/* eslint-disable max-nested-callbacks */
import { withStyles } from '@mui/styles';
import React, { useRef, useContext } from 'react';
import BreadCrumb from '../../../../components/molecules/BreadCrumb';
import PartnerSubTitleCard from '../../../../components/molecules/PartnerSubTitleCard';
import Filters from '../../components/Filters';
import SearchBox from '../../components/SearchBox';
import Button from '../../../../components/inputs/Button';
import Loader from '../../../../components/atoms/Loader';
import NotFound from '../../../../components/atoms/NotFound';
import TableGrid from '../../components/TableGrid/TableGrid';
import '../../../../features/assessments/screens/AssessmentContainer/customCss.css';
import SnackbarToast from '../../../../components/organisms/SnackbarToast';
import {
  ADD_DATA,
  ADD_DOCUMENT,
  DEFAULT_NULL_REPLACEMENT,
  LOADING_TEXT_FOR_REUSABLE_COMPONENTS,
  NUMBER_OF_RECORDS_PER_PAGE,
  SELF_REPORTING_DATA_SOURCE_LABELS,
  FAVOURITES_KEY_NAMES,
  SNACKBAR_TYPE,
} from '../../../../utils/constants';
import withRouter from '../../../../components/withRoute';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  arrayOfObjectIntoObject,
  calculateDateFilterTimeStamp,
  checkOneTimeUser,
  firstLetterLowerCase,
  formatForTableGrid,
  getAnalyticsEventsForRouteName,
  getBreadCrumbs,
  getConsentUserDetailsFromLocalStorage,
  getPartnerInfoConsolidated,
  getValueFromSourceList,
  getValueObjFromExtension,
  handleAddSelfReportedButtonalyticalEvents,
  viewDetailsDataFormatter,
  appointments,
  dateParse_appointment,
  APPOINTMENT_SCHEDULE_DETAILS,
  prior_authorization_records,
  prior_authorization_view_details,
  formatViewDetails,
} from '../../../../utils/helper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useClickFooter from '../../../../hooks/useClickFooter';
import useWidth from '../../../../hooks/useWidth';
import useGetScreenMode from '../../../../hooks/useGetScreenMode';
import useUpdateUsedService from '../../../../hooks/useUpdateUsedService';
import useAnalyticsLogger from '../../../../hooks/useAnalyticsLogger';
import { useInitialLoading } from '../../../../hooks/useInitialLoading';
import { useLayoutEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { SELF_REPORTING_TEXTS } from '../../../../utils/constants';
import { useMemo } from 'react';
import ViewDetailsFlyout from '../../components/ViewDetailsFlyout/ViewDetailsFlyout';
import GenericServices from '../../Services/GenericServices';
import { useSelector } from 'react-redux';
import { mockTemplates } from '../../mockTemplate';
import CareTeamDetails from '../../components/viewDetailsCustomComponents/CareTeamDetails/CareTeamDetails';
import ReadingsBanner from '../../components/ReadingsBanner';
import ListItems from '../../components/ListItems';
import ProfileAssociateDetails from '../../components/ProfileAssociateDetails';
import ValueAndDateTable from '../../components/ValueAndDateTable';
import { getBgColorForInitials } from '../../../../pages/AwardPoints/GiftAwardPoints/helper';
import GenericDosageList from '../../components/GenericDosageList';
import CustomDateFilter from '../../../../pages/Visits/datePicker';
import { ViewAssessment, CloseIcon } from '@premkumarmph/assessment-library';
import useFavouritesHook from '../../../../hooks/useFavouritesHook';
import { UserInfoContext } from '../../../../components/Routes';
import FileUploadModal from '../../../../pages/FileUploadModal';
import { Paper, Typography } from '@mui/material';
import colors from '../../../../utils/colors';
import { Appointments } from '../../components/Appointments/AppointmentsDetailsFlyout';
import appointmentsImg from '../../../../assets/images/Appointments.png';
import { styled } from '@mui/material/styles';
// import useReadonly from '../../../../hooks/useReadonly';

const { MARK_RESTRICTED_DESC } = SELF_REPORTING_TEXTS;

const styles = (theme) => ({
  container: {
    background: '#F5F5F5',
    position: 'relative',
    minHeight: '100%',
    padding: '32px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px',
    },
  },
  title: {
    fontSize: '28px',
    fontWeight: 600,
    height: '40px',
    marginTop: '24px',
    // marginBottom: '40px',
  },
  filterDropDownContainer: {
    display: 'flex',
    gap: '20px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '8px 0px',
      marginBottom: '8px',
    },
  },
  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  table_Wrapper: { marginTop: 16, position: 'relative' },
  searchBoxRootClass: {},
  searchInputClass: {},
  filterContainerClass: {
    minWidth: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  menu: {
    height: '40px',
    width: '40px',
    alignSelf: 'center',
  },
  favButtonContainer: {
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
  },
  spacer: {
    marginLeft: theme.spacing(2),
  },
  paperStyle: {
    padding: '16px 0px 0px 0px',
    width: '340px',
    height: '160px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    alignItem: 'center',
    backgroundColor: colors.WHITE,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  scheduletitle: {
    marginRight: '40px',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    fontFamily: 'Inter',
    lineHeight: 1.6,
    color: colors.BLACK,
  },
  button: {
    width: 'max-content',
    padding: '8px 24px 8px 24px',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
    marginTop: '12px',
    marginBottom: '16px',
    borderRadius: '40px',
    textTransform: 'none',
    border: '1px solid',
    color: colors.BLUE,
    borderColor: colors.SUBTLE_GRAY,
    '&:hover': {
      backgroundColor: colors.BLUE_HOVER,
      color: 'white',
    },
  },
});

const Img = styled('img')({
  display: 'block',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  width: '100%',
  height: '100%',
  maxWidth: '120px',
  maxHeight: '120px',
  marginLeft: '-10px',
  objectFit: 'contain',
  alignSelf: 'flex-end',
});

////////////////////////Below codes should be removed once BE sends formated data
const formatedData = (list) => {
  return list?.map((item, idx) => {
    return {
      ...item,
      extension: [
        {
          url: 'status',
          valueString: item?.assessmentResponse?.assessmentDisplay?.status,
        },
        { url: 'orgName', valueString: item?.assessmentResponse?.assessmentMeta?.orgName },
        {
          url: 'status_color',
          valueString: item?.assessmentResponse?.assessmentDisplay?.statusColorCode,
        },
      ],
    };
  });
};
///////////////////////////

const ReusableCategoriesContainer = (props) => {
  const { classes, onFooterItemClick, navigate } = props;
  const location = useLocation();
  const { partner: partnerUrlParam, grantorId, granteeId, circleId, categoryName } = useParams();
  const isOneTimeUser = checkOneTimeUser();
  const consentUserName = getConsentUserDetailsFromLocalStorage();
  const partnerInfo = getPartnerInfoConsolidated(partnerUrlParam);
  const screenMode = useGetScreenMode();
  const screenWidth = useWidth();
  const scheduleNavigate = useNavigate();
  let SERVICE_TYPE = categoryName;
  useUpdateUsedService(SERVICE_TYPE, screenMode?.isCircle, partnerInfo && partnerInfo?.circleId);
  const isLarge = screenWidth === 'lg' || screenWidth === 'xl';

  ////used for footerItemClicked
  useClickFooter(onFooterItemClick);

  /////data state////

  const [responseData, setRenponseData] = useState([]);
  const [responseGrid, setResponseGrid] = useState({});
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(false);

  const [favLoading, setFavLoading] = useState(false);
  const [isOpen, setIsOpen] = useState({
    successModalOpen: false,
    cancelModalOpen: false,
  });

  const { templates } = useSelector((state) => state.templates);

  const [markRestricted, setMarkRestricted] = useState(
    templates?.[SERVICE_TYPE]?.details?.detailsTable?.options?.markRestricted ||
      mockTemplates[SERVICE_TYPE]?.details?.detailsTable?.options?.markRestricted ||
      false,
  );

  //////////data fields from templates/////////////////
  const title = templates?.[SERVICE_TYPE]?.title || mockTemplates[SERVICE_TYPE]?.title || '-';
  const useResponseGrid =
    templates?.[SERVICE_TYPE]?.useResponseGrid ||
    mockTemplates[SERVICE_TYPE]?.useResponseGrid ||
    false;
  const disableButton =
    templates?.[SERVICE_TYPE]?.disableButton || mockTemplates[SERVICE_TYPE]?.disableButton || {};
  const icon = templates?.[SERVICE_TYPE]?.icon || mockTemplates[SERVICE_TYPE]?.icon || '-';
  const bgColorForInitial =
    templates?.[SERVICE_TYPE]?.bgColorForInitial ||
    mockTemplates[SERVICE_TYPE]?.bgColorForInitial ||
    '';
  const filters =
    templates?.[SERVICE_TYPE]?.dashboard?.filters ||
    mockTemplates[SERVICE_TYPE]?.dashboard?.filters ||
    [];
  const search =
    templates?.[SERVICE_TYPE]?.dashboard?.search ||
    mockTemplates[SERVICE_TYPE]?.dashboard?.search ||
    {};
  const { enabled } =
    templates?.[SERVICE_TYPE]?.dashboard?.selfReporting ||
    mockTemplates[SERVICE_TYPE]?.dashboard?.selfReporting ||
    {};

  const { enabled: addDocumentEnabled } =
    templates?.[SERVICE_TYPE]?.dashboard?.addDocument ||
    mockTemplates[SERVICE_TYPE]?.dashboard?.addDocument ||
    {};

  const tableHeadings =
    templates?.[SERVICE_TYPE]?.dashboard?.tableColumns ||
    mockTemplates[SERVICE_TYPE]?.dashboard?.tableColumns ||
    [];
  const breadCrumbProperties =
    templates?.[SERVICE_TYPE]?.dashboard?.breadCrumbProperties ||
    mockTemplates[SERVICE_TYPE]?.dashboard?.breadCrumbProperties;

  const reloadOnScroll =
    templates?.[SERVICE_TYPE]?.dashboard?.reloadOnScroll ||
    mockTemplates[SERVICE_TYPE]?.dashboard?.reloadOnScroll;

  const customCardForViewDetails =
    templates?.[SERVICE_TYPE]?.details?.customCardForViewDetails?.detailsTable ||
    mockTemplates[SERVICE_TYPE]?.details?.detailsTable?.customCardForViewDetails ||
    {};

  const resourceName =
    templates?.[SERVICE_TYPE]?.resourceName || mockTemplates[SERVICE_TYPE]?.resourceName;

  const loaderName =
    templates?.[SERVICE_TYPE]?.loadingText || mockTemplates[SERVICE_TYPE]?.loadingText;

  const loaderViewDetailsName =
    templates?.[SERVICE_TYPE]?.details?.loadingText ||
    mockTemplates[SERVICE_TYPE]?.details?.loadingText;

  const isHistoricalCardEnable =
    templates?.[SERVICE_TYPE]?.details?.detailsTable?.options?.isHistoricalCardEnable ||
    mockTemplates[SERVICE_TYPE]?.details?.detailsTable?.options?.isHistoricalCardEnable;

  const selfReportingTemplate =
    templates?.[SERVICE_TYPE]?.selfReportingSection ||
    mockTemplates[SERVICE_TYPE]?.selfReportingSection;

  const uploadDocumentTypes = templates?.documentCategories || mockTemplates?.documentCategories;

  const needToFormat =
    templates?.[SERVICE_TYPE]?.dashboard?.needToFormat ||
    mockTemplates?.[SERVICE_TYPE]?.dashboard?.needToFormat;

  const buttonDetail =
    templates?.[SERVICE_TYPE]?.details?.medicationRequest?.buttonDetails ||
    mockTemplates?.[SERVICE_TYPE]?.details?.medicationRequest?.buttonDetails;
  /////////Table Headings/////////////////////
  const getTableHeading = useMemo(() => {
    let tableHeading = tableHeadings.map((obj) =>
      obj.headerName === 'Data source' ? { ...obj, hide: partnerUrlParam ? true : false } : obj,
    );
    if (isOneTimeUser && !isLarge) {
      tableHeading = tableHeadings?.filter(
        (obj) =>
          !['Recorded on', 'Criticality', 'Data source', 'Severity', 'Category'].includes(
            obj.headerName,
          ),
      );
    }

    return tableHeading;
  }, [isLarge, SERVICE_TYPE]);

  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { isFavourite, renderFavIcon } = useFavouritesHook(resourceName);

  //////////////////breadCrumbs///////////////
  const breadCrumbs = breadCrumbProperties
    ? breadCrumbProperties
    : getBreadCrumbs(title, screenMode, partnerInfo, consentUserName, isOneTimeUser);
  /////////////////Analytics Events//////////
  const isCirclesOrRecords = location.pathname.includes('circles');
  const { logAnalyticsEvent } = useAnalyticsLogger();
  const analyticsEvents = getAnalyticsEventsForRouteName(SERVICE_TYPE);

  ///////////////Loader related state///////////
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [buttonDetails, setButtonDetails] = useState(buttonDetail);
  const [isLoading, setIsLoading] = useState(true);
  const [loaderText, setLoaderText] = useState(LOADING_TEXT_FOR_REUSABLE_COMPONENTS(loaderName));
  const [loaderViewDetailsText, setLoaderViewDetailsText] = useState(
    LOADING_TEXT_FOR_REUSABLE_COMPONENTS(loaderViewDetailsName),
  );

  //flyout states
  const [modalData, setModalData] = useState([]);
  const [editData, setEditData] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [showEditInModal, setShowEditInModal] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [markAsRestrictedInModal, setMarkAsRestrictedInModal] = useState(false);
  const [historicalRecordsParamsObj, setHistoricalRecordsParamsObj] = useState({});
  const [currentObj, setCurrentObj] = useState({});
  const [documents, setDocuments] = useState([]);
  const [editDataForFlyout, setEditDataForFlyout] = useState({});
  const [viewProfileData, setViewProfileData] = useState([]);
  const [nextPageId, setNextPageId] = useState('');
  const [customComponentProps, setCustomComponentProps] = useState({
    note: '',
    membersDetails: [],
    assoc_care_plan_Details: [],
    responseObj: [],
    resourceType: '',
  });

  //infinite scroll and filters and search states
  const [hasInfiniteScroll, setHasInfiniteScroll] = useState(true);
  const recordsPerPage = NUMBER_OF_RECORDS_PER_PAGE;
  const [initialRender, setInitialRender] = useInitialLoading();
  const pageNumber = useRef(1);
  const changeFiltersValue = useRef(true);
  const timeFilter = useRef(0);
  const typeFilter = useRef('');
  const statusFilter = useRef([]);
  const searchValue = useRef('');
  const [apiData, setAPIData] = useState();
  const params = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showpage, setShowPage] = useState(true);
  //snackbar states
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState(SNACKBAR_TYPE.SUCCESS);

  //navigation to add selfReported Data
  const handleAddSelfReportedData = () => {
    handleAddSelfReportedButtonalyticalEvents(logAnalyticsEvent, SERVICE_TYPE);
    props.navigate(`/records/new/${SERVICE_TYPE.toLowerCase()}/add`);
  };

  const getAllRecords = async (offset) => {
    const variables = {
      initiatorId: granteeId ?? '',
      grantorId: grantorId ?? '',
      count: recordsPerPage.toString(),
      offset,
      nextPageId: offset == 0 ? '' : nextPageId,
      sourceUrl: partnerUrlParam ? partnerInfo?.partnerSourceUrl : '',
      resourceType: useResponseGrid ? selfReportingTemplate?.resourceType : SERVICE_TYPE,
      filters: [
        { filterType: 'dateFilter', value: [String(timeFilter.current)] },
        { filterType: 'documentType', value: [String(typeFilter.current)] }, 
        { filterType: 'statusFilter', value: [String(statusFilter.current)] },
        { filterType: 'searchFilter', value: [String(searchValue.current)] },
        {
          filterType: 'timePeriod',
          value: 'ALL',
        },
        {
          filterType: 'eobType',
          value: 'all',
        },
      ],
    };
    const multipleResourceTypes =
      templates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes ||
      mockTemplates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes;
    if (multipleResourceTypes) {
      const res = await Promise.all(
        multipleResourceTypes.map((item) => {
          return GenericServices.fetchAllRecords({ ...variables, resourceType: item });
        }),
      );
      return arrayOfObjectIntoObject(res);
    } else {
      return await GenericServices.fetchAllRecords(variables);
    }
  };

  const updateUi = async (offset, checkForNewlyCreatedRecord = false, onDocUpload = false) => {
    try {
      setIsLoading(true);
      const { recordList, nextPageId } = await getAllRecords(offset);
      setNextPageId(nextPageId);  
      if(nextPageId === null){
        setHasInfiniteScroll(false)
      }
      let parsedResult = [];
      const result = JSON.parse(recordList);
      result.forEach((obj) => parsedResult.push(obj));
      ///Below line should be removed, once BE send formated data
      parsedResult = useResponseGrid ? formatedData(parsedResult) : parsedResult;
      if (checkForNewlyCreatedRecord) {
        if (createdObj?.id) {
          // check if createdObj exists in all records and add if not
          const ifNewlyCreatedObjExists = parsedResult?.find((obj) => obj.id === createdObj.id);
          if (!ifNewlyCreatedObjExists) {
            const originalResLength = parsedResult?.length;
            const poppedAry =
              originalResLength > 0 ? parsedResult?.splice(0, parsedResult?.length - 1) : [];
            parsedResult = [createdObj, ...poppedAry];
          }
        }
      }
      needToFormat && setAPIData(parsedResult);
      const formattedData = needToFormat
        ? formatForTableGrid(parsedResult, SERVICE_TYPE)
        : parsedResult;
      const data = formattedData;
      sessionStorage.setItem('formattedData',JSON.stringify(formattedData))
      if (pageNumber.current !== 1 && data?.length === 0) {
        setHasInfiniteScroll(false);
        return;
      } else if (data?.length === 0 && pageNumber.current === 1) {
        setHasInfiniteScroll(false);
        setRenponseData([]);
        return;
      } else if (changeFiltersValue.current && data?.length && pageNumber.current === 1) {
        changeFiltersValue.current = false;
        setRenponseData(data);
        return;
      } else if (!changeFiltersValue.current && data?.length && pageNumber.current !== 1) {
        setRenponseData((prevData) => [...prevData, ...data]);
        return;
      }
    } catch (error) {
      SERVICE_TYPE === 'prior_authorization' &&
        setRenponseData(formatForTableGrid(prior_authorization_records, SERVICE_TYPE,  [String(statusFilter.current)]));
      SERVICE_TYPE === 'prior_authorization' && setAPIData(prior_authorization_view_details);
      SERVICE_TYPE === 'appointments' && setRenponseData(appointments);
      console.warn('Error in fetching list: ', error);
      setHasInfiniteScroll(false);
    } finally {
      setIsLoading(false);
    }
  };

  const { state: propsState } = location;
  useEffect(() => {
    if (propsState?.carePlanId) {
      viewDetailsHandler(propsState?.carePlanId);
    } else if (propsState?.careTeamId) {
      viewDetailsHandler(propsState?.careTeamId);
    }
  }, [propsState]);

  useEffect(() => {
    updateUi(0);
    changeFiltersValue.current = true;
    pageNumber.current = 1;
    return () => {
      setOpenPopUp(false);
    };
  }, [SERVICE_TYPE, tableRefresh, isOpen.cancelModalOpen]);

  const { state } = location;
  useEffect(() => {
    const drawerOpen = state && state.appointments;
    if (drawerOpen === 'appointmentspopup') {
      setDrawerOpen(true);
    }
  }, [state]);

  //Filters methods
  const handleChangeFilter = () => {
    changeFiltersValue.current = true;
    setHasInfiniteScroll(true);
    setInitialRender(true);
    pageNumber.current = 1;
    updateUi(0);
  };

  const handlepageNumber = () => {
    pageNumber.current += 1;
    const offsetValue = 10 * (pageNumber.current - 1);
    updateUi(offsetValue);
  };

  const handleSearch = (value) => {
    if (value !== searchValue) {
      searchValue.current = value;
      handleChangeFilter();
    }
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setNextPageId(null);
    switch (name) {
      case 'dateFilter':
        let dateValue = 0;
        if (!value) {
          dateValue = 0;
        } else if (typeof value === 'object') {
          const date = Date.parse(value);
          dateValue = date;
        } else {
          dateValue = calculateDateFilterTimeStamp(value);
        }
        timeFilter.current = dateValue;
        handleChangeFilter();
        break;
      case 'statusFilter':
        statusFilter.current = value;
        handleChangeFilter();
        break;
        case 'typeFilter':
          typeFilter.current = value.toLowerCase();
          handleChangeFilter();
          break;
        default:
        return;
    }
  };

  useEffect(() => {
    if (props.location.state) {
      setLoaderText(props.location.state.loaderText);
      setLoaderViewDetailsText(props.location.state.loaderViewDetailsText);
      setSnackbarMessage(props.location.state.message);
    }

    ///converting object to array and passing as object based on route and categories
    const listOfEvents = Object.entries(analyticsEvents)?.map(([key, value]) => value);
    isCirclesOrRecords
      ? logAnalyticsEvent(listOfEvents?.[0])
      : logAnalyticsEvent(listOfEvents?.[1]);
  }, []);

  const getViewDetailsCustomTemplates = {
    labs: {
      component: ReadingsBanner,
      props: { editDataForFlyout },
    },
  };
  const getCustomTemplates = {
    care_plan: {
      component: ListItems,
      props: {
        responseObj: customComponentProps?.responseObj,
      },
    },
    care_team: {
      component: ProfileAssociateDetails,
      props: {
        membersDetails: { memberList: customComponentProps.membersDetails, heading: 'Members' },
        assoc_care_plan_Details: {
          associatedPlansList: customComponentProps.assoc_care_plan_Details,
          heading: 'Associated care plans',
        },
        screenMode,
      },
    },
    family_member_history: {
      component: ValueAndDateTable,
      props: {
        conditions: customComponentProps?.responseObj?.condition || [],
        note: customComponentProps?.responseObj?.note || DEFAULT_NULL_REPLACEMENT,
        reasonForHistory: customComponentProps?.responseObj?.reasonCode || [],
      },
    },
    medications: {
      component: GenericDosageList,
      props: {
        dosageInstruction: customComponentProps?.responseObj?.dosageInstruction,
        note: customComponentProps?.responseObj?.note,
        resourceType: customComponentProps?.resourceType,
      },
    },
  };

  const getMemberDetails = (memberList = []) => {
    const res = memberList.map(({ member, onBehalfOf, role }, index) => {
      return {
        id: index,
        member_name:
          typeof member === String ? member : member?.display || member?.identifier?.display || '',
        imgUrl: member?.avatarUrl || '',
        specialty: role?.[0]?.coding?.[0]?.display || DEFAULT_NULL_REPLACEMENT,
        partnerName: onBehalfOf?.display || DEFAULT_NULL_REPLACEMENT,
        bgColor: getBgColorForInitials(),
      };
    });
    return res;
  };
  const getAssociateCarePlanDetails = (associateCarePlanList = []) => {
    return associateCarePlanList?.map((associateDetails) => {
      const dailyExerciseList = associateDetails?.listElements;
      return {
        id: associateDetails?.resource_id,
        imgUrl: associateDetails?.iconurl || null,
        heading: associateDetails?.title,
        subHeading: associateDetails?.subtitle || 'Activities',
        list: dailyExerciseList,
        resource: associateDetails?.resource,
        resource_id: associateDetails.resource_id,
      };
    });
  };

  const markRestrictedFun = (resourceType) => {
    const markRestricted =
      templates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes ||
      mockTemplates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes
        ? templates?.[SERVICE_TYPE]?.details?.[resourceType]?.detailsTable?.options
            ?.markRestricted ||
          mockTemplates?.[SERVICE_TYPE]?.details?.[resourceType]?.detailsTable?.options
            ?.markRestricted
        : templates?.[SERVICE_TYPE]?.details?.detailsTable?.options?.markRestricted ||
          mockTemplates?.[SERVICE_TYPE]?.details?.detailsTable?.options?.markRestricted;
    setMarkRestricted(markRestricted);
  };

  const viewDetailsHandler = async (id, resource_Type) => {
    setModalData(null);
    setIsLoading(true);
    setDetailsLoading(true);
    setShowEditInModal(false);
    setLoaderViewDetailsText(
      LOADING_TEXT_FOR_REUSABLE_COMPONENTS(`${loaderViewDetailsName} details`),
    );
    SERVICE_TYPE = resource_Type ? resource_Type : SERVICE_TYPE
    const tableRowInfo = responseData.find((obj) => obj.id === id);
    const multiAPICalls =
      templates?.dashboard?.[SERVICE_TYPE]?.queryResourceTypes ||
      mockTemplates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes;
    const resourceType = firstLetterLowerCase(
      tableRowInfo?.resourceType,
      SERVICE_TYPE,
      multiAPICalls,
    );
    const buttonDetails =
      templates?.[SERVICE_TYPE]?.details?.[resourceType]?.buttonDetails ||
      mockTemplates?.[SERVICE_TYPE]?.details?.[resourceType]?.buttonDetails;
    markRestrictedFun(resourceType);
    setButtonDetails(buttonDetails);
    setCurrentObj(tableRowInfo);
    try {
      const { record, documentReferenceId, docArn } = await GenericServices.fetchRecordById({
        resourceType: resource_Type ? resource_Type : resourceType,
        resourceId: id?.toString() ?? '',
        grantorId: grantorId?.toString() ?? '',
      });
      let resourceObj = JSON.parse(record);
      resourceObj = {
        ...resourceObj,
        docArn: docArn,
        documentReferenceId: documentReferenceId,
      };
      setCustomComponentProps({
        responseObj: resourceObj,
        resourceType: resourceType,
        note: resourceObj?.note?.[0]?.text || '-',
        membersDetails:
          (resourceObj?.participant && getMemberDetails(resourceObj?.participant)) || [],
        assoc_care_plan_Details:
          (resourceObj?.extension &&
            getValueObjFromExtension(resourceObj?.extension, 'associated_data')?.valueString &&
            JSON.parse(
              getValueObjFromExtension(resourceObj?.extension, 'associated_data')?.valueString,
            ) &&
            getAssociateCarePlanDetails(
              JSON.parse(
                getValueObjFromExtension(resourceObj.extension, 'associated_data')?.valueString,
              ),
            )) ||
          [],
      });
      const viewProfileKey =
        templates?.[categoryName]?.details?.associatedData ||
        mockTemplates?.[categoryName]?.details?.associatedData;
      const viewProfile = viewProfileKey ? getValueFromSourceList(resourceObj, viewProfileKey) : [];
      setViewProfileData(
        typeof viewProfile === 'string' && viewProfile !== '-' ? JSON.parse(viewProfile) : [],
      );
      setDocuments(docArn);

      const detailsTemplate =
        templates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes ||
        mockTemplates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes
          ? templates?.[SERVICE_TYPE]?.details?.[resourceType]?.detailsTable?.cols ||
            mockTemplates[SERVICE_TYPE]?.details?.[resourceType]?.detailsTable?.cols ||
            {}
          : templates?.[SERVICE_TYPE]?.details?.detailsTable?.cols ||
            mockTemplates[SERVICE_TYPE]?.details?.detailsTable?.cols ||
            {};
      const header =
        templates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes ||
        mockTemplates?.[SERVICE_TYPE]?.dashboard?.queryResourceTypes
          ? templates?.[SERVICE_TYPE]?.details?.[resourceType]?.detailsTable?.header ||
            mockTemplates[SERVICE_TYPE]?.details?.[resourceType]?.detailsTable?.header ||
            DEFAULT_NULL_REPLACEMENT
          : templates?.[SERVICE_TYPE]?.details?.detailsTable?.header ||
            mockTemplates[SERVICE_TYPE]?.details?.detailsTable?.header ||
            DEFAULT_NULL_REPLACEMENT;
      const formattedData = viewDetailsDataFormatter(
        resourceObj,
        detailsTemplate,
        selfReportingTemplate,
        customCardForViewDetails,
        header,
        partnerUrlParam && true,
      );
      setModalData(formattedData?.modalData);
      setModalTitle(formattedData?.title);
      setMarkAsRestrictedInModal(formattedData?.markAsRestricted);
      //////for custom component in viewDetails flyout For lab Result
      setEditDataForFlyout(formattedData?.requiredPropsForCustomCardInViewDetails);
      ///////////////////////
      setHistoricalRecordsParamsObj({
        resourceType: resourceType ?? SERVICE_TYPE,
        icon: icon,
        title: formattedData?.title,
        partner: partnerUrlParam,
        circleId: partnerInfo?.circleId,
        code: formattedData?.code,
      });
      setOpenPopUp(true);

      setIsLoading(false);
      if (SELF_REPORTING_DATA_SOURCE_LABELS.includes(formattedData?.dataSource?.toLowerCase())) {
        setShowEditInModal(true);
        setEditData(formattedData?.editData);
      }
    } catch (err) {
      SERVICE_TYPE === 'appointments' && setDrawerOpen(true);
      console.log(err);
    } finally {
      setIsLoading(false);
      setDetailsLoading(false);
    }
  };

  const handleViewDetails = (id) => {
    setOpenPopUp(true);
    setModalTitle(responseData?.[id]?.title);
    const data = formatViewDetails(apiData?.[id], SERVICE_TYPE);
    setModalData(data);
  };

  const handleViewDetailsInAssessment = async (id, isOpenViewDetails) => {
    if (isOpenViewDetails) {
      setIsLoading(true);
      setLoaderViewDetailsText(
        LOADING_TEXT_FOR_REUSABLE_COMPONENTS(`${loaderViewDetailsName} details`),
      );
      try {
        const { record } = await GenericServices.fetchRecordById({
          resourceType: selfReportingTemplate?.resourceType,
          resourceId: id?.toString() ?? '',
          grantorId: grantorId?.toString() ?? '',
        });

        const resourceObj = JSON.parse(record);

        const handleAnswers = (subQ) => {
          return subQ?.answers
            ?.filter((subAnswer) => subAnswer?.answered && subAnswer?.question)
            .map((subAnswered) => subAnswered);
        };

        const handleAnsweredId = (question) => {
          return question?.answers
            ?.filter((answer) => answer.answered)
            .map((answered) => answered?.id);
        };

        const handleChoices = (question) => {
          return question?.answers?.map((answer) => ({
            ...answer,
            value: answer?.text || DEFAULT_NULL_REPLACEMENT,
          }));
        };

        const handleAnswerValue = (question) => {
          if (
            question?.answerType?.toLowerCase() === 'shorttext' ||
            question?.answerType?.toLowerCase() === 'longtext'
          ) {
            return question?.answers?.[0]?.answered === true
              ? question?.answers?.[0]?.answeredValue
              : DEFAULT_NULL_REPLACEMENT;
          }
          return handleAnsweredId(question)?.length === 0 ? DEFAULT_NULL_REPLACEMENT : null;
        };

        const handleNRQ = (question) => {
          const answeredIds = handleAnswers(question);
          const nrqQuestions = answeredIds?.map((nrqQuestion) => ({
            selectedChoice: nrqQuestion?.id,
            question: {
              ...nrqQuestion?.question,
              answerId: handleAnsweredId(nrqQuestion?.question),
              choices: handleChoices(nrqQuestion?.question),
              answerValue: handleAnswerValue(nrqQuestion?.question),
            },
          }));
          return nrqQuestions;
        };

        const displayAssessment = {
          url: resourceObj?.assessmentResponse?.assessmentMeta?.orgImageURL,
          status: resourceObj?.assessmentResponse?.assessmentMeta?.status,
          statusColorCode: resourceObj?.assessmentDisplay?.statusColorCode,
          completedDate: resourceObj?.assessmentDisplay?.completedDate,
          name: resourceObj?.assessmentResponse?.assessmentMeta?.assessmentName,
          assessmentDefinition:
            resourceObj?.assessmentResponse?.assessmentResponseBody?.assessmentQuestionnaires.map(
              (question) => ({
                ...question,
                subQuestion: question?.questions?.map((subQ) => ({
                  ...subQ,
                  answerId: handleAnsweredId(subQ),
                  choices: handleChoices(subQ),
                  answerValue: handleAnswerValue(subQ),
                  settings: {
                    nextReferenceQuestion: handleNRQ(subQ),
                  },
                })),
                answerId: handleAnsweredId(question),
                choices: handleChoices(question),
                answerValue: handleAnswerValue(question),
                settings: {
                  nextReferenceQuestion: handleNRQ(question),
                },
              }),
            ),
        };
        setResponseGrid(displayAssessment);
        setOpenPopUp(true);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      //navigate to assessment start page with assessmentInstanceId and status
      const currentAssessment = responseData?.find(
        (item) => item?.assessmentResponse?.assessmentMeta?.id === id,
      );
      navigate('/assessments', {
        state: { assessmentInstanceId: id, status: currentAssessment?.status },
      });
    }
  };

  const onEdit = ({ newRestrictedVal = markAsRestrictedInModal }) => {
    props.navigate(`/records/new/${SERVICE_TYPE.toLowerCase()}/edit`, {
      state: { editProps: editData, markAsRestricted: newRestrictedVal },
    });
  };

  useLayoutEffect(() => {
    if (partnerUrlParam && !partnerInfo?.partnerSourceUrl) {
      props.navigate('/circles');
    }
    return () => {
      // localStorage.removeItem('partnerInfo');
      if (onFooterItemClick) {
        onFooterItemClick(-1);
      }
    };
  }, []);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleAddDocument = () => {
    setShowAddDocument(true);
  };

  const onDocumentSave = () => {
    setShowAddDocument(false);
    setTableRefresh(!tableRefresh);
    setSnackbarType(SNACKBAR_TYPE.SUCCESS);
    setSnackbarMessage('Document uploaded successfully');
  };

  return (
    <div className={classes.container}>
      {initialRender && <Loader overlay text={loaderText} />}
      {detailsLoading && <Loader overlay text={loaderViewDetailsText} />}
      <BreadCrumb breadCrumbProps={breadCrumbs} />
      <h2 className={classes.title}>{title}</h2>
      {userInfo?.betaUser && FAVOURITES_KEY_NAMES[resourceName] && (
        <div className={classes.favButtonContainer}>{renderFavIcon()}</div>
      )}
      {resourceName === 'appointments' && (
        <div style={{ position: 'relative', marginTop: '20px', marginBottom: '20px' }}>
          <Paper className={classes.paperStyle}>
            <img alt="complex" src={appointmentsImg} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '16px',
                marginLeft: '20px',
              }}
            >
              <Typography className={classes.scheduletitle}>
                Schedule a visit with a provider of your choice
              </Typography>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => {
                  scheduleNavigate('/shop/FindCare', { state: { hidePartnerList: true } });
                }}
              >
                Schedule a visit
              </Button>
            </div>
          </Paper>
        </div>
      )}
      {partnerInfo?.partnerIconUrl && partnerUrlParam && (
        <PartnerSubTitleCard icon={partnerInfo?.partnerIconUrl} title={partnerUrlParam} />
      )}
      <div className={classes.filtersContainer}>
        <div className={classes.filterDropDownContainer}>
          {search.datePicker ? (
            <CustomDateFilter
              searchValue={searchValue.current}
              placeholder={search.placeholder}
              filterItem={search}
              handleChange={handleSearch}
            />
          ) : (
            <SearchBox
              search={search}
              handleSearch={handleSearch}
              searchBoxRootClass={classes.searchBoxRootClass}
              searchInputClass={classes.searchInputClass}
            />
          )}
          <Filters
            handleFilter={handleFilter}
            filters={filters}
            filterContainerClass={classes.filterContainerClass}
          />
          
        </div>

        <div className={classes.buttonsContainer}>
          {!screenMode?.isCircle && !screenMode?.involvesConsent && !isOneTimeUser && enabled && (
            <div className={classes.spacer}>
              <Button onClick={handleAddSelfReportedData}>{ADD_DATA}</Button>
            </div>
          )}

          {addDocumentEnabled && (
            <div className={classes.spacer}>
              <Button onClick={handleAddDocument}>{ADD_DOCUMENT}</Button>
            </div>
          )}

          {userInfo?.betaUser && resourceName !== 'appointments' && (
            <div className={classes.spacer}>
              <Button disabled={!!!responseData?.length} onClick={handlePrint}>
                {'Print'}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={classes.table_Wrapper}>
        {!responseData?.length && !isLoading && <NotFound title={title} />}
        {responseData?.length > 0 && (
          <TableGrid
            ref={printRef}
            setOpen={setOpenPopUp}
            data={responseData}
            selfReportingTemplate={selfReportingTemplate}
            paginationObj={{
              handlepageNumber: handlepageNumber,
              loading: isLoading,
              detailsLoading,
              hasInfiniteScroll: hasInfiniteScroll,
            }}
            tableHeading={getTableHeading}
            viewDetailsHandler={needToFormat ? handleViewDetails : viewDetailsHandler}
            screenMode={screenMode}
            useResponseGrid={useResponseGrid}
            handleViewDetailsInAssessment={handleViewDetailsInAssessment}
            disableButton={disableButton}
            onRefresh={() => setTableRefresh(!tableRefresh)}
            reloadOnScroll={reloadOnScroll}
            nextPageId={nextPageId}
          />
        )}
      </div>

      <FileUploadModal
        options={uploadDocumentTypes}
        show={showAddDocument}
        onClose={() => setShowAddDocument(false)}
        onSave={() => onDocumentSave()}
        onError={() => {}}
      />

      {resourceName === 'appointments' && (
        <Appointments drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      )}

      {openPopUp && resourceName !== 'appointments' && !useResponseGrid && (
        <ViewDetailsFlyout
          setOpen={setOpenPopUp}
          data={modalData}
          markAsRestricted={markAsRestrictedInModal}
          title={modalTitle}
          currentObj={currentObj}
          documents={documents}
          clinicalRecord={customComponentProps?.resourceType ?? SERVICE_TYPE}
          icon={icon}
          bgColorForInitial={bgColorForInitial}
          isHistoricalCardEnable={isHistoricalCardEnable}
          markRestricted={markRestricted}
          historicalRecordsParamsObj={
            !screenMode?.involvesConsent ? historicalRecordsParamsObj : {}
          }
          showEdit={showEditInModal}
          restrictedDescription={MARK_RESTRICTED_DESC(resourceName)}
          handleEdit={(obj) => onEdit(obj)}
          hideOptions={Boolean(partnerUrlParam || grantorId)}
          //////////////custom component with props for new layouts above the key-value pair list
          customComponent={getViewDetailsCustomTemplates[SERVICE_TYPE]}
          customBodyComponent={getCustomTemplates[SERVICE_TYPE]}
          viewProfileData={viewProfileData}
          buttonDetails={buttonDetails}
        />
      )}
      {openPopUp && useResponseGrid && (
        <div>
          {/* {alert('viewDetails')} */}
          {/* AssessmentViewDetails with this props modalData={modalData} setOpenPopUp={setOpenPopUp} */}
          {/* onClick={(viewDetailsHandler)} */}
          <ViewAssessment
            dateField={responseGrid.completedDate}
            dateHeader="Completed on"
            getStatusColor={() => responseGrid.statusColorCode}
            data={responseGrid}
            handleClose={() => setOpenPopUp(false)}
            styles={{
              logo: { width: '100%', maxHeight: '48px', maxWidth: '200px', marginBottom: '8px' },
            }}
          />
        </div>
      )}
      <SnackbarToast
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!snackbarMessage}
        alertStyle={{ backgroundColor: '#1F1F1F', borderRadius: 4, color: '1F1F1F' }}
        duration={4000}
        onClose={() => {
          setSnackbarMessage(null);
        }}
        message={snackbarMessage}
        type={snackbarType}
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(ReusableCategoriesContainer));
