import StatusIcon from '@mui/icons-material/FiberManualRecord';
import { TableContainer } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import UserCircle from '../../../../assets/images/defaultAvatarBlack.svg';
import hotLinkIcon from '../../../../assets/images/hot-link.png';
import colors from '../../../../utils/colors';
import {
  capitalizeFirstLetter,
  getMultipleObjFromSource,
  getValueNameWithCodeExtension,
  getNameWithCodeField,
  getValueFromSourceList,
  getValueFromOptions,
  getValueFromTransformation,
  getFileNameAndExtension,
  convertStrToTitleCase,
} from '../../../../utils/helper';
import Button from '../../../../components/atoms/Button';
import objectPath from 'object-path';
import { DEFAULT_NULL_REPLACEMENT, DUMMY_DATE_STUB, SNACKBAR_TYPE } from '../../../../utils/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import TrashIcon from '../../../../assets/images/trash.svg';
import DocDownloadIcon from '../../../../assets/images/doc-download.svg';
import SnackbarToast from '../../../../components/organisms/SnackbarToast';
import DocumentService, { DOCUMENT_SERVICE_END_POINTS } from '../../../../service/DocumentService';

const PaperWithoutShadow = styled(Paper)({
  boxShadow: 'unset',
});

const styles = (theme) => ({
  tableContainer: {
    height: 'max-content',
    overflow: 'auto',
    borderRadius: 16,
    backgroundColor: '#F5F5F5',
  },
  table: {
    borderCollapse: 'inherit',
    backgroundColor: colors.WHITE,
    borderTopRightRadius: 16,
    scrollBehaviour: 'smooth',
    '&:last-child': {
      // '&:nth-last-child(1)': {
      border: ' 1px solid #E4E4E4',
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
    },
  },
  tableHead: {},
  endOfFileText: {
    textAlign: 'center',
    background: 'white',
    width: '100%',
    margin: 0,
    padding: '12px 0px',
    lineHeight: '24px',
    borderTop: ' 1px solid #E4E4E4',
    borderBottom: ' 1px solid #E4E4E4',
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    fontWeight: 600,
  },
  viewDetailsButton: {
    background: '#036FCB',
    color: 'white',
    height: 24,
    padding: '2px 8px 0px',
    borderRadius: 20,
    minWidth: 90,
    [theme.breakpoints.down('sm')]: {
      height: 34,
    },
  },

  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  iconBackground: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    backgroundColor: '#E4E4E4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  downloadMargin: {
    marginRight: 16,
  },
  downloadLeft: {
    marginLeft: 18,
  },

  menu: {
    height: '16px',
    width: '24px',
  },
  viewRecordButton: {
    marginRight: 10,
    color: '#036FCB',
    border: '1px solid #A0A4A6',
    height: 24,
    padding: '2px 8px 0px',
    borderRadius: 20,
    minWidth: 90,
  },
  consentRequestButton: {
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    height: 24,
    padding: '2px 8px 0px',
    borderRadius: 20,
    minWidth: 90,
  },
  paginationWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  dataSource: {
    background: '#E4E4E4',
    border: '1px solid #C0C0C0',
    boxSizing: 'border-box',
    borderRadius: 40,
    color: '#1F1F1F',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    textAlign: 'center',
    width: 108,
    padding: '1px 8px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    top: 3,
  },
  tooltipDisplay: {
    background: '#1B1B1B',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    padding: '0px 8px 6px 8px',
    minWidth: 98,
    display: 'block',
    position: 'relative',
    top: 2,
  },
  statusContainer: { display: 'flex' },
  statusIcon: { width: 12, height: 12 },
  statusColumn: {
    marginRight: 4,
    display: 'flex',
    alignItems: 'center',
  },
  moreWrapper: { textAlign: 'right' },
  moreWrapperBtn: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  payBtn: {
    border: '1px solid #A0A4A6',
    borderRadius: 40,
    width: 94,
    height: 24,
    color: '#036FCB',
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Inter',
  },
  tooltip: {
    padding: 0,
    background: '#1B1B1B',
    minWidth: 120,
    position: 'relative',
    bottom: 5,
    height: 20,
  },
  hotlInkTooltip: {
    fontSize: '0.8rem',
  },
  hotLink: {
    width: '15px',
    height: '15px',
    display: 'inline-block',
    marginLeft: '8px',
    cursor: 'pointer',
  },
  imgIcon: {
    width: 20,
    height: 20,
    borderRadius: 80,
    marginRight: 8,
    objectFit: 'cover',
    overflow: 'hidden',
  },
  ImgWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  initialWrapper: {
    boxSizing: 'border-box',
    fontFamily: 'Inter',
    height: 24,
    width: 24,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    color: '#002534',
    fontSize: '12px',
    lineHeight: 0,
    marginRight: 4,
    padding: 4,
  },
  typeOfConsentWrapper: {
    fontSize: '11px',
    color: '#1f1f1f',
    fontWeight: 500,
    fontFamily: 'Inter',
    background: '#e4e4e4',
    padding: '0px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #c0c0c0',
    borderRadius: 24,
    width: 'fit-content',
  },
  pillString: {
    color: ' #1F1F1F',
    width: '108px',
    border: '1px solid #C0C0C0',
    display: 'inline-block',
    padding: '1px 8px',
    overflow: ' hidden',
    position: 'relative',
    fontSize: '11px',
    background: ' #E4E4E4',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 500,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    borderRadius: '40px',
    textOverflow: 'ellipsis',
  },
  bgColorForInitial: {
    backgroundColor: '#00A0DF',
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    width: 24,
    borderRadius: '50%',
    margin: '-0px 8px 0px 0px',
    lineHeight: '16px',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    paddingLeft: 16,
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
    opacity: 1,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    height: 48,
    //minWidth: 100,
  },
  body: {
    paddingLeft: 15,
    paddingRight: 16,
    fontWeight: 'normal',
    fontSize: 14,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    height: 48,
    //minWidth: 100,
    overflow: 'hidden',
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
      paddingRight: 0,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))(TableCell);

const TableGrid = forwardRef(
  (
    {
      classes,
      tableHeading,
      onPayClick,
      selfReportingTemplate,
      data,
      viewDetailsHandler,
      handleViewDetailsInAssessment,
      useResponseGrid = false,
      disableButton = {},
      paginationObj: {
        handlepageNumber,
        loading = false,
        detailsLoading = false,
        hasInfiniteScroll = false,
        infiniteScrollMargin = '100px',
      } = {},
      screenMode: { isCircle = false, involvesConsent = false },
      viewRecordsHandler,
      viewBtnTitle = '',
      onRefresh,
      reloadOnScroll,
      nextPageId,
    },
    ref,
  ) => {
    const [tableBodyData, setTableData] = useState(data);
    const observor = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const showViewDetailsHandler = location.pathname?.includes('individual');
    const [snackbar, setSnackbar] = useState({ message: null, type: null });
    useEffect(() => {
      setTableData(data);
      if (!hasInfiniteScroll && observor && reloadOnScroll) {
        observor.current?.disconnect();
      }
    }, [data]);

    useEffect(() => {
      if (!hasInfiniteScroll && observor && reloadOnScroll) {
        observor.current?.disconnect();
      }
    }, [hasInfiniteScroll]);

    const lastElementObserver = useCallback((element) => {
      if (loading || !element || !reloadOnScroll) {
        return;
      }
      if (observor.current) {
        observor.current.disconnect();
      }
      observor.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            if (hasInfiniteScroll) {
              handlepageNumber();
            }
          }
        },
        {
          rootMargin: infiniteScrollMargin,
        },
      );
      if (element) {
        observor.current.observe(element);
      }
    });

    const displayStatus = (status) => {
      const color = statusColorFun(status);
      return (
        <div className={classes.statusContainer}>
          {color && (
            <span className={classes.statusColumn}>
              <StatusIcon htmlColor={color} className={classes.statusIcon} />
            </span>
          )}
          {capitalizeFirstLetter(status) ?? DEFAULT_NULL_REPLACEMENT}
        </div>
      );
    };
  
    const renderStatusView = (bodyObject, headerObj) => {
      const { filterVals } = headerObj;
      const statusObj = getMultipleObjFromSource(bodyObject, headerObj);
      return (
        <div className={classes.statusContainer}>
          {statusObj[filterVals?.[0]] && statusObj[filterVals?.[1]] && (
            <span className={classes.statusColumn}>
              <StatusIcon htmlColor={statusObj?.[filterVals[0]]} className={classes.statusIcon} />
            </span>
          )}
          {statusObj[filterVals?.[1]] ? capitalizeFirstLetter(statusObj[filterVals?.[1]]) : '-'}
        </div>
      );
    };

    const renderStatusViewFromDiffLocation = (bodyObject, headerObj) => {
      const statusColor = getValueFromSourceList(bodyObject, headerObj);
      const status = objectPath.get(bodyObject, headerObj?.dataCol);
      return (
        <div className={classes.statusContainer}>
          {statusColor && (
            <span className={classes.statusColumn}>
              <StatusIcon htmlColor={statusColor} className={classes.statusIcon} />
            </span>
          )}
          {status ? capitalizeFirstLetter(status) : '-'}
        </div>
      );
    };  

    const renderDataSourceColumn = (obj, headObj) => {
      let showToolTip = false;
      const dataSource = getValueFromSourceList(obj, headObj);
      if (dataSource >= 15) {
        showToolTip = true;
      }
      const toolTipHtml = (partnerName) => {
        return <span className={classes.tooltipDisplay}>{partnerName}</span>;
      };
      return showToolTip ? (
        <Tooltip arrow title={toolTipHtml(dataSource)} classes={{ tooltip: classes.tooltip }}>
          <span className={classes.dataSource}>{dataSource}</span>
        </Tooltip>
      ) : (
        <span className={classes.dataSource}>{dataSource}</span>
      );
    };

    const getFileNameAndExtension = (docArn) => {
      const parts = docArn.split('/');
      const fileName = parts[parts.length - 1];
      const [fileNameWithoutExtension, extension] = fileName.split('.');
      return { fileNameWithoutExtension, extension };
    };
  
    const viewDocument = async (documentUrl) => {
      setSnackbar({ message: 'Opening document...', type: SNACKBAR_TYPE.INFO });
      try {
        const res = await DocumentService.downloadFile(
          documentUrl,
          DOCUMENT_SERVICE_END_POINTS.SCAN_DOC_BUCKET_NAME,
        );
        const fileInfo = getFileNameAndExtension(documentUrl);
        const blob = new Blob([res.data], { type: getMimeType(fileInfo.extension) });
        const blobUrl = window.URL.createObjectURL(blob);
  
        // Open the file in a new tab
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.write(`
            <html>
              <head>
                <title>${fileInfo.fileNameWithoutExtension}.${fileInfo.extension}</title>
              </head>
              <body style="margin:0;padding:0;">
                <iframe src="${blobUrl}" style="border:none;width:100%;height:100vh;"></iframe>
              </body>
            </html>
          `);
        } else {
          // If popup is blocked, fallback to downloading the file
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `${fileInfo.fileNameWithoutExtension}.${fileInfo.extension}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
  
        // Clean up the Blob URL after a short delay
        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
  
        setSnackbar({ message: 'Document opened successfully', type: SNACKBAR_TYPE.SUCCESS });
      } catch (err) {
        console.error('Error viewing document:', err);
        setSnackbar({
          message: 'Something went wrong while opening the document',
          type: SNACKBAR_TYPE.ERROR,
        });
      }
    };
  
    const getMimeType = (extension) => {
      const mimeTypes = {
        'pdf': 'application/pdf',
        'doc': 'application/msword',
        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'ppt': 'application/vnd.ms-powerpoint',
        'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'jpg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'txt': 'text/plain'
      };
      return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
    };

    const renderNameColumn = (val, isTitleCase, headerObj, bodyObj) => {
      let showToolTip = false;
      let docUrl = bodyObj?.documentResponse?.document?.documentUrl
      if (headerObj.headerName === 'Document name') {
        return (
          <a 
            href="#"
            style={{color: '#036fcb', textDecoration: 'underline', cursor: 'pointer'}}
            onClick={(e) => {
              e.preventDefault();
              viewDocument(docUrl);
            }}
          >
            {val}
          </a>
        );
      }
      if(val === DUMMY_DATE_STUB){
        return '-';
      }
      if (val && val.length > 50) {
        showToolTip = true;
      }
      if (isTitleCase) {
        val = convertStrToTitleCase(val);
      }
      const toolTipHtml = (partnerName) => {
        return <span className={classes.tooltipDisplay}>{partnerName}</span>;
      };

      return showToolTip ? (
        <Tooltip arrow title={toolTipHtml(val)} classes={{ tooltip: classes.tooltip }}>
          <span>{val}</span>
        </Tooltip>
      ) : (
        <span>{val ?? '-'}</span>
      );
    };

    const renderHotLink = (rowObj) => {
      return (
        rowObj.hotLink && (
          <Tooltip
            placement="top"
            title="On clicking, you will be redirected to eHealth's site to view the coverage details"
            classes={{ tooltip: classes.hotLinkTooltip }}
          >
            <img
              id="hot-link-icon"
              onClick={() => redirectHotlink(rowObj.hotLink)}
              src={hotLinkIcon}
              className={classes.hotLink}
            />
          </Tooltip>
        )
      );
    };


    const getClickableValueFromSourceList = (obj, headerObj) => {
      const { filterKey, filterVal, key, source, resultKey } = headerObj;
      const handleClick = (clickableValue) => {
        // Pass `formattedData` during navigation
        navigate('/records/new/care_team');
      
        // Delay subsequent logic for API call
        setTimeout(async () => {
          const formattedData = JSON.parse(sessionStorage.getItem('formattedData'))        
          const matchingRecord = formattedData.find(
            (data) => data.name === clickableValue
          );
      
          if (!matchingRecord) {
            console.error("No matching record found for:");
            return;
          }
          const resourceId = matchingRecord.id;
          const resourceType = "care_team"
          viewDetailsHandler(resourceId, resourceType)
        }, 2000);
      };
    
      objectPath.ensureExists(obj, source, DEFAULT_NULL_REPLACEMENT);
      const sourceList = objectPath.get(obj, source);
      if (!sourceList?.length) {
        return '-';
      } else {
        const res =
          sourceList !== DEFAULT_NULL_REPLACEMENT
            ? sourceList?.find((obj) => obj?.[filterKey] === filterVal)
            : null;
        if (res?.[resultKey] === DUMMY_DATE_STUB) {
          return '-';
        }
        const clickableValue = res?.[resultKey] || DEFAULT_NULL_REPLACEMENT;
        return (
          <span
            style={{ cursor: 'pointer', color: '#036fcb'}}
            onClick={() => handleClick(clickableValue)}
          >
            {clickableValue}
          </span>
        );
      }
    };

    const redirectHotlink = (url) => {
      window.open(`https://${url}`, '_blank');
    };

    const getTransformedValue = (bodyObj, headerObj) => {
      const res = getValueFromOptions(
        bodyObj,
        { ...headerObj, key: headerObj.dataCol, label: headerObj.headerName },
        selfReportingTemplate,
      );
      return res;
    };

    const handleDocumentDelete = async (document) => {
      const documentReferenceId = document?.id;
      try {
        setSnackbar({ message: 'Deleting Document...', type: SNACKBAR_TYPE.INFO });
        const res = await DocumentService.deleteDocument(documentReferenceId);
        onRefresh();
        setSnackbar({ message: 'Deleted document', type: SNACKBAR_TYPE.SUCCESS });
      } catch (err) {
        console.log(err);
        setSnackbar({
          message: 'Something went wrong while downloading the document',
          type: SNACKBAR_TYPE.ERROR,
        });
      }
    };

    const handleDocumentDownload = async ({ document }) => {
      const docArn = document?.documentUrl;

      setSnackbar({ message: 'Downloading document...', type: SNACKBAR_TYPE.INFO });
      try {
        const res = await DocumentService.downloadFile(
          docArn,
          DOCUMENT_SERVICE_END_POINTS.SCAN_DOC_BUCKET_NAME,
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        openDocument({ url: url, binaryData: res.data, docArn: docArn });
        setSnackbar({ message: 'Downloaded document successfully', type: SNACKBAR_TYPE.SUCCESS });
      } catch (err) {
        setSnackbar({
          message: 'Something went wrong while downloading the document',
          type: SNACKBAR_TYPE.ERROR,
        });
      }
    };

    const openDocument = async (documentObj) => {
      const fileInfo = getFileNameAndExtension(documentObj.docArn);
      const link = document.createElement('a');
      link.href = documentObj.url;
      link.setAttribute('download', `${fileInfo.fileNameWithoutExtension}.${fileInfo.extension}`);
      document.body.appendChild(link);
      link.click();
    };

    return (
      <React.Fragment>
        <SnackbarToast
          open={!!snackbar.message}
          message={snackbar.message ?? ''}
          duration={4000}
          onClose={() => setSnackbar({ ...snackbar, message: null })}
          type={snackbar.type}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        />
        <TableContainer
          ref={ref}
          id="scrollableDiv"
          component={PaperWithoutShadow}
          className={classes.tableContainer}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow style={{ height: 48 }}>
                {tableHeading?.map((headObject, i) => {
                  if (headObject?.headerName === 'Data source' && headObject?.hide) {
                    return null;
                  }
                  return <StyledTableCell key={i}>{headObject.headerName}</StyledTableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableBodyData?.map((bodyObject, idx) => {
                let isLastElement = nextPageId ? true : false;
                // if (idx === tableBodyData?.length - 1) {
                //   isLastElement = true;
                // }
                return (
                  <TableRow
                    key={Math.random()}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ height: 48 }}
                    ref={isLastElement ? lastElementObserver : null}
                  >
                    {tableHeading?.map((headerObj, index) => {
                      if (headerObj.type === 'downloadButton') {
                        return (
                          <StyledTableCell key={index}>
                            <div className={classes.iconContainer}>
                              <a
                                className={`${classes.iconBackground} ${classes.downloadMargin}`}
                                onClick={() => handleDocumentDownload(bodyObject?.documentResponse)}
                              >
                                <img className={classes.menu} src={DocDownloadIcon} />
                              </a>

                              <a
                                className={classes.iconBackground}
                                onClick={() => handleDocumentDelete(bodyObject)}
                              >
                                <img className={classes.menu} src={TrashIcon} />
                              </a>
                            </div>
                          </StyledTableCell>
                        );
                      } else if (headerObj.type === 'download') {
                        const downloadObj = bodyObject?.extension?.find(
                          (item) => item.url === 'download',
                        );
                        return (
                          <StyledTableCell key={index}>
                            <div className={classes.iconContainer}>
                              <a
                                className={`${classes.iconBackground} ${classes.downloadLeft}`}
                                onClick={() => handleDocumentDownload(downloadObj)}
                              >
                                <img className={classes.menu} src={DocDownloadIcon} />
                              </a>
                            </div>
                          </StyledTableCell>
                        );
                      } else if (headerObj.type === 'empty') {
                        return <StyledTableCell key={index}></StyledTableCell>;
                      } else if (headerObj.type === 'button') {
                        let assessmentButtonTitle = '';
                        let isButtonDisabled = false;
                        if (Object.keys(disableButton)?.length > 0) {
                          let statusValue = getMultipleObjFromSource(bodyObject, disableButton);
                          if (typeof statusValue === 'object') {
                            statusValue = statusValue?.status;
                          }
                          statusValue = statusValue?.toLowerCase();
                          assessmentButtonTitle =
                            statusValue && statusValue !== '-'
                              ? disableButton?.comparatorValues?.includes(statusValue)
                                ? disableButton?.buttonTitleView
                                : disableButton?.buttonTitleFillNow
                              : '-';
                          isButtonDisabled =
                            assessmentButtonTitle === disableButton?.buttonTitleView &&
                            statusValue !== 'completed'
                              ? true
                              : false;
                        }

                        return (
                          <StyledTableCell key={index} className={classes.moreWrapperBtn}>
                            {/* {!isCircle && !involvesConsent && (
                            <Button
                              onClick={() => (onPayClick ? onPayClick(row[['id']]) : null)}
                              rootClass={classes.payBtn}
                              title="Pay now"
                            />
                          )} */}
                            {involvesConsent && !showViewDetailsHandler && (
                              <Button
                                onClick={() =>
                                  viewRecordsHandler ? viewRecordsHandler(bodyObject['id']) : null
                                }
                                className={classes.viewRecordButton}
                                title={'View records'}
                              />
                            )}
                            {!useResponseGrid ? (
                              <Button
                                onClick={() =>
                                  viewDetailsHandler ? viewDetailsHandler(bodyObject['id']) : null
                                }
                                className={classes.viewDetailsButton}
                                title={viewBtnTitle || 'View details'}
                              />
                            ) : (
                              <Button
                                onClick={() =>
                                  handleViewDetailsInAssessment && assessmentButtonTitle !== '-'
                                    ? handleViewDetailsInAssessment(
                                        bodyObject['id'],
                                        assessmentButtonTitle === disableButton?.buttonTitleView,
                                      )
                                    : null
                                }
                                className={classes.viewDetailsButton}
                                title={assessmentButtonTitle}
                                disabled={isButtonDisabled}
                                inactive={isButtonDisabled}
                              />
                            )}
                          </StyledTableCell>
                        );
                      }

                      // if (headerObj.type === 'hotLink') {
                      //   return (
                      //     <StyledTableCell key={index}>
                      //       {objectPath.get(bodyObject, headerObj.dataCol)}
                      //       {renderHotLink(bodyObject)}
                      //     </StyledTableCell>
                      //   );
                      // }
                      if (headerObj.type === 'link') {
                        return (
                          <StyledTableCell key={index}>
                            {getClickableValueFromSourceList(bodyObject, headerObj) || DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj.type === 'nameWithImg') {
                        return (
                          <StyledTableCell key={index}>
                            <span className={classes.ImgWrapper}>
                              {/* {bodyObject[headerObj.dataCol]?.imgUrl ? (
                              <span className={classes.imgIcon}>
                                <img
                                  src={bodyObject[headerObj.dataCol].imgUrl}
                                  alt={bodyObject[headerObj.dataCol].Name}
                                  className={classes.imgIcon}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = UserCircle;
                                  }}
                                />
                              </span>
                            ) :  */}
                              {bodyObject[headerObj.dataCol] ? (
                                <p
                                  className={classes.initialWrapper}
                                  style={{ backgroundColor: '#00A0DF' }}
                                >
                                  {bodyObject[headerObj?.dataCol]?.[0].toUpperCase()}
                                </p>
                              ) : (
                                ''
                              )}
                              {bodyObject[headerObj.dataCol]}
                            </span>
                          </StyledTableCell>
                        );
                      }
                      if (headerObj.type === 'pillString') {
                        if (headerObj?.headerName === 'Data source' && headerObj?.hide) {
                          return null;
                        }
                        return (
                          <StyledTableCell key={index}>
                            {renderDataSourceColumn(bodyObject, headerObj) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj.type === 'filter') {
                        return (
                          <StyledTableCell key={index}>
                            {getValueFromSourceList(bodyObject, headerObj) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj.type === 'needTransformation') {
                        return (
                          <StyledTableCell key={index}>
                            {getValueFromTransformation(bodyObject, headerObj) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj?.type === 'dotValue' && headerObj.dataCol) {
                        return (
                          <StyledTableCell key={index}>
                            {renderStatusViewFromDiffLocation(bodyObject, headerObj) || DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj?.type === 'dotValue') {
                        return (
                          <StyledTableCell key={index}>
                            {renderStatusView(bodyObject, headerObj) || DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj?.type === 'status') {
                        return (
                          <StyledTableCell key={index}>
                            {displayStatus(bodyObject?.status) || DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj?.type === 'nameWithCode') {
                        return (
                          <StyledTableCell key={index}>
                            {renderNameColumn(getNameWithCodeField(bodyObject, headerObj)) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj?.type === 'requireTransformation') {
                        return (
                          <StyledTableCell key={index}>
                            {renderNameColumn(getTransformedValue(bodyObject, headerObj)) ||
                              DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj?.type === 'nameWithCodeExtension') {
                        return (
                          <StyledTableCell key={index}>
                            {renderNameColumn(
                              getValueNameWithCodeExtension(bodyObject, headerObj),
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj.type === 'string') {
                        objectPath.ensureExists(
                          bodyObject,
                          headerObj?.dataCol,
                          DEFAULT_NULL_REPLACEMENT,
                        );
                        return (
                          <StyledTableCell key={index}>
                            {renderNameColumn(
                              String(
                                objectPath.get(bodyObject, headerObj?.dataCol) ||
                                  DEFAULT_NULL_REPLACEMENT,
                              ),
                              headerObj?.isTitleCase,
                              headerObj,
                              bodyObject,
                            ) || DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                      if (headerObj.type === 'stringWithFirstLetter') {
                        objectPath.ensureExists(
                          bodyObject,
                          headerObj?.dataCol,
                          DEFAULT_NULL_REPLACEMENT,
                        );
                        const name = String(
                          objectPath.get(bodyObject, headerObj?.dataCol) ||
                            DEFAULT_NULL_REPLACEMENT,
                        );
                        return (
                          <StyledTableCell key={index}>
                            {name !== DEFAULT_NULL_REPLACEMENT && name?.length ? (
                              <span className={classes.bgColorForInitial}>
                                {name?.[0]?.toUpperCase()}
                              </span>
                            ) : (
                              ''
                            )}
                            {renderNameColumn(name) || DEFAULT_NULL_REPLACEMENT}
                          </StyledTableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {loading && !detailsLoading && <h5 className={classes.endOfFileText}>Loading...</h5>}
          {/* {!hasInfiniteScroll && (
            <h5 className={classes.endOfFileText}>Yah! you have seen it all</h5>
          )} */}
        </TableContainer>
      </React.Fragment>
    );
  },
);
export default withStyles(styles, { withTheme: true })(TableGrid);