import gql from 'graphql-tag';
import client from '../../../components/ApolloClient';
// import { CODE_FRAGMENT, PROCEDURES_FRAGMENT } from '../../../../utils/fragments';

const FETCH_ALL_RECORDS = gql`
  query fetchAllRecords($recordRequest: RecordRequest) {
    fetchAllRecords(recordRequest: $recordRequest) {
      recordList
      count
      nextPageId
    }
  }
`;
const FETCH_DATA_BY_ID = gql`
  query fetchRecordById($resourceType: String, $resourceId: String, $grantorId: String) {
    fetchRecordById(resourceType: $resourceType, resourceId: $resourceId, grantorId: $grantorId) {
      record
      docArn
      documentReferenceId
    }
  }
`;
const MARKED_AS_RESTRICTED = gql`
  mutation updateMarkAsRestrictedRecord(
    $resourceId: String
    $markAsRestricted: String
    $resourceType: String
  ) {
    updateMarkAsRestrictedRecord(
      resourceId: $resourceId
      markAsRestricted: $markAsRestricted
      resourceType: $resourceType
    ) {
      record
    }
  }
`;

const FETCH_HISTORICAL_RECORDS_FOR_REUSABLE_COMPONENTS = gql`
  query fetchHistoricalRecords($recordRequest: RecordRequest) {
    fetchHistoricalRecords(recordRequest: $recordRequest) {
      recordList
      nextPageId
      count
    }
  }
`;

const FETCH_RECORD_BY_ID = gql`
  query fetchRecordById($resourceType: String, $resourceId: String, $grantorId: String) {
    fetchRecordById(resourceType: $resourceType, resourceId: $resourceId, grantorId: $grantorId) {
      record
      docArn
      documentReferenceId
    }
  }
`;
const CREATE_SELF_REPORTING_DATA = gql`
  mutation createSelfReportDataRecords($selfReported: SelfReported) {
    createSelfReportDataRecords(selfReported: $selfReported) {
      record
    }
  }
`;

const GenericServices = {
  fetchAllRecords(variables, query = FETCH_ALL_RECORDS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            recordRequest: variables,
          },
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              domain: window.location.hostname.startsWith('demo.mpowered') ? 'DEMO' : 'DEFAULT',
            },
          },
        })
        .then((response) => {
          resolve(response.data.fetchAllRecords);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchRecordById(args, query = FETCH_DATA_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchRecordById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMarkAsRestrictedRecord(args, mutation = MARKED_AS_RESTRICTED) {
    const variables = {
      resourceId: String(args?.resourceId),
      markAsRestricted: String(args?.markAsRestricted),
      resourceType: String(args?.resourceType),
    };
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables,
        })
        .then((response) => {
          resolve(response.data.updateMarkAsRestrictedRecord);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchHistoricalRecords(args, query = FETCH_HISTORICAL_RECORDS_FOR_REUSABLE_COMPONENTS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            recordRequest: args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchHistoricalRecords);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRecordById(args, query = FETCH_RECORD_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchRecordById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createSelfReportDataRecords(args, mutation = CREATE_SELF_REPORTING_DATA) {
    const { valuesEntered, resourceId, resourceType, type = 'add' } = args;
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            selfReported: {
              data: JSON.stringify(valuesEntered),
              resourceType,
              resourceId,
              type,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.createSelfReportDataRecords);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default GenericServices;
