import AddPartnerImage from '../assets/images/AddPartner.svg';
import ActiveIcon from '../assets/images/Circles/active.svg';
import AppleHealthIcon from '../assets/images/Circles/apple_icon.svg';
import CompletedIcon from '../assets/images/Circles/completed.svg';
import FileGif from '../assets/images/Circles/file_gif.gif';
import GrayColorStatusIcon from '../assets/images/Circles/grayColorStatusIcon.svg';
import IndividualIcon from '../assets/images/Circles/Circles_Individual.svg';
import MyMedicareIcon from '../assets/images/Circles/my_medicare.svg';
import NoIndividual from '../assets/images/Circles/not_found.svg';
import partnerNotFound from '../assets/images/Circles/partnerNotFound.svg';
import PartnerIcon from '../assets/images/Circles/Circles_Organization.svg';
import PendingIcon from '../assets/images/Circles/pending.svg';
import RejectIcon from '../assets/images/Circles/reject.svg';
import SuccessIcon from '../assets/images/Circles/SuccessfulIcon.svg';
import consentNotFound from '../assets/images/consent/consentNotFound.svg';
import EarnPointCard4 from '../assets/images/coverageImg.png';
import EarnPointCard2 from '../assets/images/EarnPointCard2.png';
import ErrorAtAddingPartner from '../assets/images/errorAtAddingPartner.svg';
import FailedToLoadImage from '../assets/images/failedToLoad.svg';
import FailedToLoadIcon from '../assets/images/failedToLoadIcon.svg';
import FindCareImage from '../assets/images/findCareImage.svg';
import YellowIcon from '../assets/images/icons/yellow.png';
import NoInternetConnectionImage from '../assets/images/noInternetConnection.svg';
import NoPartnerLeft from '../assets/images/noPartnerLeft.svg';
import colors from './colors';

import AddAdult from '../assets/images/addAdultPNG.png';
import AddMinor from '../assets/images/minorBaby.svg';
import faqIcon from '../assets/images/faqIcon.jpg';

export const SERVICE_TYPE = Object.freeze({
  BILL_PAY: 'bill_pay',
  SECOND_OPINION: 'second_opinion',
  AWARDPOINT: 'award_point',
  WALLET: 'wallet',
  COVERAGE: 'coverage',
  MEDICATIONS: 'medications',
  CONDITIONS: 'conditions',
  PROCEDURES: 'procedures',
  ALLERGIES: 'allergies',
  CLINICAL_VITALS: 'clinical_vitals',
  LAB_RESULTS: 'labs',
  CLAIMS: 'claims',
  IMMUNIZATIONS: 'immunizations',
  VISITS: 'visits',
  SOCIAL_HISTORY: 'smoking_status',
  SETTINGS: 'settings',
  CARE_PLAN: 'care_plan',
  CONSENT: 'consents',
});

export const CIRCLES_SERVICE_TYPE = Object.freeze({
  MEDICATIONS: 'MEDICATIONS',
  CONDITIONS: 'CONDITIONS',
  PROCEDURES: 'PROCEDURES',
  ALLERGIES: 'ALLERGIES',
  CLINICAL_VITALS: 'VITALS',
  LAB_RESULTS: 'LAB_RESULTS',
  CLAIMS: 'CLAIMS',
  IMMUNIZATIONS: 'IMMUNIZATIONS',
  VISITS: 'VISITS',
  COVERAGE: 'COVERAGE',
  SOCIAL_HISTORY: 'SMOKING_HISTORY',
  CARE_PLAN: 'CARE_PLAN',
});

// , 'Care', 'Legal'
export const SERVICE_TAB_HEADING =  ['Financial', 'Care', 'Medical','Legal'];
export const INDIVIDUAL_TAB_HEADING = ['Financial', 'Care','Medical'];
export const UTILITY_TAB_HEADING = ['Wallet', 'Account', 'Contact'];
export const SHOWING_RESOURCES = (number, resource) => `Showing ${number} ${resource}`;

export const HOME = [
  // {
  //   service: {
  //     serviceName: 'Second Opinions',
  //     iconUrl:
  //       'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/secondopinion/secondopinion.png',
  //     notifications: 0,
  //     screenPathWeb: '/secondopiniondashboard',
  //     borderColor: '#32c5ff',
  //     serviceType: SERVICE_TYPE.SECOND_OPINION,
  //   },
  // },
  {
    service: {
      serviceName: 'Lab results',
      iconUrl:
        'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/labresults/labresults-web.svg',
      notifications: 0,
      screenPathWeb: '/labResultsDashboard',
      borderColor: '#32c5ff',
      serviceType: SERVICE_TYPE.LAB_RESULTS,
    },
  },
  {
    service: {
      serviceName: 'Bills',
      iconUrl: 'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/bills/bills-web.svg',
      notifications: 0,
      screenPathWeb: '/bill',
      borderColor: '#003A64',
      serviceType: SERVICE_TYPE.BILL_PAY,
    },
  },
  {
    service: {
      serviceName: 'Award points',
      iconUrl:
        'https://mpowered-assets.s3.us-east-2.amazonaws.com/services/awardpoint/awardpoint-web.svg',
      notifications: 0,
      screenPathWeb: '/utilities/awardpoints',
      borderColor: '#F5A540',
      serviceType: SERVICE_TYPE.AWARDPOINT,
    },
  },
];

export const SYSTEMS = {
  MPOWERED_SYSTEM: 'https://mpoweredhealth.com/',
  CMS_SYSTEM: 'https://www.cms.gov/',
};

export const MPOWERED_INFERRED = {
  INFERRED_DATA: 'This data was inferred by Mpowered Health from <RESOURCE>',
  PARENT_RESOURCES: {
    CLAIMS: 'claims',
  },
};

export const DATE_SORT_ORDER = {
  ASC: 'Date created (oldest)',
  DESC: 'Date created (newest)',
};

export const RELATED_RECORDS_TEXT = 'Related records';

export const CIRCLES = [
  {
    caption: 'My Health Plan',
    image: '/src/assets/images/healthPlan.svg',
    pwaImage: '/src/assets/images/pwaMyHealthPlan.svg',
    borderColor: '#DFD9D9',
  },
  {
    caption: 'My ObGyn',
    image: '/src/assets/images/obGyn.svg',
    pwaImage: '/src/assets/images/pwaMyObgyn.svg',
    borderColor: '#DFD9D9',
  },
  {
    caption: 'My PCP',
    image: '/src/assets/images/pcp.png',
    pwaImage: '/src/assets/images/pwaMyPCP.jpg',
    borderColor: '#DFD9D9',
  },
  {
    caption: 'My Pediatrician',
    image: '/src/assets/images/paediatrician.png',
    pwaImage: '/src/assets/images/pwaMyPaeditrician.jpg',
    borderColor: '#DFD9D9',
  },
  {
    caption: 'My Dental',
    image: '/src/assets/images/dental.png',
    pwaImage: '/src/assets/images/pwaMyDental.jpg',
    borderColor: '#DFD9D9',
  },
  {
    caption: 'My Care Team',
    image: '/src/assets/images/care.svg',
    pwaImage: '/src/assets/images/pwaMyCareTeam.svg',
    borderColor: '#DFD9D9',
  },
  {
    caption: 'My Friends',
    image: '/src/assets/images/friends.svg',
    pwaImage: '/src/assets/images/pwaMyFriends.svg',
    borderColor: '#DFD9D9',
  },
];

export const CIRCLES_GROUPS = [
  {
    caption: 'Son',
    image: '/src/assets/images/son.svg',
    pwaImage: 'src/assets/images/pwaSon.svg',
    borderColor: '#dfd9d9',
  },
  {
    caption: 'Daughter',
    image: '/src/assets/images/daughter.svg',
    pwaImage: 'src/assets/images/pwaDaughter.svg',
    borderColor: '#dfd9d9',
  },
  {
    caption: 'Parent',
    image: '/src/assets/images/parent.svg',
    pwaImage: 'src/assets/images/pwaFamily.svg',
    borderColor: '#dfd9d9',
  },
  {
    caption: 'Others',
    image: '/src/assets/images/others.svg',
    pwaImage: 'src/assets/images/pwaOthers.svg',
    borderColor: '#dfd9d9',
  },
];

export const MORE_SERVICES_MODAL_ICONS = [
  {
    caption: 'Medications',
    image: '',
    link: '/circles/medicare/medications',
    backgroundColor: '',
    tintColor: '',
    active: false,
  },
  {
    caption: 'Allergies',
    image: '',
    link: '/circles/medicare/allergies',
    backgroundColor: colors.NAVY,
    tintColor: '',
    active: false,
  },

  {
    caption: 'Immunizations',
    image: '',
    link: '',
    backgroundColor: colors.NAVY,
    tintColor: '',
    active: false,
  },
  {
    caption: 'Vitals',
    image: '',
    link: '',
    backgroundColor: colors.NAVY,
    tintColor: '',
    active: false,
  },
];

export const MY_MEDICARE_MENU_ITEMS = [
  {
    caption: 'Claims',
    image: '',
    link: '/circles/medicare/claims',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },
  {
    caption: 'Conditions',
    image: '',
    link: '/circles/medicare/conditions',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },
  {
    caption: 'Procedures',
    image: '',
    link: '/circles/medicare/procedures',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },

  ...MORE_SERVICES_MODAL_ICONS,
];

export const AHK_MENU_ITEMS = [
  {
    caption: 'Medications',
    image: '',
    link: '/circles/ahk/medications',
    backgroundColor: '',
    tintColor: '',
    active: false,
  },
  {
    caption: 'Conditions',
    image: '',
    link: '/circles/ahk/conditions',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },
  {
    caption: 'Allergies',
    image: '',
    link: '/circles/ahk/allergies',
    backgroundColor: colors.NAVY,
    tintColor: '',
    active: false,
  },
  {
    caption: 'Vitals',
    image: '',
    link: '/circles/ahk/vitals',
    backgroundColor: colors.NAVY,
    tintColor: '',
    active: false,
  },
  {
    caption: 'Immunizations',
    image: '',
    link: '/circles/ahk/immunizations',
    backgroundColor: colors.NAVY,
    tintColor: '',
    active: false,
  },
  {
    caption: 'Procedures',
    image: '',
    link: '/circles/ahk/procedures',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },
  {
    caption: 'Lab results',
    image: '',
    link: '/circles/ahk/labResults',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },
];

export const RELATED_RECORDS_DEFAULT_ICONS = [
  {
    caption: 'Claims',
    image: '',
    link: '/circles/medicare/claims',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },
  {
    caption: 'Conditions',
    image: '',
    link: '/circles/medicare/conditions',
    backgroundColor: colors.NAVY,
    tintColor: colors.WHITE,
  },
  {
    caption: 'Procedures',
    image: '',
    link: '/circles/medicare/procedures',
    backgroundColor: '',
    tintColor: '',
  },
  {
    caption: 'More',
    image: '',
    backgroundColor: '',
    tintColor: '',
  },
];

export const ACTIVE_CIRCLES = [
  {
    name: 'My Medicare',
    value: 'medicare',
    icon: MyMedicareIcon,
  },
  {
    name: 'Apple Health',
    value: 'ahk',
    icon: AppleHealthIcon,
  },
];

export const DATA_SOURCE = {
  'Apple Health': 'https://www.apple.com/ios/health/',
  'My Medicare': ['https://www.cms.gov/', 'bluebutton.cms.gov'],
};

export const CIRCLES_LABELS = {
  ANTHEM_BLUECROSS: 'Anthem Bluecross',
  STANFORD_HEALTHCARE: 'Stanford Healthcare',
  APPLE_DENTAL: 'Apple Dental',
  MY_CARE_TEAM: 'My Care Team',
  MY_MEDICARE: 'My Medicare',
  APPLE_HEALTHKIT: 'Apple Health',
  MY_SUPPORT_GROUP: 'My Support Group',
  SON: 'Son',
  DAUGHTER: 'Daughter',
  PARENT: 'Parent',
};

export const SERVICES_DASHBOARD_TEXTS = {
  LOADING_SERVICES: 'Loading records',
};
export const UTILITIES_DASHBOARD_TEXTS = {
  HEADING: 'Utilities',
  LOADING_UTILITIES: 'Loading utilities',
  WALLET: 'Wallet',
};

export const CIRCLES_PARTNER_URL = {
  [CIRCLES_LABELS.MY_MEDICARE]: 'https://www.cms.gov/',
  [CIRCLES_LABELS.APPLE_HEALTHKIT]: 'https://www.apple.com/ios/health/',
};

export const CLAIMS = 'Claims';
export const CONDITIONS = 'Conditions';
export const MEDICATIONS = 'Medications';
export const PROCEDURES = 'Procedures';
export const ALLERGIES = 'Allergies';

export const CLAIMS_DASHBOARD_LABELS = Object.freeze({
  MEDICAL: 'Medical',
  DRUGS: 'Drugs',
  TOTAL: 'Total',
});

export const CLAIMS_DASHBOARD_TEXTS = Object.freeze({
  DUE: 'Your claims due',
  PATIENT: 'Patient portion',
  INSURER: 'Insurer portion',
  SUB_HEADER: 'Here is a helpful breakdown',
  LOADING_CLAIMS: 'Loading claims',
  LOADING_CLAIM_DETAILS: 'Loading claim details',
  NO_RECORD: '0 records found',
  DROPDOWN_LABELS: {
    CLAIM: 'Select claim type',
    TP: 'Time period',
  },
  CLAIM_NUMBER: 'Claim number:',
  TAB_LABEL: ['Claims Summary', 'Claims'],
  SUMMARY: 'Claims summary',
  TOTAL_AMOUNT: 'Total claim amount',
  PATIENT_CLAIMS_SUMMARY: 'Patient claims summary',
  COLLAPSE_TEXT: 'Click on collapse to hide breakup',
  COLLAPSE: 'Collapse',
  EXPAND_TEXT: 'Click on expand to view breakup',
  EXPAND: 'Expand',
});

export const BILL_STATUS_PAY_MAPPING = {
  cancelled: 'Cancelled',
  enteredinerror: 'Cancelled',
  draft: 'Waiting for confirmation',
  balanced: 'Paid',
  issued: 'Paid',
};

export const CMS_CLAIMS_TYPE_TEXTS = {
  all: 'ALL',
  carrier: 'Professional services',
  dme: 'Durable medical equipment',
  hha: 'Home health services',
  hospice: 'Hospice',
  inpatient: 'Inpatient',
  outpatient: 'Outpatient',
  pde: 'Prescription drugs',
  snf: 'Skilled nursing facility',
  oral: 'Oral',
  pharmacy: 'Pharmacy',
  vision: 'Vision',
  institutional: 'Institutional services',
  professional: 'Professional services',
};

export const TIME_PERIOD = {
  ALL: 'All',
  ONE_MONTH: '1 month',
  ONE_YEAR: '1 year',
  FIVE_YEARS: '5 years',
  TEN_YEARS: '10 years',
};

export const TIME_PERIOD_ARRAY = [
  {
    type: 'ALL',
    value: 'All',
  },
  {
    type: 'ONE_MONTH',
    value: '1 month',
  },
  {
    type: 'ONE_YEAR',
    value: '1 year',
  },
  {
    type: 'FIVE_YEARS',
    value: '5 years',
  },
  {
    type: 'TEN_YEARS',
    value: '10 years',
  },
];

export const EOB_COST_LABELS = Object.freeze({
  institutionalCost: 'Institutional',
  pharmacyCost: 'Pharmacy',
  professionalCost: 'Professional',
  hhaCost: 'HHA',
  dmeCost: 'DME',
  visionCost: 'Vision',
  oralCost: 'Dental',
});

export const CLAIMS_DETAILS_TEXT = {
  TITLE: 'Claim details',
  CLAIMS_OBJECT_TEXT: {
    claimNumber: 'Claim number',
    type: 'Type',
    providerNumber: 'Provider number',
    insurerPaid: 'Insurer portion',
    patientPaid: 'Patient portion',
    serviceDate: 'Service date',
    services: 'Services',
    serviceText: {
      service: 'Service',
      qty: 'Quantity',
      patientPaid: 'Patient portion',
      insurerPaid: 'Insurer portion',
    },
  },
  LOADER: 'Loading claim details...',
  LKD_RECORDS: 'Related records',
};

export const CONDITION_DETAIL_LABELS = Object.freeze({
  CATEGORY: 'Category',
  SEVERITY: 'Severity',
  BODYSITE: 'Body site',
  FRDATE: 'First recorded date',
  ABAT_DATE: 'Abatement date',
});

export const CONDITION_DETAIL_TEXTS = Object.freeze({
  CONDITION_DETAILS: 'Condition details',
  CONDITION_DETAIL_LABELS,
  LOADING_DETAILS: 'Loading condition details',
  LOADING_CONDITIONS: 'Loading conditions',
  LKD_RECORDS: 'Related records',
});

export const COVERAGE_DETAIL_TEXTS = Object.freeze({
  COVERAGE_DETAILS: 'Coverage details',
  LOADING_DETAILS: 'Loading coverage details',
  LOADING_COVERAGES: 'Loading coverages',
  LKD_RECORDS: 'Related records',
});

export const LINKED_RECORDS = [...RELATED_RECORDS_DEFAULT_ICONS];

export const MEDICATION_DETAIL_LABELS = Object.freeze({
  PRES_DATE: 'Prescription date',
  DOSAGE: 'Dosage instruction',
  DUR: 'Duration',
  DOS_ROUTE: 'Dosage route',
  DOS_METHOD: 'Dosage method',
  REQTR: 'Requestor',
  NOTES: 'Notes',
});

export const MEDICATION_DETAIL_TEXTS = Object.freeze({
  MEDICATION_DETAILS: 'Medication details',
  MEDICATION_DETAIL_LABELS,
  LOADING_MEDICATIONS: 'Loading medications',
  LOADING_DETAILS: 'Loading medication details',
  LKD_RECORDS: 'Related records',
  ERROR_LOADING: 'Error while loading medication details',
});

export const PROCEDURE_DETAILS_TEXT = {
  PROCEDURE_DETAILS: 'Procedure details',
  LABELS: {
    BODY_SITE: 'Body site',
    PERFORMER: 'Performer',
    PATIENT: 'Patient',
    PROVIDER: 'Organization',
    DATE: 'Date',
    NOTE: 'Note',
    SEE_LESS: 'See less',
    SEE_MORE: 'See more',
  },
  LKD_RECORDS: 'Related records',
  LOADING_PROCEDURES: 'Loading procedures',
  LOADING_DETAILS: 'Loading procedure details',
};

export const LOADING_TEXT_FOR_REUSABLE_COMPONENTS = (name) => `Loading ${name}`;
export const ALLERGY_DETAIL_LABELS = Object.freeze({
  SUBSTANCE: 'Substance',
  TYPE: 'Type',
  CATEGORY: 'Category',
  CRITICALITY: 'Criticality',
  FRDATE: 'First recorded date',
  REACTION: 'Reaction',
});

export const ALLERGY_DETAIL_TEXTS = Object.freeze({
  ALLERGY_DETAILS: 'Allergy details',
  ALLERGY_DETAIL_LABELS,
  LOADING_ALLERGIES: 'Loading allergies',
  LOADING_DETAILS: 'Loading allergy details',
  LKD_RECORDS: RELATED_RECORDS_TEXT,
});

export const IMMUNIZATION_TEXTS = Object.freeze({
  LOADING_IMMUNIZATIONS: 'Loading immunizations',
  LOADING_DETAILS: 'Loading immunization details',
});

export const LAB_RESULTS_TEXTS = Object.freeze({
  LOADING_LAB_RESULTS: 'Loading lab results',
  LOADING_DETAILS: 'Loading lab result details',
});

export const VITALS_RESULTS_TEXTS = Object.freeze({
  LOADING_LAB_RESULTS: 'Loading vitals...',
  LOADING_DETAILS: 'Loading vital details...',
});

export const VISITS_RESULTS_TEXTS = Object.freeze({
  LOADING_VISITS: 'Loading visits',
  LOADING_DETAILS: 'Loading visits details',
});

export const SOCIAL_HISTORY_RESULTS_TEXTS = Object.freeze({
  LOADING_SOCIAL_HISTORY: 'Loading social history',
  LOADING_DETAILS: ' Loading social history details',
});

export const UTILITIES = [
  {
    caption: 'Wallet',
    image: '/src/assets/images/payments.svg',
    link: '/payment/manage',
    pwaImage: 'src/assets/images/pwaPayments.svg',
    inactiveImage: 'src/assets/images/grey-wallet.svg',
    pwaInactiveImage: 'src/assets/images/pwa-grey-wallet',
    borderColor: '#003A64',
    inactiveBorderColor: '#dfd9d9',
    active: true,
  },
  {
    caption: 'Award points',
    image: '/src/assets/images/award_points.svg',
    link: '/utilities/awardpoints',
    pwaImage: '/src/assets/images/pwaAwardPoints.svg',
    inactiveImage: 'src/assets/images/award_points.svg',
    pwaInactiveImage: 'src/assets/images/pwaAwardPoints.svg',
    borderColor: '#003A64',
    inactiveBorderColor: '#dfd9d9',
    active: true,
  },
  {
    caption: 'ID Card',
    image: '/src/assets/images/grey-id.svg.svg',
    pwaImage: 'src/assets/images/pwa-grey-identity-card.svg',
    inactiveImage: 'src/assets/images/grey-id.svg',
    pwaInactiveImage: 'src/assets/images/pwa-grey-identity-card.svg',
    borderColor: '#32c5ff',
    inactiveBorderColor: '#dfd9d9',
    active: true,
  },
  {
    caption: 'Settings',
    image: '/src/assets/images/pwa-grey-settings-work-tool.svg',
    pwaImage: 'src/assets/images/pwa-grey-settings-work-tool.svg',
    link: '/settings',
    borderColor: '#003A64',
    active: true,
  },
  {
    caption: 'Upload',
    image: '/src/assets/images/grey-upload.svg',
    link: '/scanbill',
    pwaImage: 'src/assets/images/pwa-grey-scan-barcode.svg',
    inactiveImage: 'src/assets/images/grey-upload.svg',
    pwaInactiveImage: 'src/assets/images/pwa-grey-scan-barcode.svg',
    borderColor: '#003a64',
    inactiveBorderColor: '#dfd9d9',
    active: false,
  },
];

export const SETTINGS = [
  {
    caption: 'Deactivate',
    image: '/src/assets/images/deactivate.svg',
    link: '/deactivate',
    pwaImage: 'src/assets/images/deactivate.svg',
    inactiveImage: 'src/assets/images/deactivate.svg',
    pwaInactiveImage: 'src/assets/images/deactivate.svg',
    active: true,
  },
];

export const SERVICES_TYPE = {
  BILL_PAY: 'bill_pay',
  SECOND_OPINION: 'second_opinion',
  AWARD_POINT: 'award_point',
  WALLET: 'wallet',
  DIAGNOSTICS: 'diagnostics',
  MEDICATIONS: 'medications',
  LABS: 'labs',
  CLINICAL_VITALS: 'clinical_vitals',
  dental: 'dental',
  VISION: 'vision',
  CONSULTS: 'consults',
  CLAIMS: 'claims',
  APPOINTMENTS: 'appointments',
  CONSENTS: 'consents',
  ID_CARD: 'id_card',
  SETTINGS: 'settings',
  UPLOAD: 'upload',
  CONDITIONS: 'conditions',
  PROCEDURES: 'procedures',
  ALLERGIES: 'allergies',
  IMMUNIZATIONS: 'immunizations',
  NONE: 'none',
  COVERAGE: 'coverage',
  SOCIAL_HISTORY: 'smoking_status',
  FAMILY_MEMBER_HISTORY: 'family_member_history',
  VITAL: 'clinical_vitals',
  VISITS: 'visits',
  CARE_PLAN: 'care_plan',
  CARE_TEAM: 'care_team',
  ASSESSMENTS: 'assessments_dashboard',
  CONSENTS: 'consents',
};

export const SERVICES = {
 
  Financial: [
    {
      title: 'Coverage',
      icon: '/src/assets/images/advancedDirectives.svg',
      image: '',
      inactiveIcon: '/src/assets/images/advancedDirectives_inactive.svg',
      active: true,
      link: '/records/coverage',
      type: SERVICES_TYPE.COVERAGE,
      consentType: 'FinancialCoverage',
    },
    {
      title: 'Claims',
      icon: '/src/assets/images/advancedDirectives.svg',
      image: '',
      inactiveIcon: '/src/assets/images/advancedDirectives_inactive.svg',
      active: true,
      link: '/records/claims',
      type: SERVICES_TYPE.CLAIMS,
      consentType: 'FinancialClaims',
    },
    {
      title: 'Bills',
      icon: '/src/assets/images/bill-service.svg',
      image: '/src/assets/images/bill-pay.jpg',
      active: true,
      link: '/records/bills',
      type: SERVICES_TYPE.BILL_PAY,
      consentType: 'FinancialBills',
    },
  ],
  Care: [
    {
      title: 'Care plans',
      icon: '/src/assets/images/carePlan.svg',
      image: '',
      inactiveIcon: '/src/assets/images/carePlan.svg',
      active: true,
      link: '/records/care_plan',
      type: SERVICES_TYPE.CARE_PLAN,
      consentType: 'CarePlan',
    },
    {
      title: 'Care Team',
      icon: '/src/assets/images/carePlan.svg',
      image: '',
      inactiveIcon: '/src/assets/images/carePlan.svg',
      active: true,
      link: '/records/care_team',
      type: SERVICES_TYPE.CARE_TEAM,
      consentType: 'CareTeam',
    },
    // {
    //   title: 'Appointments',
    //   icon: '',
    //   image: '',
    //   inactiveIcon: '/src/assets/images/circles-filled.svg',
    //   active: true,
    // },
  ],
  Medical : [
    {
      title: 'Conditions',
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/diagnoses_inactive.svg',
      active: true,
      link: '/records/conditions',
      type: SERVICES_TYPE.CONDITIONS,
      consentType: 'MedicalConditions',
    },
    {
      title: 'Procedures',
      icon: '',
      image: '',
      inactiveIcon: '',
      active: true,
      link: '/records/procedures',
      type: SERVICES_TYPE.PROCEDURES,
      consentType: 'MedicalProcedures',
    },
    {
      title: 'Lab results',
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/labsService.svg',
      link: '/records/lab_results',
      active: true,
      type: SERVICES_TYPE.LABS,
      consentType: 'MedicalLabResults',
    },
    {
      title: 'Medications',
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/medication_inactive.svg',
      link: '/records/medications',
      active: true,
      type: SERVICES_TYPE.MEDICATIONS,
      consentType: 'MedicalMedications',
    },
    {
      title: 'Allergies',
      icon: '',
      image: '',
      inactiveIcon: '',
      active: true,
      link: '/records/allergies',
      type: SERVICES_TYPE.ALLERGIES,
      consentType: 'MedicalAllergies',
    },
    {
      title: 'Immunizations',
      icon: '',
      image: '',
      inactiveIcon: '',
      link: '/records/immunizations',
      active: true,
      type: SERVICES_TYPE.IMMUNIZATIONS,
      consentType: 'MedicalImmunizations',
    },
    {
      title: 'Vitals',
      image: '/src/assets/images/so.jpg',
      icon: '/src/assets/images/chat.svg',
      active: true,
      inactiveIcon: '',
      link: '/records/vitals',
      type: SERVICES_TYPE.VITAL,
      consentType: 'MedicalVitals',
    },
    {
      title: 'Visits',
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/visits-web.svg',
      active: true,
      link: '/records/visits',
      type: SERVICES_TYPE.VISITS,
      consentType: 'MedicalVisits',
    },

    {
      title: 'Social history',
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/dental_inactive.svg',
      active: true,
      link: '/records/smoking_status',
      type: SERVICES_TYPE.SOCIAL_HISTORY,
      consentType: 'MedicalSocialHistory',
    },
    {
      title: 'Family history',
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/dental_inactive.svg',
      active: true,
      link: '/records/family_member_history',
      type: SERVICES_TYPE.FAMILY_MEMBER_HISTORY,
      consentType: 'DemographicsFamilyMedicalHistory',
    },
    // {
    //   title: 'Second Opinions',
    //   image: '/src/assets/images/so.jpg',
    //   icon: '/src/assets/images/chat.svg',
    //   active: true,
    //   link: '/secondopiniondashboard',
    //   type: SERVICES_TYPE.SECOND_OPINION,
    // },
  ],
  Legal: [
    {
      title: 'Consents',
      link: '/records/consents',
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/consents_inactive.svg',
      active: true,
      type: 'consents',
      consentType: 'Consent',
    },
  ],
};

export const dateFilterMenuItemsInConsents = [
  { label: 'All', value: 'all' },
  { label: 'Past 1 month', value: '1 month' },
  { label: 'Past 3 months', value: '3 months' },
  { label: 'Past 6 months', value: '6 months' },
];

export const CONSENT_STATUS_LIST = [
  'active',
  'draft',
  'proposed',
  'inactive',
  'rejected',
  'entered-in-error',
];
export const consentStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active', icon: ActiveIcon },
  { label: 'Expired', value: 'inactive', icon: RejectIcon },
  { label: 'Pending', value: 'pending', mappingList: ['PROPOSED'], icon: YellowIcon },
  { label: 'Cancelled', value: 'rejected', icon: RejectIcon },
  { label: 'Not Initiated', value: 'notinitiated', icon: GrayColorStatusIcon },
  { label: 'Unknown', value: 'entered-in-error', icon: GrayColorStatusIcon },
  { label: 'Terminated', value: 'terminated', icon: RejectIcon },
];

export const UTILITIES_TITLE = {
  AWARD_POINTS: 'Award points',
  REFUNDS: 'Refunds',
  PAYMENT_METHODS: 'Payment methods',
  PAYMENT_HISTORY: 'Payment history',
  REFER: 'Refer a friend',
  OFFERS_DISCOUNTS: 'Offers & discounts',
  TAX_FORMS: 'Tax forms',
  ACCOUNT_DETAILS: 'Account details',
  ID_CARD: 'ID card',
  PLAN_COVERAGE: 'Plan coverage',
  LOGOUT: 'Logout',
  HELP: 'Help',
  EXPORT_RECORDS: 'Export records',
  FEEDBACK: 'Ratings',
  CONTACT: 'Support',
  SUPPORT: 'Support',
  PRIVACY_POLICY: 'Privacy policy',
  TERMS_CONDITIONS: 'Terms & conditions',
  DEACTIVATE: 'Deactivate account',
  COMMUNICATION_PREFERENCE: 'Communication Preferences',
};

export const COMMUNICATION_PREFERENCE = {
  HEADING: 'Notification Preferences',
  SUB_HEADING: 'Set your notification preferences',
  HEADING1: 'Print preferences',
  SUB_HEADING1: 'Set your print preferences',
  // HEADING2: 'Communication type',
  // SUB_HEADING2:'Set your communication type',
};

export const MAX_TIME_LIMIT_FOR_RESENT_OTP = 120000;
export const UTILITY_TAB_CONTENT = {
  Wallet: [
    {
      title: UTILITIES_TITLE.AWARD_POINTS,
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/medication_inactive.svg',
      link: '/utilities/awardpoints',
      active: true,
      // isButton: true,
      buttonText: 'EARN POINTS',
    },
    {
      title: UTILITIES_TITLE.PAYMENT_METHODS,
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/medication_inactive.svg',
      link: '/payment/manage',
      active: true,
    },

    // {
    //   title: UTILITIES_TITLE.REFER,
    //   icon: '',
    //   image: '',
    //   inactiveIcon: '/src/assets/images/medication_inactive.svg',
    //   link: '/utilities/refer',
    //   active: true,
    //   // isButton: true,
    //   buttonText: '+100 POINTS',
    // },
  ],
  Account: [
    {
      title: 'Account details',
      icon: '/src/assets/images/bill-service.svg',
      image: '/src/assets/images/bill-pay.jpg',
      active: true,
      link: '/utilities/accountDetails',
    },
    {
      title: UTILITIES_TITLE.LOGOUT,
      icon: '/src/assets/images/advancedDirectives.svg',
      image: '',
      inactiveIcon: '/src/assets/images/advancedDirectives_inactive.svg',
      active: true,
      link: '/logout',
    },
    {
      title: UTILITIES_TITLE.DEACTIVATE,
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/circles-filled.svg',
      link: '/utilities/deactivate',
      active: true,
    },
  ],
  Contact: [
    {
      title: UTILITIES_TITLE.COMMUNICATION_PREFERENCE,
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/communicationPreference.png',
      link: '/utilities/communicationPreference',
      active: true,
    },
    {
      title: UTILITIES_TITLE.CONTACT,
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/circles-filled.svg',
      link: '/utilities/support',
      // link: 'https://mpoweredhealth.com/contact/',
      active: true,
    },
    {
      title: UTILITIES_TITLE.FEEDBACK,
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/circles-filled.svg',
      link: '/utilities/feedback',
      // link: 'https://mpoweredhealth.ideas.aha.io/ideas/new',
      active: true,
    },
    {
      title: UTILITIES_TITLE.HELP,
      icon: '',
      image: '',
      inactiveIcon: '/src/assets/images/circles-filled.svg',
      link: '/help',
      active: true,
    },
    // {
    //   title: UTILITIES_TITLE.PRIVACY_POLICY,
    //   icon: '',
    //   image: '',
    //   inactiveIcon: '/src/assets/images/circles-filled.svg',
    //   link: '',
    //   openModal: true,
    //   active: true,
    //   type: 'PRIVACY_POLICY',
    // },
    // {
    //   title: UTILITIES_TITLE.TERMS_CONDITIONS,
    //   icon: '',
    //   image: '',
    //   inactiveIcon: '/src/assets/images/circles-filled.svg',
    //   link: '',
    //   opemModal: true,
    //   active: true,
    //   type: 'TERMS_AND_CONDITIONS',
    // },
  ],
};

export const EMPTY_FUNCTION = () => {};

export const ROTATE_DIRECTIONS = Object.freeze({
  ANTICLOCKWISE: 'ACL',
  CLOCKWISE: 'CL',
});
export const IMAGE_EDITOR = () =>
  Object.freeze({
    BTN_TEXT_RESCAN: 'Rescan',
  });
export const FORGOT_PASS_TXT = 'Forgot password?';
export const FORGOT_PASSWORD = Object.freeze({
  BTN_TEXT_GO_TO_LOGIN: 'Go to log in',
  BTN_TEXT_RESET: 'Send reset link',
  DIDNT_RECEIVE_MAIL_TEXT: 'If you didn’t receive an email!',
  RESEND: 'Resend',
  RESET_LINK_SENT: 'Reset link sent',
  RESET_LINK_RESENT: 'Reset link resent',
});

export const SIGNUP_TERMS = Object.freeze({
  PRECEDING_TEXT: 'I have read and agree to the ',
  MID_TEXT: 'Terms & conditions',
  AND: ' and ',
  POST_TEXT: 'Privacy policy',
  TERMS_AND_CONDITIONS_LINK: 'https://mpoweredhealth.com/terms-of-use',
});

/**
 * @todo: Replace TERMS_AND_CONDITIONS and PRIVACY_POLICY with actual strings.
 * Parking placeholders for now.
 */

export const CONSENT_TYPES = {
  TERMS_CONDITION: 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
};
export const NAVIGATION_CONTENT = Object.freeze({
  MAIN_BODY: [
    {
      name: 'Your profile',
      link: '/utilities/accountDetails',
    },
    {
      name: 'Your ratings',
      link: '/ratingsdashboard',
    },
    {
      name: 'Your alerts',
      link: '/alerts',
    },
    {
      name: 'Feedback',
      link: '/utilities/feedback',
    },
  ],
  SECONDARY_BODY: [
    {
      name: 'FAQ',
      link: '/help',
    },
    {
      name: 'Support',
      link: '/utilities/support',
    },
    {
      name: 'Privacy policy',
      consentType: CONSENT_TYPES.PRIVACY_POLICY,
    },
    {
      name: 'Terms & conditions',
      consentType: CONSENT_TYPES.TERMS_CONDITION,
    },
  ],
  LOGOUT_TXT: 'Logout',
});

export const TERMS_AND_CONDITIONS = Object.freeze({
  TITLE: 'Terms & conditions',
  OUTLINE:
    'The following outlines the terms & conditions of use of the Mpowered Health application. Before using any of the Mpowered Health services, you are requested to read, understand and agree to these terms.',
  BODY: '',
  ACCEPT: 'I agree',
  OK: 'OK',
});
export const PRIVACY_POLICY = Object.freeze({
  TITLE: 'Privacy policy',
  OUTLINE:
    'The following outlines the privacy policy of Mpowered Health application. Before using any of the Mpowered Health services, you are requested to read, understand and agree to the privacy policy.',
  BODY: '',
  ACCEPT: 'I agree',
  OK: 'Ok',
});
export const FORGOT_PASS_CAPTION =
  'Please enter your phone number, we will send you a password reset link';

export const SIGN_UP = Object.freeze({
  LABEL: `Don't have an account?`,
  HEADING: 'Get Mpowered with us.',
  SIGN_UP_NOW: 'Sign up now.',
  BODY: 'Sign up',
  BTN_TEXT_APPLY: 'Apply',
  BTN_TEXT_SIGNUP: 'Sign up',
  EXISTING_USER_TEXT: 'Existing user?',
  LOGIN_TEXT: ' Log in',
  PASSWORD_STRENGTH_TEXT: 'Password strength - ',
  ERRORLOG_USER_EXIST_MSG: 'User exists with same username',
  REFERRAL_CODE_APPLIED: 'Referral code successfully applied',
  PROMO_CODE_APPLIED: 'Promo code successfully applied',
});
export const PASS_RESET_SENT_TXT = 'A password reset link has been sent to <EMAIL-ID>';

export const POLICY = Object.freeze({
  TERMS: 'Terms',
  PRIVACY: 'Privacy',
});

export const PREVIEW_SCAN_LABEL = 'Preview scan';
export const PREVIEW_SOURCE_TYPE = Object.freeze({
  PDF: 'PDF',
  IMAGE: 'IMAGE',
});
export const OK = 'OK';
export const VERIFICATION_TEXTS = Object.freeze({
  EMAIL_VERIFY_TITLE: 'Verify email',
  EMAIL_SENT_TEXT: 'Click the link in the email sent to',
  EMAIL_NO_RECEIVE_MSG: `Don’t see the email? Make sure the email address is correct. Check your spam or junk folder.`,
  RESEND_EMAIL: 'Resend email',
  EMAIL_RESENT: 'Email resent',
});
export const EMAIL_VERIFICATION_SUCCESS_TEXTS = Object.freeze({
  EMAIL_VERIFIED: 'Email verified',
  EMAIL_VERIFIED_MSG: 'Your email has been verified successfully',
  AWESOME: 'Awesome!',
  GO_TO_DASHBOARD: '  Go to dashboard',
  VERIFY_PHONE: 'Verify phone number',
});
export const EMAIL_VERIFICATION_FAILURE_TEXTS = Object.freeze({
  EMAIL_VERIFY_FAIL: `We couldn’t verify your email`,
  EMAIL_VERIFY_FAIL_REASON: 'The email verification link you clicked is invalid/expired.',
});
export const PHONE_VERIFICATION_TEXTS = Object.freeze({
  TITLE: 'Enter code',
  BODY: ' Please enter the 4 digit verification\ncode sent to ',
  NO_RECEIVE: `Didn't receive the code?`,
  OPT_SENT: 'Verification code sent',
  OPT_RESENT: 'Verification code resent',
  VERIFY: 'Verify',
  WRONG_OTP: 'Verification code doesn’t match',
  PHONE: 'your phone number',
  BTN_TEXT_RESEND: 'Resend',
  BTN_TEXT_RESEND_OTP: 'Resend OTP',
  PHONE_VERIFICATION_STATUS: {
    APPROVED: 'approved',
    PENDING: 'pending',
    EXPIRED: 'expired',
    DENIED: 'denied',
  },
});
export const PHONE_VERIFICATION_SUCCESS_TEXTS = Object.freeze({
  PHONE_VERIFIED: 'Code verified',
  PHONE_VERIFIED_MSG: 'Your phone number has been verified successfully',
  AWESOME: 'Awesome!',
  GO_TO_DASHBOARD: '  Go to dashboard',
});
export const REFERRAL_STEPS_TEXTS = Object.freeze({
  STEP_ONE: `Invite your friends to Mpowered Health by sharing your unique invitation code`,
  STEP_TWO: 'They use your invitation code to signup for an Mpowered Health account',
  STEP_THREE:
    'You can earn award points, when they successfully create an account using your invitation code',
});
export const PAYMENT_SUCCESS_TEXTS = Object.freeze({
  TITLE: 'Payment successful',
  CNF_NO: 'Confirmation number',
  GO_TO_BILL: 'Go to bill pay',
  GO_TO_SO: 'Go to second opinions',
  AWARDPOINTS_CREDITED_SHORTLY:
    'Congrats! Mpowered award points\nwill be credited in your account\nshortly!',
  PAYMENT_INFO_UPDATE: (serviceName) =>
    `It will take up to 5 minutes for this payment information to be updated in the ${serviceName} dashboard.`,
});

export const PAYMENT_PENDING_TEXTS = Object.freeze({
  TITLE: 'Payment has been initiated',
  CNF_NO: 'Confirmation number',
  GO_TO_BILL: 'Go to bill pay',
  GO_TO_SO: 'Go to second opinions',
  PAYMENT_PROCESSING_TEXT:
    'You have successfully initiated payment via ACH.\nWe will attempt to debit the bank account and it may take up to 5 business days to receive acknowledgment of success or failure. ',
});

export const RATE_APP = 'Rate service';
export const RATE_SO = 'Rate second opinion';

export const MPOW_POINTS_EARNED = 'Congrats! You earned <Points>\nMpowered award points';
export const PAYMENT_FAILURE_TEXTS = Object.freeze({
  TITLE: 'Payment unsuccessful',
  RETRY: 'Retry',
});
export const MODAL = Object.freeze({
  BTN_TITLE_ONE: 'Pay a bill to earn more',
  BTN_TITLE_TWO: 'Go to bill summary',
  SUBTEXT_ONE: 'You have been awarded with',
  SUBTEXT_TWO: 'Mpowered award points',
  GREETING: 'Hello (firstname) !',
});
export const PAYMENT_FAIL_MSG =
  'The transaction to <Payee>\nusing card ending with <Card-No> was unsuccessful';

export const CARD_ACTION_TEXTS = Object.freeze({
  LOADER_TEXT: 'Saving data',
  ADD_CARD: {
    TITLE: 'Add card',
    CONFIRMATION_DIALOG: {
      HEADING: 'Card details saved!',
      SUBHEADING: 'Your card details have been added.',
    },
  },
  EDIT_CARD: {
    TITLE: 'Edit card',
    CONFIRMATION_DIALOG: {
      HEADING: 'Card details saved!',
      SUBHEADING: 'Your card details have been updated.',
    },
  },
  CARD_SAVE_FAILED_MODAL: {
    HEADING: 'Failed to save card details!',
    SUBHEADING: 'Something went wrong while adding card details. Please try again.',
  },
  BUTTON_TEXT: 'Save',
  CARD_FORM: {
    FIRST_NAME: {
      label: 'Name on card',
      name: 'name',
    },
    ZIP_CODE: {
      label: 'Zip code',
      name: 'addressZip',
    },
    PHONE_NO: {
      label: 'Phone number',
      name: 'phoneNo',
    },
    CARD_NO: {
      label: 'Card number',
    },
    EXPIRY_DATE: {
      label: 'Expiry date',
    },
    CVC: {
      label: 'CVC',
    },
    ADDRESS_LINE_1: {
      label: 'Address line 1',
      name: 'addressLine1',
    },
    ADDRESS_LINE_2: {
      label: 'Address line 2',
      name: 'addressLine2',
    },
    COUNTRY: {
      label: 'Country',
      name: 'addressCountry',
    },
    STATE: {
      label: 'State',
      name: 'addressState',
    },
    CITY: {
      label: 'City',
      name: 'addressCity',
    },
    SAVEAS: {
      label: 'Save as',
      name: 'saveAs',
    },
  },
});

export const BANK_ACCOUNT_ACTION_TEXTS = Object.freeze({
  LOADER_TEXT: 'Saving data',
  ADD_ACCOUNT: {
    TITLE: 'Add bank account',
    CONFIRMATION_DIALOG: {
      HEADING: 'Bank account details saved!',
      SUBHEADING: 'Your bank account details have been added.',
    },
    FAIL_DIALOG: {
      HEADING: 'Failed to save bank account details!',
      SUBHEADING: 'Your bank account details have not been added.',
    },
  },
  EDIT_ACCOUNT: {
    TITLE: 'Edit bank account',
    CONFIRMATION_DIALOG: {
      HEADING: 'Bank account details saved!',
      SUBHEADING: 'Your bank account details have been updated.',
    },
    FAIL_DIALOG: {
      HEADING: 'Failed to save bank account details!',
      SUBHEADING: 'Your bank account details have not been updated.',
    },
    FAIL_FETCH_DETAILS: {
      HEADING: 'Failed to get bank account details!',
      SUBHEADING: 'Bank account with provided account id does not exist.',
    },
  },
  BUTTON_TEXT: 'Save',
  BANK_ACCOUNT_FORM: {
    NICK_NAME: {
      label: 'Save as',
      name: 'nickName',
    },
    ACCOUNT_HOLDER_NAME: {
      label: 'Account holder name',
      name: 'accountHolderName',
    },
    ROUTING_NUMBER: {
      label: 'Routing number',
      name: 'routingNumber',
    },
    ACCOUNT_HOLDER_TYPE: {
      label: 'Account holder type',
      name: 'accountHolderType',
    },
    ACCOUNT_NUMBER: {
      label: 'Account number',
      name: 'accountNumber',
    },
    ADDRESS_LINE_1: {
      label: 'Account holder address line 1',
      name: 'addressLine1',
    },
    ADDRESS_LINE_2: {
      label: 'Account holder address line 2',
      name: 'addressLine2',
    },
    STATE: {
      label: 'State',
      name: 'state',
    },
    CITY: {
      label: 'City',
      name: 'city',
    },
    ZIP_CODE: {
      label: 'Zip code',
      name: 'zip',
    },
  },
  ACCOUNT_HOLDER_OPTIONS: [
    {
      value: '',
      label: 'Select account type',
    },
    {
      value: 'individual',
      label: 'Individual',
    },
    {
      value: 'company',
      label: 'Company',
    },
  ],
  AUTHORIZATION_TEXT:
    'I authorize Mpowered Health to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.',
});

export const MANAGE_PAYMENT_TEXTS = Object.freeze({
  HEADING: 'Manage payments',
  SUBHEADING: 'Manage, add, delete your payment methods here',
  EMPTY_PAYMENT_METHOD: 'You do not have any payment options setup.',
  MAKE_DEFAULT: 'Make primary',
  DEFAULT_PAYMENT: 'Primary',
  ADD_CARD: 'Add a credit / debit card',
  ADD_BANK_ACCOUNT: 'Add a bank account',
  EXISTING_CARDS: 'Cards',
  EXISTING_BANK_ACCOUNTS: 'Bank accounts',
  EDIT: 'Edit',
  DELETE: 'Delete',
  VERIFY: 'Verify',
  MAKE_DEFAULT_CONFIRMATION_DIALOG: {
    HEADING: 'Default payment updated!',
    SUBHEADING: 'Your default payment has been updated.',
  },
  BANK_MAKE_DEFAULT_CONFIRMATION_DIALOG: {
    HEADING: 'Default bank account updated!',
    SUBHEADING: 'Your default bank account has been updated.',
  },
  DELETE_CARD_CONFIRMATION_DIALOG: {
    HEADING: 'Delete card ending ',
    SUBHEADING: 'Are you sure you want to delete this payment method?',
    CONFIRM_BUTTON_TEXT: 'Yes',
    CANCEL_BUTTON_TEXT: 'No',
  },
  DELETE_ACCOUNT_CONFIRMATION_DIALOG: {
    HEADING: 'Delete bank account ending with ',
    SUBHEADING: 'Are you sure you want to delete this payment method?',
    CONFIRM_BUTTON_TEXT: 'Yes',
    CANCEL_BUTTON_TEXT: 'No',
  },
  DELETE_CARD_ACKNOWLEDGE_DIALOG: {
    HEADING: 'Card ending (last4) deleted!',
    SUBHEADING: 'Your card has been deleted.',
  },
  DELETE_ACCOUNT_ACKNOWLEDGE_DIALOG: {
    HEADING: 'Bank account ending (last4) deleted!',
    SUBHEADING: 'Your bank account has been deleted.',
  },
  VERIFY_ACCOUNT_DIALOG: {
    HEADING: 'Verify account',
    VERIFY_BUTTON_TEXT: 'Verify',
    CANCEL_BUTTON_TEXT: 'Cancel',
    TEXT_FIELD_AMOUNT_1: 'First amount (in cents)',
    TEXT_FIELD_AMOUNT_2: 'Second amount (in cents)',
  },
  ACCOUNT_VERIFIED_ACK_DIALOG: {
    HEADING: 'Account verified!',
    SUBHEADING: 'Your bank account was verified successfully.',
  },
  ACCOUNT_VERIFICATION_FAILED: {
    HEADING: 'Account verification failed. Please try again.',
    SUBHEADING: 'The amounts provided do not match the amounts that were sent to the bank account.',
    RE_VERIFY: 'Verify Again',
    CANCEL: 'Cancel',
  },
});

export const PAYMENT_METHODS_TYPES = {
  CREDIT_CARD: 'credit-card',
  BANK_ACCOUNT: 'bank-account',
};

export const BANK_ACCOUNT_STATUS = {
  VERIFIED: 'verified',
  UNVERIFIED: 'unverified',
};

export const WHAT_IS_CVC = Object.freeze({
  HEADING: 'What is CVC?',
  SUBHEADING:
    'The CVC (Card Verification Code) is a 3 digit number printed on your card. It is the last 3 digits of the number that appears on the back of your card ',
  HELPER_IMAGE: 'what_is_cvc.png',
});

export const regex = {
  verifyEmail: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-])+\.([A-Za-z]{2,4})$/i,
  verifyNumber: /^\d{10}$/,
  verifyUSFormatNumber: /^\((\d{3})\) (\d{3}) (\d{4})$/,
  passwordUpper: /[A-Z]/,
  passowrdLower: /[a-z]/,
  passwordNumber: /[0-9]/,
  passwordSpecialChar: /[^A-Za-z0-9]/,
  verifyName: /^\d/,
  verifyUSZip: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  ZipCodeChar: /^(\d|[-])*$/,
  allDigits: /[0-9]/,
  routingNumber: /^[0-9]{9}$/,
  accountNumber: /^[0-9]{3,17}$/,
  numberAtmost2Decimal: /^\d+(\.)?(\d{1,2})?$/,
  verifyFirstName: /^[a-z .']+$/i,
  verifyLastName: /^[a-z .']+$/i,
  verifyFullName: /^[a-zA-Z .']+$/,
  verifyPreferredName: /^[a-zA-Z .']*$/,
  verifyDOB: /^\d{2}\/\d{2}\/\d{4}$/,
};
export const SECOND_OPINION_TITLE = 'Second opinions';
export const SECOND_OPINION_BTN_TEXT_UPLOAD = 'Upload a Scan';
export const SECOND_OPINION_BTN_TEXT_BROWSE = 'Browse our Radiologists';

export const NOT_CONSULTED_ANYONE = 'You haven’t consulted any one yet';
export const GET_ASSURED_SECOND_OPINION_LINE =
  'Get reassured in health. Browse through our US Trained & Board certified radiologists';
export const PAYMENT_PROGRESS_TEXT = 'Payment in progress';
export const EDIT_PROFILE = {
  TITLE: 'Edit account',
  SUBTEXT: 'Edit your account details here',
  PROVIDE_OLD_PASSWORD_TEXT: 'For added security, please enter your current password',
  BTN_TEXT_SAVE_CHANGES: 'Save',
  LOADER_TEXT: 'Updating data',
};

export const FORM_LABEL = Object.freeze({
  EMAIL: 'Email id',
  PHONE: 'Phone number',
  MOBILE: 'Mobile number',
  USER_ID: 'User ID',
  FIRST_NAME: 'First name',
  MIDDLE_NAME: 'Middle name',
  LAST_NAME: 'Last name',
  PREFERRED_LANGUAGE: 'Preferred language',
  FULL_NAME: 'Full name',
  DOB: 'DOB',
  PREFERRED_NAME: 'Preferred name',
  PASSWORD: 'Password',
  RE_ENTER_PASSWORD: 'Re-enter password',
  OLD_PASSWORD: 'Old password',
  NEW_PASSWORD: 'New password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  CONFIRM_PASS: 'Confirm password',
  REFERRAL_CODE: 'Referral/Promo code',
  DATE: 'Date',
  GENDER: 'Gender',
  STATE: 'State',
  COUNTRY: 'Country',
  ZIP_CODE: CARD_ACTION_TEXTS.CARD_FORM.ZIP_CODE.label,
  PATIENT_ID: 'Patient ID',
  PAYMENT_AMOUNT: 'Payment amount',
  PAYMENT_TO: 'Make payment to',
  CITY: 'City',
  CVC: '123',
  SAVE_AS: CARD_ACTION_TEXTS.CARD_FORM.SAVEAS.label,
  ADDRESS_LINE_1: CARD_ACTION_TEXTS.CARD_FORM.ADDRESS_LINE_1.label,
  ADDRESS_LINE_2: CARD_ACTION_TEXTS.CARD_FORM.ADDRESS_LINE_2.label,
  EXPIRY_DATE: 'Expiry date',
  CARD_NO: 'Card number',
  PAYER_NAME: 'Insurer name',
  MEMBER_ID: 'Member ID',
  GROUP_ID: 'Group ID',
  PlAN_TYPE: 'Plan type',
  RELATIONSHIP: 'Relationship to subscriber',
  MONTH: 'm',
  YEAR: 'Year',
  SECURITYmCODE: 'Security code',
  POLICY_HOLDER_FIRST_NAME: 'First name of policy holder',
  POLICY_HOLDER_LAST_NAME: 'Last name of policy holder',
});

export const entries = [
  {
    value: 'Texas',
    label: 'Texas',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Alabama',
    label: 'Alabama',
  },
];

export const MAKE_PAYMENT_TO = Object.freeze({
  HEADING: 'Make payment to',
  HOSPITAL: 'El Camino Hospital',
  BILL: '$500',
  SUBHEADING: 'Choose from existing payment options',
  PAY: 'Pay',
});

export const CHOOSE_DOCTORS = Object.freeze({
  HEADING: 'Choose your doctor',
  SUBHEADING: 'Browse through our recommended doctors in Texas',
  PICKER_LABEL_STATE: 'State',
  PICKER_LABEL_SCAN_TYPE: 'ScanType',
  PICKER_LABEL_BODY_PART: 'BodyPart',
  CLEAR_FILTER: 'clear filters',
  BTN_TEXT_SAVE_LATER: 'Save for later',
  BTN_TEXT_NEXT: 'Next',
  STATES: [
    {
      value: 'alabama',
      label: 'alabama',
    },
    {
      value: 'texas',
      label: 'texas',
    },
    {
      value: 'colarado',
      label: 'colarado',
    },
    {
      value: 'houstan',
      label: 'houstan',
    },
  ],
  SCAN_TYPE: [
    {
      value: 'CT',
      label: 'CT',
    },
    {
      value: 'OPG',
      label: 'OPG',
    },
    {
      value: 'L-scan',
      label: 'L-scan',
    },
  ],
  BODY_PARTS: [
    {
      value: 'Head',
      label: 'Head',
    },
    {
      value: 'Chest',
      label: 'Chest',
    },
  ],
});

export const SAVE_CONFIRMATION = Object.freeze({
  HEADING: 'Save for later',
  SUBTEXT_LIST: [
    'Your draft has been saved for later in pending section',
    'You can come back any time to complete the process',
  ],
  BTN_TEXT_NEXT: 'Next',
});

export const TERMS = Object.freeze({
  HEADING: 'Terms and conditions',
  SUBTEXT_LIST: ['By tapping yes you accept Terms and Conditions of DocPanel'],
});

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const SECOND_OPINION_SHARE_REASON = Object.freeze({
  HEADING: 'Share reason',
  SUBHEADING: 'Tell us why you need a second opinion',
  PLACEHOLDER:
    'E.g. I need second opinion because my knee pain isn’t alleviating with current dosage',
  BTN_TEXT_SAVE_LATER: 'Save for later',
  BTN_TEXT_NEXT: 'Next',
  CHARACTERS_LEFT: '(char-left) characters',
});
export const HOW_IT_WORKS_DIALOG = Object.freeze({
  HEADING: 'How it works?',
  LIST: [
    'Upload a scan',
    'Upload a doctor’s report (optional)',
    'Provide patient information',
    'Review uploaded documents',
    'Share why you need a second opinion',
    'Pick your doctor ',
    'Pay',
  ],
});

export const STATES = [
  {
    value: 'Texas',
    label: 'Texas',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Alabama',
    label: 'Alabama',
  },
];
export const GENDERS = [
  {
    value: 'MALE',
    label: 'Male',
  },
  {
    value: 'FEMALE',
    label: 'Female',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
  {
    value: 'UNKNOWN',
    label: 'Unknown',
  },
  {
    value: '',
    label: '-',
  },
];

export const VIEW_PROFILE = Object.freeze({
  HEADING: 'View profile',
  BTN_TEXT_SAVE_LATER: 'Save for later',
  BTN_TEXT_NEXT: 'Next',
});

export const NO_BILL_STATE = Object.freeze({
  PAST_DUE_BILLS: 'Hooray! You have no past due bills to pay',
  DUE_BILLS: 'Hooray! You have no bills due',
  PAID_BILLS: 'You have not paid any bills yet',
});

export const RATING_SUCCESS = Object.freeze({
  RATE_TITLE: 'Rate your experience',
  RATE_SUBTITLE: 'Thank you for your feedback!',
  RATE_ENDTITLE: 'It will help us improve your experience',
  RATE_SUBMIT: 'Go to Your ratings',
});
export const SENT_SCAN = Object.freeze({
  HEADING: 'Scans sent',
  BTN_TEXT_GO_TO: 'Go to second opinion',
  SUBHEADING: 'You can expect to know the diagnosis by\nJune 10, 2019.',
});
export const GIFT_CARD = Object.freeze({
  TITLE: 'Redeemed',
  AWARD_POINT_REDEEMED: 'You have redeemed <Points> points',
  AVAILED_GIFT_CARD: 'You have availed this gift card',
  BTN_TEXT_REDEEM_ANOTHER: 'Redeem more points',
  BTN_TEXT_GO_TO: 'Go to award points dashboard',
  REDEEM_ERROR_MSG_PREFIX: 'You are trying to redeem',
  REDEEMED_ERROR_MSG_POSTFIX_MORE_THAN_LIMIT: 'points which is more than limit',
  REDEEMED_ERROR_MSG_POSTFIX_LESS_THAN_LIMIT: 'points which is less than minimum limit',
  LOADER_TEXT: 'Redeeming',
  PLACEHOLDER: 'Select a value',
  BTN_TEXT_REDEEM: 'Redeem',
  ENTER_AMOUNT_LABEL_PREFIX: 'Enter amount (between',
  SELECT_AMOUNT_LABEL_PREFIX: 'Select amount (between',
  AMOUNT_LESS_ERROR: ({ min = 20, max = 0 }) => `Please enter the amount between $${min}-$${max}`,
  AMOUNT_EXCEEDS_ERROR: `You do not have enough award points`,
  REWARD_DES: 'Reward description',
  MORE_INFO: 'More Information about the gift card',
  GENERAL_DISCLAIMER:
    'The merchants represented are not sponsors of rewards or otherwise affiliated with Mpowered Health. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates. Please visit each company’s website for additional terms and conditions ',
});

export const GIFT_CARD_DETAILS = Object.freeze({
  HEADING: 'Redeem award points ',
  SUB_HEADING: 'Available award points',
  REDEEM_TITLE: 'Award points to redeem',
  DROPDOWN_PLACEHOLDER: 'Select a gift card',
  MIN_REQUIRED: 'Min value needs to be',
});

export const AWARD_POINTS = Object.freeze({
  HEADING: 'Award points',
  SUB_HEADING: 'My award points history',
});
export const SECOND_OPINION_CHAT = Object.freeze({
  SUBHEADING: 'Please expect the earliest response within 48 hours ',
  PLACEHOLDER: 'Enter text',
  SEND_TEXT: 'Send',
});
export const itemList = [
  {
    icon: PartnerIcon,
    content: 'Organization',
    subContent: 'Select an organization and add to your circle',
    category: 'partner',
    toolTipText:
      'Organizations are health plans, hospitals or other healthcare organizations that you currently have or have had a relationship with in the past. You will need your user ID & password for these organizations to establish the connection.',
  },
  {
    icon: IndividualIcon,
    content: 'Individual',
    subContent: 'Add an individual to your circle',
    category: 'individual',
    toolTipText:
      'Individual is someone you know who is either involved in your care or for whom you provide care. Do not establish connections with people you do not know. ',
  },
  // {
  //   icon: CareGiverIcon,
  //   content: 'Care giver',
  //   category: 'careGiver',
  // },
];
export const AWARD_POINT_PAY = Object.freeze({
  HEADING: 'Make payment',
  AVAILABLE_AMOUNT: 'Available amount : <Amount/Points>',
  TEXT_AWARDPOINT: 'Award points',
  TEXT_FIELD_LABEL: 'Enter amount to redeem',
  TEXT_REDEEMED: 'Award points redeemed: ',
  LABEL_CHOOSE_CARD: 'Select a card',
  LABEL_CHOOSE_BANK_ACCOUNT: 'Select a bank account',
  TEXT_PAYMENT_METHOD: 'Payment methods',
  BTN_TEXT_ADD_METHOD: 'Add a payment method',
  BTN_TEXT_PAY: 'Pay',
  MIN_AWARADPOINT_ERROR: 'invalid amount',
  MAX_AWARDPOINT_ERROR: 'maximum amount redeem can be',
  // MIN_AWARDPOINT_REQUIRED_PREFIX: 'Amount to use (minimum',
  // MIN_AWARDPOINT_REQUIRED_POSTFIX: 'points required)',
  PAYMENT_IN_PROGRESS: 'Payment in progress',
  AMOUNT_PAY_VIA_CARD: 'Amount due',
  TOTAL_AMOUNT: 'Total amount due',
  CARD_TYPE: [
    {
      label: 'Use existing card ',
      value: 'existingCard',
    },
    {
      label: 'Use a different card',
      value: 'newcard',
    },
    {
      label: 'Use existing bank account',
      value: 'existingBankAccount',
    },
  ],
});

export const TAB_LABEL = ['Pending', 'Requested', 'Reviewed'];

export const HEADER_MENU_LIST = [
  {
    icon: '/src/assets/images/Profile.svg',
    name: 'Account details',
    link: '/utilities/accountDetails',
  },
  {
    icon: '/src/assets/images/contact.svg',
    name: 'Feedback',
    link: 'https://mpoweredhealth.ideas.aha.io/ideas/new',
  },
  {
    icon: '/src/assets/images/signing-the-contract.svg',
    name: 'Contact us',
    link: 'https://mpoweredhealth.com/contact/',
  },
  {
    icon: '/src/assets/images/notepad-orange.svg',
    name: 'Privacy policy',
    consentType: CONSENT_TYPES.PRIVACY_POLICY,
  },
  {
    icon: '/src/assets/images/signing-the-contract.svg',
    name: 'Terms & conditions',
    consentType: CONSENT_TYPES.TERMS_CONDITION,
  },
  {
    icon: '/src/assets/images/sign-out.svg',
    name: 'Log out',
    link: '/logout',
    alt: 'logout',
  },
];

export const PWA_HEADER_ICON = [
  {
    icon: '/src/assets/images/awardPointsInHeader.svg',
    name: 'Award Points',
    link: '/utilities/awardpoints',
    alt: 'awardpoints',
  },
  {
    icon: '/src/assets/images/notifications.svg',
    name: 'Your alerts',
    link: '/alerts',
    alt: 'alerts',
  },
];

export const GIFT_CARD_VALUE_TYPE = Object.freeze({
  VARIABLE_VALUE: 'VARIABLE_VALUE',
  FIXED_VALUE: 'FIXED_VALUE',
});

export const PENDING_CASE_STATES = {
  RESUME_CASE: 'Resume case',
  MAKE_PAYMENT: 'Make payment',
  PENDING_PAYMENT: 'Payment processing',
};

export const CASE_STATES = {
  Completed: 'completed',
  Active: 'active',
  OnHold: 'on-hold',
  Draft: 'draft',
};

export const THUMBNAIL_URL = 'Thumbnail';

export const YOUR_ALERTS = 'Your alerts';

export const LOINC_CODES = Object.freeze({
  IMPRESSION_CODE: '19005-8',
  FINDING_CODE: '18782-3',
  PROCEDURE_CODE: '55114-3',
  COMPARISON_CODE: '18834-2',
});

export const REVIEWED = ' reviewed your scans.';

export const IDLE_TIME = parseInt(process.env.IDLE_TIME);

export const CHECK_SESSION_TIME = parseInt(process.env.CHECK_SESSION_TIME);

export const PARTNERS = {
  TITLE: 'Partners in your care',
  ADD: 'Add to my circles',
  VIEW_MORE: 'View more',
  OPTIONS: ['Delete'],
  CANCEL: 'Cancel',
  REMOVE: 'Remove',
  REMOVE_PARTNER: (name) => `Remove ${name}`,
  REMOVE_PARTNER_MESSAGE: (name) => `Are you sure you want to remove ${name} from My partner?`,
  REMOVE_PARTNER_LOADING: (name) => `Removing ${name} from My Partner`,
  REMOVE_PARTNER_SUCCESS: (name) => `${name} has been removed from My Partner`,
  REMOVE_PARTNER_ERROR: (name) => `$Unable to remove ${name}!`,
  FETCH_CIRCLES: 'Fetching circles',
};
export const ADD_PARTNER = {
  HEADING: 'You are not part of any circles yet',
  SUBHEADING: 'Start by adding an organization',
  ADD_TO_CIRCLE_BTN_TEXT: 'Add to circle',
  TITLE: 'Add an organization',
  BUTTON: 'Proceed',
  ADD_PARTNER: 'Add an organization',
  SELECT_PARTNER: 'Select organization',
  SEARCH_PARTNER: 'Search organization',
  PARTNER_PICKER: 'PartnerPicker',
  POPULAR_PARTNERS: 'Popular organizations',
  MORE_PARTNER: 'Loading more organizations...',
  SEEN_ALL: 'Yay! You have seen it all',
  PARTNER_PICKER_ENTERIES: [
    {
      value: 'medicare',
      label: 'My Medicare',
    },
    {
      value: 'ahk',
      label: 'Apple Health',
    },
    {
      value: 'bcbsm',
      label: 'BCBS of Minnesota',
    },
  ],
  REDIRECTING: 'Redirecting to organization website...',
  CMS_LOGIN: {
    HEADING: '"Demo Mpowered Health" Wants to Use "cms.gov" to sign in',
    SUBHEADING: 'This allows the app and website to share information about you.',
    CONTINUE: 'Continue',
    CANCEL: 'Cancel',
  },
  MEDICARE: 'My Medicare',
  ADDING_PARTNER: 'Adding organization...',
  ADD_PARTNER_SUCCESS: (partner = 'Partner') => `${partner} has been added as your organization`,
  ADD_PARTNER_ERROR: 'Failed to add organization, please try again!',
  CIRCLES_FETCH_ERROR: 'Unable to fetch circles data!',
  ADD_PARTNER_CONNECTION_PROBLEM:
    'Something went wrong! Please delete and add the organization again',
  ADD_DEMOGRAPHICS_ERROR: 'Something went wrong when trying to update profile details',
  ABORT: 'Adding partner aborted',
};

export const CIRCLES_PAGE = {
  TAB_HEADERS: [
    {
      header: 'Organizations',
      index: 0,
    },
    {
      header: 'Individuals',
      index: 1,
    },
  ],
};

export const CONFIRMATION_MODAL = {
  BUTTON_NO: 'No',
  BUTTON_YES: 'Yes',
  HEADING: 'Are you sure you want to exit ?',
  HEADING_REQUEST_CONSENT: 'Are you sure you want to request the consent ?',
};

export const AWARD_POINTS_HISTORY_MESSAGE = Object.freeze({
  REDEEMED: 'Redeemed',
  EARNED: 'You earned',
});

export const RATING_SUCCESS_POPUP = Object.freeze({
  IMAGE: 'approved.svg',
  MESSAGES: ['Thank you for submitting your feedback!', 'It will help us improve your experience!'],
});

export const RATING_DASHBOARD = Object.freeze({
  HEADING: 'Your ratings',
  RATED_EMPTY_MESSAGE: 'You haven’t rated any service yet',
  RATED_EMPTY_BUTTON: 'Rate a service',
  UNRATED_EMPTY_MESSAGE: 'You have no pending ratings ',
  UNRATED_EMPTY_BUTTON: 'Explore services',
});

export const RATING_TABS = ['Pending', 'Past'];

export const SWITCH_TO_WEB_DIALOG = Object.freeze({
  HEADING: 'Switch to a web experience',
  SUBHEADING:
    'Please login to your account from a PC or a Mac for a better experience of this service.',
  OK: 'OK',
});
export const USER_ACCOUNT = Object.freeze({
  HEADING: 'Your account',
  EMAIL: 'Email ID:',
  FNAME: 'First name:',
  LNAME: 'Last name:',
  USERID: 'User ID:',
  PASSWORD: 'Password:',
  SAVECHANGES: 'Your changes have been saved!',
  BTN_TEXT_EDIT: 'Edit account',
});
export const ALERTS = Object.freeze({
  HEADING: 'Your alerts',
  EMPTY_STATE_MSG: 'You don’t have any alerts.',
  EMPTY_BUTTON_TITLE: 'Explore records',
});

export const BILL_DETAILS = Object.freeze({
  HEADING: 'Bill detail',
  PATIENT_NAME: 'Patient',
  PATIENT_ID: 'Patient ID',
  PROVIDER_NAME: 'Provider name / Physician name',
  SERVICE_DATE: 'Service date',
  PAID_VIA: (paymentType = PAYMENT_TYPE.card, last4 = 'xxxx') =>
    `Paid via ${paymentType} (xxxx - ${last4})`,
  PAID_ON: 'Paid on',
  PAID_VIA_AWARD_POINTS: 'Paid via award points',
  TOTAL_AMOUNT: 'Total amount',
  BTN_TEXT_PREFIX: 'Go to',
  BTN_TEXT_POSTFIX: 'Case',
  PAY_NOW: 'Pay now',
});

/**
 * @TODO update urls
 */
export const BILL_DETAILS_LINK = Object.freeze({
  second_opinion: {
    link: '/secondopiniondashboard',
    name: 'Second Opinions',
  },
  bill_pay: {
    link: '/bill',
    name: 'Bill Pay',
  },
  none: {
    link: '/bill',
    name: 'Bill Pay',
  },
});

export const CARD_ACTION_TYPE = {
  ADD: 'add_card',
  EDIT: 'edit_card',
};

export const LANDING_PAGE = Object.freeze({
  HEADING: "It's time to take control of your healthcare!",
  // SUBHEADING: 'Please log in or sign up to continue',
  BTN_TEXT_LOGIN: 'Log in',
  BTN_TEXT_SIGNUP: 'Sign up',
});
export const Home_DASHBOARD = Object.freeze({
  HEADING: 'Recently viewed records',
  CARD_HEADING: 'Let’s get started',
  RECOMMANDED: 'Recommended',
  YOUR_ALERTS: 'Your alerts',
  GREETING: 'Hi (firstname)',
  ACTION_CARD: [
    {
      TITLE: 'Compare and shop for health, vision and dental plans',
      BTN_TXT: 'Shop now',
    },
    {
      TITLE: 'Get maximum discount on health deals with award points',
      BTN_TXT: 'Award points',
    },
    {
      TITLE: 'Be assured of your healthcare decisions ',
      BTN_TXT: 'Get second opinion',
    },
    {
      TITLE: 'Invite friends and earn reward points',
      BTN_TXT: 'Refer a friend',
    },
    {
      TITLE: 'Get my medical records from my health plans and hospitals',
      BTN_TXT: 'Access my records',
    },
    {
      TITLE: 'Securely share your health records',
      BTN_TXT: 'Share records',
    },
  ],
});
export const REFERRAL_SCREEN = Object.freeze({
  HEADING: 'Refer a friend',
  HOW_IT_WORKS: 'How it works',
  TOATAL_REFERRAL: 'Total referrals',
  NO_REFERRAL_TEXT: 'No referrals yet,start referring your friends!',
  CARD_TEXT: 'Your referral code',
  BTN_TEXT_SHARE: 'Share link',
  MORE_INFO_TEXT: 'More information & FAQ',
});

export const RATE_AFTER_SERVICE = Object.freeze({
  HEADING: 'Rate your experience',
  BUTTON_TEXT: 'Submit',
  LOADER_TEXT: 'Updating ratings',
});

export const ERROR_SCREEN = Object.freeze({
  HEADING: 'Sorry! Page not found.',
  SUBHEADING:
    'We have not found what you have been looking for.\nTry checking for misspelled words or the page has\nbeen removed.',
  GO_TO_HOME: 'Go to home',
});

export const SUGGESTION = Object.freeze({
  BTN_TEXT_SUBMIT: 'Submit',
  GOT_ANY_SUGGESTION: 'Got any suggestions? Let us know',
});
export const AWARD_POINT_CARD = Object.freeze({
  BALANCE_TEXT: 'Balance',
  BTN_TEXT_REDEEM: 'Redeem points',
});
export const AWARD_POINT_MODAL = Object.freeze({
  SUBTITLE_ONE: 'You have been awarded with',
  SUBTITLE_TWO: 'Mpowered award points',
});
export const STRIPE_INJECTED_FORM = Object.freeze({
  BTN_TEXT_PAY: 'Pay',
});
export const BILL_DUE_SUMMARY = Object.freeze({
  BTN_TEXT_UPLOAD: 'Upload a bill',
  NEW_USER_SUBTITLE: 'Pay a bill to get started',
  PICKER_ENTERIES: [
    {
      value: 'Oldest',
      label: 'Oldest',
    },
    {
      value: 'Newest',
      label: 'Newest',
    },
  ],
  TAB_LABEL: ['Due', 'Paid'],
});

export const SHOP = Object.freeze({
  HEADING: 'Shop',
  BILL_CARD_TITLE: ['Find care', 'Buy coverage'],
  EXPENDITURES: 'Expenditure',
  CARD: [
    {
      COLOR: '#E2F1FC',
      DESCRIPTION: 'Find doctors and view price list',
      HEADING: 'Find care',
      URL: '/shop/findCare',
      IMAGE: FindCareImage,
      code: 'findCare',
    },
  ],
});

export const BILL_SUMMARY = Object.freeze({
  HEADING: 'Bills',
  BILL_CARD_TITLE: ['Total bills due', 'Your expenses'],
  LOADING: 'Loading bills',
  LOADING_DETAILS: 'Loading bill details',
  SUMMARY: 'Bill summary',
  DUE: 'Total bills due',
  EXPENDITURE: 'Your expenditure',
  EXPENDITURES: 'Expenditure',
});

export const CIRCLE_DASHBOARD = Object.freeze({
  HEADING: 'My circles',
  NEW_USER_SUBTITLE_ONE: `You aren't a part of any circles yet`,
  NEW_USER_SUBTITLE_TWO: 'Start by adding a circle',
  BTN_TEXT_ADD_CIRCLE: 'Add a circle',
  SUBTITLE: 'People whose care you are part of',
});

export const PATIENT_INFO = Object.freeze({
  HEADING: 'Patient information',
  BTN_TEXT_SAVE_LATER: 'Save for later',
  BTN_TEXT_NEXT: 'Next',
});

export const EXPENDITURE_SUMMARY = Object.freeze({
  NEW_USER_SUBTITLE: 'You have no expenditures yet.\nUse a service or upload a bill to pay',
  BTN_TEXT_PAY_BILL: 'Pay a bill',
  BTN_TEXT_EXPLORE: 'Explore services',
  SUBHEADING: 'Here is a helpful breakdown',
  EXPENDITURES: ['Service', 'Emergency', 'Medications'],
});
export const RATING = Object.freeze({
  BTN_TEXT_SUBMIT: 'Submit',
});
export const LOGIN = Object.freeze({
  WELCOME_HEADING: 'Welcome back!',
  SUBHEADING: 'Please log in to continue',
  LABEL: {
    email: 'Email id or phone number',
    password: 'Password',
  },
  BTN_TEXT_LOGIN: 'Log in',
  DONT_HAVE_TEXT: 'Don’t have an account?',
  SIGN_UP_TEXT: 'Sign up',
});

export const SECOND_OPINION = Object.freeze({
  REVIEWED_ON: 'Reviewed on \n',
  REQUESTED_ON: 'Requested on \n',
  CREATED_ON: 'Created on',
  CASE_ID: 'Case Id: ',
});

export const REVIEWED_CASE = Object.freeze({
  REVIEWED_ON: 'Reviewed on ',
  PRACTITIONER: 'Radiologist:',
  FINDING: 'Finding:',
  IMPRESSION: 'Impression:',
  SEE_MORE_TEXT: 'See more',
  SEE_LESS: 'See less',
  CONTACT: 'Contact:',
  HEADING: 'Case detail',
});

export const PROFILE = Object.freeze({
  ADD_HEADING: 'Add profile',
  YOUR_PROFILE_HEADING: 'Your profile',
  GENDER: 'Gender',
  DOB: 'DOB',
  BLOOD_GROUP: 'Blood group',
  AUTHENTICATE: 'Authenticate',
  AUTHENTICATE_SUBEHEADING: 'Choose one from the below to get your 4 digit code.',
  GET_CODE: 'Get your 4 digit verification code',
  ENTER_CODE: 'Enter code',
  ENTER_CODE_SUBHEADING: 'Please enter the 4 digit code sent to\n<PHONE/EMAIL>',
  VERIFY_NOW: 'Verify',
  SEND_CODE: 'Send code',
  VERIFIED: 'Account verified successfully',
  OK: 'OK',
  SAVE_CHANGES_BTN_TEXT: 'Save',
  SAVE_BTN_TEXT: 'Save',
  INITIAL_LOADING: 'Please wait while we load your profile',
  UPDATING_DATA: 'Saving data',
  ALERT_TITLE: 'Profile details saved!',
  ALERT_BODY: 'Your changes have been saved.',
  AVATAR_FILE_SIZE: 10,
  AVATAR_UPLOAD: 'Add profile image',
  AVATAR_UPLOAD_SUBHEADING: `Required dimensions 400*400,\nfile size shouldn\'t be more than 5 MB.`,
  AVATAR_UPLOAD_BUTTON: 'Upload image',
  AVATAR_ACTION: 'Choose an action',
  AVATAR_ADDED: 'Profile image added',
  AVATAR_ERROR: 'Failed to upload profile image.',
  AVATAR_ADDED_SUBHEADING: 'Your profile image has been saved.',
  AVATAR_SIZE_LARGE: `Please choose a file less than 5 MB`,
  AVATAR_UPLOAD_FAILED: 'Failed to upload profile avatar, Please try again.',
  AVATAR_FILE_NOTFOUND: 'Unable to fetch file, Please try again.',
  CAMERA: 'Camera',
  FILES: 'Files',
  NO_RECEIVE: `Didn't receive the code? `,
  RESEND: 'Resend',
});

export const PROFILE_CARD = Object.freeze({
  CONTACT_HEADING: 'Contact details',
  PLAN_HEADING: 'Plan coverage',
  PASSWORD_HEADING: 'Password',
});

const CONTACT = Object.freeze({
  title: 'Contact details',
  description: 'Review, edit contact details',
  link: '/contact',
});

const PLAN = Object.freeze({
  title: 'Plan coverage',
  description: 'Review, edit plan coverage',
  link: '/coverage',
});

const PASSWORD = Object.freeze({
  title: 'Password',
  description: 'Edit password details',
  link: '/changepassword',
});

export const PROFILE_CARD_ARRAY = [CONTACT, PLAN];

export const CHANGE_PASSWORD = Object.freeze({
  HEADING: 'Change password',
  TITLE: 'Enter your new password',
  OLD_PASSWORD_TEXT: 'For added security please provide your password',
  PASSWORD_REQUIREMENTS:
    'Your password should be 8 characters long including\nat least one number, one capital and one special character',
  SAVE_CHANGES: 'Save',
  OLD_PASSWORD_ERROR: 'Password cannot be empty',
  SAME_PASSWORD_ERROR: 'You have entered same password',
  CONFIRM_PASSWORD_ERROR: "The passwords don't match",
  PASSWORD_SAVED: 'Password saved!',
  NEW_PASSWORD_SAVED: 'Your new password has been saved',
});

export const COVERAGE_PLAN = Object.freeze({
  ADD_HEADING: 'Add plan',
  EDIT_HEADING: 'Edit plan',
  SAVE: 'Save',
  PLAN_COVERAGE: 'Plan coverage',
  PRIMARY: 'Primary',
  DEACTIVATE: 'Deactivate',
  MAKE_PRIMARY: 'Make primary',
  ACTIVATE: 'Activate',
  DEACTIVATE_HEADING: 'Deactivate <name>',
  DEACTIVATE_MESSAGE: 'Are you sure you want to deactivate this plan?',
  DIALOG_HEADING: 'Plan details saved!',
  ADD_DIALOG_SUBHEADING: '<Plan> has been added!',
  EDIT_DIALOG_SUBHEADING: 'Changes made to <Plan> are saved',
});
export const MAKE_PAYMENT = Object.freeze({
  HEADING: 'Make payment to',
  BTN_TEXT_PAY_NOW: 'Pay now',
  BTN_TEXT_PAY_LATER: 'Pay later',
});

export const PAYEE_INFO = Object.freeze({
  HEADING: 'Verify payee information',
  BTN_TEXT_NEXT: 'Next',
});

export const PAYMENT_FORM = Object.freeze({
  HEADING: 'Pay',
  BTN_TEXT_ADD_NEW: 'Add new card',
  BTN_TEXT_PAY: 'Pay',
  PAYMENT_IN_PROGRESS: 'Payment in progress',
  TOTAL_AMOUNT: 'Total amount due',
});

export const RESET_PASS = Object.freeze({
  HEADING: 'Reset password',
  BTN_TEXT_RESET: 'Reset password',
  RESET_SUCCESS_TEXT: 'Password reset successfully',
  BTN_TEXT_LOGIN: 'Log in',
  LINK_EXPIRED: 'Reset password link expired.Please regenerate',
  PASSWORD_STRENGTH_TEXT: SIGN_UP.PASSWORD_STRENGTH_TEXT,
  RESET_CODE_SENT: 'Reset code sent',
});
export const VIEW_SCANS = Object.freeze({
  HEADING: 'Review',
  UPLOAD_SCAN_TEXT: 'Upload another scan',
  BTN_TEXT_SAVE_LATER: 'Save for later',
  BTN_TEXT_NEXT: 'Next',
  UPLOAD_ANOTHER_REPORT: 'Upload another report',
});

export const ONBOARDING = Object.freeze({
  CARDS: [
    {
      HEADING: 'Congratulations! You chose to get Mpowered!',
      SUBHEADING: 'Let us show you some quick tips to get started',
      image: '/src/assets/images/csr.svg',
    },
    {
      HEADING: 'Manage health information',
      SUBHEADING:
        'Fetch health records from Medicare & Apple Health and consolidate them in my account',
      image: '/src/assets/images/budgeting.svg',
    },
    // {
    //   HEADING: 'Be assured of your healthcare decisions',
    //   SUBHEADING:
    //     'Get a second opinion from a radiologist with the right expertise quickly and conveniently.',
    //   image: '/src/assets/images/introduction.svg',
    // },
    {
      HEADING: 'Reward myself for getting Mpowered!',
      SUBHEADING:
        'Earn award points for referrals and more.\nRedeem points for gift cards, or to pay my bills.',
      image: '/src/assets/images/badge.svg',
    },
    // {
    //   HEADING: 'Give feedback',
    //   SUBHEADING:
    //     'Rate services you use and share feedback\nto help better the consumer health care experience for all.',
    //   image: '/src/assets/images/get-feedback.svg',
    // },
    {
      HEADING: 'Manage my profile',
      SUBHEADING:
        'Upload information for safe and secure access. Control who sees what information from my records.',
      image: '/src/assets/images/user-settings.svg',
    },
  ],
  BTN_TEXT_SHOW: 'Show me',
  BTN_TEXT_GET_STARTED: 'Get started',
  BTN_TEXT_SKIP: 'Skip',
});

export const SHARE_LINK_DRAWER = [
  {
    title: 'Share',
    text: 'Close',
  },
  {
    title: 'Copy link',
    image: '/src/assets/images/unlink.svg',
  },
  // {
  //     title: 'WhatsApp',
  //     image: '/src/assets/images/whatsapp.svg'
  // },
  {
    title: 'Facebook',
    image: '/src/assets/images/facebook.svg',
  },
  {
    title: 'Mail',
    image: '/src/assets/images/gmail.svg',
  },
  // {
  //     title: 'Messaging',
  //     image: '/src/assets/images/mail.svg'
  // },
  // {
  //     title: 'Hangouts',
  //     image: '/src/assets/images/social-media.svg'
  // }
];

export const ALERT_LINK = Object.freeze({
  second_opinion: {
    link: '/secondopiniondashboard',
    name: 'Second Opinion',
  },
  bill_pay: {
    link: '/bill',
    name: 'Bill Pay',
  },
  award_point: {
    link: '/utilities/awardpoints',
    name: 'Award points',
  },
  rating: {
    link: '/ratingsdashboard',
    name: 'Ratings',
  },
  health_kit: {
    link: '/circles',
    name: 'HealthKit',
  },
  cms: {
    link: '/circles',
    name: 'CMS',
  },
  default: {
    link: '/home',
    name: 'Alert',
  },
});

export const OPTIONAL = 'Optional';

export const _CARD = Object.freeze({
  HEADING: 'Contact details',
  TAB_LABEL: ['Email ID', 'Phone Number', 'Address'],
  PHONE_VERIFIED_TEXT: 'Phone number verified successfully',
  INITIAL_LOADING: 'Please wait while we load your contact details',
  UPDATING_DATA: 'Updating your data',
  MENU_ITEM: [
    {
      text: 'Deactivate',
      status: 'Deactive',
    },
    {
      text: 'Make primary',
      status: 'Primary',
    },
  ],
  ACTIVATE_TEXT: 'Activate',
  UNVERIFIED_TEXT: 'Unverified',
  MENU_ITEM_TWO: [
    {
      text: 'Deactivate',
      status: 'Deactive',
    },
  ],
  STATUS_TYPE: {
    PRIMARY: 'Primary',
    ACTIVE: 'Active',
    DEACTIVE: 'Deactive',
  },
  ALERT: {
    EMAIL_ACTIVED: {
      TITLE: 'Email activated',
      BODY: 'The email has been activated!',
    },
    EMAIL_DEACTIVED: {
      TITLE: 'Email deactivated',
      BODY: 'The email has been deactivated!',
    },
    PHONE_ACTIVED: {
      TITLE: 'Phone number activated',
      BODY: 'The phone number has been activated!',
    },
    PHONE_DEACTIVED: {
      TITLE: 'Phone number deactivated',
      BODY: 'The phone number has been deactivated!',
    },
    ADDRESS_ACTIVED: {
      TITLE: 'Address activated',
      BODY: 'The address has been activated!',
    },
    ADDRESS_DEACTIVED: {
      TITLE: 'Address deactivated',
      BODY: 'The address has been deactivated!',
    },
  },
  CONFIRM: {
    EMAIL_ACTIVED: {
      TITLE: 'Activate email',
      BODY: 'Are you sure you want to activate this email?',
    },
    EMAIL_DEACTIVED: {
      TITLE: 'Deactivate email',
      BODY: 'Are you sure you want to deactivate this email?',
    },
    PHONE_ACTIVED: {
      TITLE: 'Activate phone number',
      BODY: 'Are you sure you want to activate this phone number?',
    },
    PHONE_DEACTIVED: {
      TITLE: 'Deactivate phone number',
      BODY: 'Are you sure you want to deactivate this phone number?',
    },
    ADDRESS_ACTIVED: {
      TITLE: 'Activate address',
      BODY: 'Are you sure you want to activate this address?',
    },
    ADDRESS_DEACTIVED: {
      TITLE: 'Deactivate address',
      BODY: 'Are you sure you want to deactivate this address?',
    },
    EMAIL_PRIMARY: {
      TITLE: 'Primary email',
      BODY: 'Are you sure you want to make this email primary?',
    },
    ADDRESS_PRIMARY: {
      TITLE: 'Primary address',
      BODY: 'Are you sure you want to make this address primary?',
    },
  },
});

export const ADD_CONTACT = {
  EMAIL: {
    HEADING: 'Add email ID',
    SUBHEADING:
      'We will send you a confirmation email. Click on the link to verify and add this email.',
  },
  PHONE: {
    HEADING: 'Add phone number',
    SUBHEADING: 'We will send you a verification code. Enter the code to verify this phone number',
  },
  BTN_TITLE: 'Submit',
};

export const CONFIRMATION_DIALOGS = {
  PHONE: {
    ACTIVATE: {
      HEADING: 'Phone number activated',
      SUBHEADING: 'The phone number has been activated!',
    },
    DEACTIVE: {
      HEADING: 'Phone number deactivated',
      SUBHEADING: 'The phone number has been deactivated!',
    },
    VERIFICATION: {
      HEADING: 'Verification code sent!',
      SUBHEADING: 'Your verification code has been sent',
    },
    DEACTIVATE_PROMPT: {
      HEADING: 'Deactivate phone number',
      SUBHEADING: 'Are you sure, you want to deactivate this number?',
    },
  },
  EMAIL: {
    ACTIVATE: {
      HEADING: 'Email activated',
      SUBHEADING: 'The email has been activated!',
    },
    DEACTIVE: {
      HEADING: 'Email deactivated',
      SUBHEADING: 'The email has been deactivated!',
    },
    VERIFICATION: {
      HEADING: 'Link sent!',
      SUBHEADING: 'Confirmation email has been sent ',
    },
    DEACTIVATE_PROMPT: {
      HEADING: 'Deactivate email ID',
      SUBHEADING: 'Are you sure, you want to deactivate this email?',
    },
  },
  ADDRESS: {
    ACTIVATE: {
      HEADING: 'Address Activated',
      SUBHEADING: 'The Address has been activated!',
    },
    DEACTIVE: {
      HEADING: 'Address deactivated',
      SUBHEADING: 'The address has been deactivated!',
    },
    DEACTIVATE_PROMPT: {
      HEADING: 'Deactivate address',
      SUBHEADING: 'Are you sure, you want to deactivate this address?',
    },
  },
  BTN_TITLE: {
    OK: 'OK',
    YES: 'Yes',
    NO: 'No',
    SUBMIT: 'Submit',
  },
  SECURITY_CODE: {
    HEADING: 'Verify phone number',
    SUBHEADING: 'Please enter the 4 digit code sent to  phone number above',
    RESEND_CODE_TEXT: 'Resend code',
    CHANGE_TEXT: 'Change',
  },
};
export const EDIT_ADDRESS = {
  HEADING: 'Edit address',
  BTN_TITLE: 'Save',
};
export const ADD_ADDRESS = {
  HEADING: 'Add new address',
  BTN_TITLE: 'Save',
};
export const AGO_TIME = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  WEEK: 'Last 7 days',
  MONTH: 'Last 30 days',
  EARLIER: 'Earlier',
};

export const SAVE_FOR_FASTRER_CHECKOUT = 'Save this card for faster checkout';
export const SEARCH_BAR_PLACEHOLDER = 'Tell me what you want to do';
export const LOADER_DEFAULT_TXT = 'Fetching data';
export const EMAIL_VERIFICATION = Object.freeze({
  BEING_VERIFIED_TXT: 'Your email is being verified',
  VERIFIED_TXT: 'Your email is verified. Redirecting to landing page',
  NOT_VERIFIED_TXT: 'Invalid verification code or your email is already verified.',
});

export const CHIP_DEFAULT_LABEL = 'Lengthy process';
export const SEE_MORE_TEXT = 'See more';

export const LATEST_SO = Object.freeze({
  DIGNOSIS: 'Diagnosis:',
  ZERO_ALERTS_TEXT: 'You have 0 alerts today',
});
export const DOCTOR_CARD = Object.freeze({
  VIEW_FULL_PROFILE: 'view full profile',
  EXPECTED_TIME: 'Expected time:',
  EXPERIENCE: 'Exp',
  CASES: 'Cases',
  PRICE: 'Price',
});
export const DOCTOR_PROFILE_CARD = Object.freeze({
  EXPECTED_TIME: 'Expected time:',
  AWARD_AND_NOMINATIONS: 'Awards and nomination',
  SPECIALITY: 'Speciality',
  EXPERIENCE: 'Exp',
  CASES: 'Cases',
  PRICE: 'Price',
  HIDE_FULL_PROFILE: 'Hide full profile',
});
export const PHONE_NO_START_ADORNMENT = '+1';

export const NO_ACTION_TXT = 'You have no (tabname) second opinions.';

export const ERROR_MSGS = Object.freeze({
  PHONE_DOESNOT_EXIST: "Phone number doesn't exists",
  USER_ALREADY_EXIST: ' User already exists',
  EMAIL_ALREADY_EXIST: 'Email already exists',
  PHONE_ALREADY_EXIST: 'Phone number already exists',
  EMAIL_ALREADY_EXISTS: 'Email already exists',
  REQUIRED: 'required',
  FIELD_REQUIRED: 'Field required',
  INVALID_VALUE: 'Invalid value',
  WRONG_CODE: "code doesn't match",
  PAYMENT_DECLINED: 'Payment declined by the user',
  REFERRAL_CODE: {
    INVALID: `Invalid ${FORM_LABEL.REFERRAL_CODE.toLowerCase()}`,
  },
  PHONE: {
    UNVERIFIED: 'Please verify the phone number before adding',
    FAILED_ADD: 'Unable to add the number at the moment! Try again later...',
    FAILED_EDIT: 'Unable to edit the number at the moment? Try again later...',
  },
  OTP: {
    FAILED_GENERATE: 'Failed to generate OTP! Please try again later...',
    INVALID: 'Invalid OTP',
    FAILED_VERIFY: 'Failed to verify OTP! Please try again later...',
  },
  PASSWORD: {
    EMPTY: `${FORM_LABEL.PASSWORD} required`,
    //LOW_STRENGTH: `Password must have at least one lower case, one upper case, one number and a special character`,
    LOW_STRENGTH: `Must include an upper case and lower case, number and symbol.`,
    LEN_ERR_8: 'At least have 8 characters',
    INCORRECT_OLD_PASSWORD: 'Incorrect old password',
    INCORRECT_PASSWORD: 'Incorrect password',
  },
  EMAIL: {
    EMPTY: `${FORM_LABEL.EMAIL} required`,
    INVALID: `Enter a valid email`,
  },
  LOGIN_EMAIL: {
    EMPTY: `Email id/mobile number required`,
    INVALID: `Invalid email id/mobile number`,
  },
  RE_PASSWORD: {
    EMPTY: 'Confirm password required',
    MATCH_ERR: 'Password does not match',
  },
  FIRST_NAME: {
    EMPTY: `${FORM_LABEL.FIRST_NAME} required`,
    INVALID: `Invalid ${FORM_LABEL.FIRST_NAME.toLowerCase()}`,
  },
  LAST_NAME: {
    EMPTY: `${FORM_LABEL.LAST_NAME} required`,
    INVALID: `Invalid ${FORM_LABEL.LAST_NAME.toLowerCase()}`,
  },
  FULL_NAME: {
    EMPTY: 'Full name required',
    INVALID: 'Invalid name',
  },
  ZIP_CODE: {
    EMPTY: `${FORM_LABEL.ZIP_CODE} required`,
    INVALID: `Invalid ${FORM_LABEL.ZIP_CODE.toLowerCase()}`,
  },
  PHONE_NO: {
    EMPTY: `${FORM_LABEL.PHONE} required`,
    SHOULD_TEN_DIGIT: 'Please enter a valid 10 digit mobile number!',
  },
  COUNTRY: {
    EMPTY: `${FORM_LABEL.COUNTRY} required`,
    INVALID: `Invalid ${FORM_LABEL.COUNTRY.toLowerCase()}`,
  },
  DATE: {
    EMPTY: `${FORM_LABEL.DATE} required`,
  },
  DOB: {
    EMPTY: `${FORM_LABEL.DOB} required`,
    INVALID: `Invalid ${FORM_LABEL.DOB}`,
  },
  AMOUNT: {
    EMPTY: 'Amount required',
    INVALID: 'Invalid amount',
  },
  ROUTING_NUMBER: {
    EMPTY: 'Routing number required',
    INVALID: 'Routing number should be 9 digit number',
  },
  ACCOUNT_NUMBER: {
    EMPTY: 'Account number required',
    INVALID: 'Invalid account number',
  },
});

export const PASSWORD_STRENGTH_TITLE = ['Too short', 'Weak', 'Medium', 'Strong', 'Very strong'];

export const IMAGE_ALTERNATIVE_TXT = Object.freeze({
  LOGO: 'Mpowered logo',
  SERVICE: 'Service',
  IMAGE_EDITOR_IMAGE: 'Image',
  ALERT_TYPE: 'type',
  AWARD_POINT_STEP: 'Stepper',
  PAYMENT_FAILED: 'Payment failed',
  PAYMENT_SUCCESS: 'Payment success',
  PAYMENT_PENDING: 'Payment initiated',
  PAYMENT_SUCCESS_HURRAY: 'Hurray',
  EMAIL_VERIFY_FAIL: 'Email verify fail',
  EMAIL_SENT: 'Email sent',
  EMAIL_VERIFIED: 'Verified',
  PHONE_VERIFIED: 'Verified',
  PREVIEW_IMAGE: 'Preview image',
  OTP_IMAGE: 'Verification code image',
  RESET_OK: 'Reset ok',
  SO_TAB_NO_ACTION: 'No Action',
  SCAN: 'Scan',
  CT_SCAN: 'CT scan',
  EDIT_ICON: 'Edit',
  ADD_ICON: 'Add',
  USER: 'User',
  SUCCESS: 'Success',
  NO_BILLS: 'No bills',
  HELPER_IMAGE: 'Helper image',
  AVATAR: 'Avatar',
});

export const FOOTER = [
  {
    icon: '/src/assets/images/home.svg',
    lightIcon: '/src/assets/images/home_light.svg',
    label: 'Home',
    link: '/home',
    alt: 'Home',
  },

  {
    icon: '/src/assets/images/shopping_cart2.svg',
    lightIcon: '/src/assets/images/shopping_cart.svg',
    label: 'Shop',
    link: '/shop',
    alt: 'Shop',
  },
  {
    icon: '/src/assets/images/circles.svg',
    lightIcon: '/src/assets/images/circles_light.svg',
    label: 'Circles',
    link: '/circles',
    alt: 'Circles',
  },
  {
    icon: '/src/assets/images/services.svg',
    lightIcon: '/src/assets/images/services_lightPayment Success.svg',
    label: 'Records',
    link: '/records',
    alt: 'Records',
  },
  {
    icon: '/src/assets/images/utilities.svg',
    lightIcon: '/src/assets/images/utilities_light.svg',
    label: 'Utilities',
    link: '/utilities',
    alt: 'Utilities',
  },
];
export const LOADING = 'Loading';
export const BILL_SUMMARY_TAB_PAY_NOW = 'Pay now';
export const SO_CHAT_ASK_A_QUESTION = 'Ask a question below';
export const CARD_NUMBER = 'xxxx - (last4)';

export const BILL_DETAIL_TILE_DEFAULT_VALUE = '-';

export const BLOOD_GROUPS = ['A+ve', 'A-ve', 'B+ve', 'B-ve', 'O+ve', 'O-ve', 'AB+ve', 'AB-ve'];

export const DOCPANEL_CASE = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  PENDING: 'pending',
  DOCPANEL_CASE_INFO: 'docpanelCaseInfo',
  STATUS: 'status',
  GUID: 'guid',
  TERMINATE: 'terminate iframe',
};

export const DOCPANEL_TERM_CONDITION_DIALOG = {
  HEADING: 'DocPanel terms And conditions',
  SUBHEADING_PREFIX:
    'You are about to go to the DocPanel’s site, by clicking on Continue, you agree to the',
  SUB_HEADING_POSTFIX: 'of DocPanel.',
  CANCEL: 'Cancel',
  CONTINUE: 'Continue',
  TERMS_CONDITION_LINK: 'https://www.docpanel.com/terms-and-conditions',
  HYPERLINK_TEXT: 'Terms and conditions',
};

export const USER_CONTACT = {
  email: 'Email',
  phone: 'Phone',
};

export const LOADING_TEXT = {
  _CARD,
};

export const PROFILE_DETAIL_MENU = Object.freeze({
  PRIMARY: 'Make primary',
  DEACTIVE: 'Deactivate',
});

export const PROFILE_DETAIL_CONTANT = Object.freeze({
  PRIMARY: 'Primary',
  ACTIVE: 'Activate',
});

export const ADD_EMAIL = Object.freeze({
  TITLE: 'Link sent',
  BODY: 'Confirmation email has been sent!',
});

export const ADD_PHONE = Object.freeze({
  TITLE: 'Link sent',
  BODY: 'Confirmation email has been sent!',
  VERIFIED: 'Phone number verified successfully',
});

export const SAVING_DATA = 'Saving data';

export const DOCPANEL_PRICES = Object.freeze({
  ACTUAL: 199,
  EXTRA_PART: 75,
});

export const PROCEED_TO_SECOND_OPINION = {
  HEADING: 'Second Opinion',
  AMOUNT: 'Your price for a second opinion for one body part is ',
  DOCPANEL_AMOUNT: `$${DOCPANEL_PRICES.ACTUAL} \nand `,
  DOCPANEL_ADDITIONAL_PART: `$${DOCPANEL_PRICES.EXTRA_PART} `,
  ADDITIONAL_PART: 'per additional body part',
  SUB_HEADING: 'Who is this second opinion for?',
  SELF: 'Self',
  OTHER: 'Other',
  PATIENT_DETAILS: 'Patient details',
  RELATIONSHIP: 'Relationship to you',
  CONSENT: 'I am the legal guardian or have the necessary consents to order this second opinion',
  TERMS_AND_CONDITIONS: "I agree to DocPanel's",
  TERMS_CONDITION_LINK: 'https://www.docpanel.com/terms-and-conditions',
  TERMS_CONDITION_LINK_TEXT: 'Terms And Conditions',
  PROCEED: 'Proceed',
  DICOM:
    'You are about to go to DocPanel’s site. Please have the X-rays, scans and medical records ready. X-rays and \nscans are typically DICOM images with file name extensions .dcm and .dcm30',
  PENDING_CASES_DIALOG: {
    HEADING: 'You have pending second opinion requests',
    SUBHEADING:
      'If you are creating a second opinion request for previously uploaded scans, please continue your pending case.',
    CONTINUE: 'Create new case',
    RESUME: 'Resume pending case',
  },
};

export const RELATIONS = ['child', 'parent', 'spouse', 'common', 'other', 'self', 'injured'];

export const IDENTIFIER_SYSTEM = {
  DOCPANEL: 'https://www.docpanel.com/',
  STRIPE: 'https://stripe.com/',
};

export const SHARE_REFERRAL = Object.freeze({
  TITLE: 'Mpowered Health Invitation',
  CONTENT: (name, code) =>
    `${name} is inviting you to download the Mpowered Health app. Click here to download`,
  // CONTENT: 'I am inviting you to download the Mpowered Health application. Please use this link',
});

export const DOCPANEL_FAQ = Object.freeze({
  HELP: 'If you need help regarding your second opinion request please contact DocPanel at',
  MAIL: 'support@docpanel.com',
  PHONE: '856-242-7343',
  FAQ: 'For more information please visit DocPanel',
  FAQ_HPERTEXT: 'FAQ',
  FAQ_LINK: 'https://www.docpanel.com/faq',
  QUESTIONS: 'Questions? Please contact DocPanel at <EMAIL> or <PHONE>',
  CONSULT_RADIOLOGIST: 'Consult Radiologist',
  VALID_THROUGH: '(valid from <FROM> to <TO>)',
  DURATION_TO_CONSULT_DOCPANEL: 5,
  EMAIL_TEXT: 'Email',
  PHONE_TEXT: 'Phone',
});

export const DEFAULT_COUNTRY = 'US';

export const STATE_SELECTOR_PLACEHOLDER = 'Select state';

export const HELP_PAGE = Object.freeze({
  HEADING: 'Frequently asked questions',
});

export const HELP_PAGE_ITEMS = [
  // {
  //   TITLE: 'Second Opinions',
  //   ICON_SOURCE: 'second-opinion-help.svg',
  //   BODY: [
  //     {
  //       QUERY: 'What is a second opinion?',
  //       SUGGESTION:
  //         'When you request a second opinion, you are asking another doctor or specialist to take a look at your medical records and give their opinion on the diagnosis, recommended treatment or other questions that you may have on your situation. ',
  //     },
  //     {
  //       QUERY: 'What types of second opinion are available?',
  //       SUGGESTION:
  //         'There are several types of second opinions. Currently, Mpowered Health offers second opinions only for Radiology Reports. We work with partners to provide you with access to licensed radiologists who specialize in reading X-rays, CT-scans, MRI and other diagnostic imaging in specific conditions and areas such as neurological, musculoskeletal etc.' +
  //         '\n\nIn our endeavor to bring more choice and flexibility to you, we plan to expand this service to include other medical second opinions in the future ',
  //     },
  //     {
  //       QUERY: 'How much does a second opinion cost?',
  //       SUGGESTION:
  //         'The cost is specific to the partner and the type of second opinion being requested. You can see the cost of your specific second opinion request before you submit your order.',
  //     },
  //     {
  //       QUERY: 'How do I pay for my second opinion?',
  //       SUGGESTION:
  //         'You can pay using a credit card or Mpowered Award points if you have accumulated points using our integrated bill payment solution which is secure and PCI compliant.',
  //     },
  //     {
  //       QUERY: 'How do I order a second opinion?',
  //       SUGGESTION:
  //         'Click on the Second Opinions from your Home dashboard. Click on "+" link in the Second Opinion page and the follow the steps',
  //     },
  //     {
  //       QUERY: 'Why does my second opinion order show up in the Pending tab?',
  //       SUGGESTION:
  //         'This means that you did not complete all the steps required to submit your second opinion order. Please click on the link and complete the ordering process.',
  //     },
  //     {
  //       QUERY: 'How will I know my order for a second opinion was submitted?',
  //       SUGGESTION: 'Your successful order will be visible in the Requested tab in Second Opinions',
  //     },
  //     {
  //       QUERY: 'How do I receive the report from the Radiologist?',
  //       SUGGESTION:
  //         'When the second opinion report from the radiologist becomes available, you will receive an alert that the report is now available. You can view the report by either clicking on the alert or going to the Reviewed tab in Second Opinions.',
  //     },
  //     {
  //       QUERY: 'Can I consult the Radiologist about the report?',
  //       SUGGESTION:
  //         'You may consult the radiologist on his report for a period of time after the report is delivered. This time period varies depending on the SLA of the company you picked for your second opinion. This information if available from the partner will be shown along with their reviewed report',
  //     },
  //   ],
  // },
  {
    TITLE: 'Award points',
    ICON_SOURCE: 'award-point-help.svg',
    BODY: [
      {
        QUERY: 'How do I earn award points?',
        SUGGESTION:
          'Mpowered Health may give you points for different activities at different times based on programs running in the system. You will be alerted to when you can earn points. ',
      },
      {
        QUERY: 'What are the different ways I can use my award points?',
        SUGGESTION:
          'You can use your award points to pay for a service you order through the mpowered app (e.g paying for a second opinion). You can also redeem points via a gift card or pre-paid card, or donate to a charity.',
      },
    ],
  },
  {
    TITLE: 'Referral',
    ICON_SOURCE: 'referral-help.svg',
    BODY: [
      {
        QUERY: 'How do I refer a friend?',
        SUGGESTION:
          "Click on Share link. Share your unique code with your friend using email, phone or the other options available. You can also send your unique code to your friend manually. Your friend should use the code while signing up for his/her separate account. The referral will be considered successful once your friend's account setup and verification is complete.",
      },
    ],
  },
  {
    TITLE: 'Profile',
    ICON_SOURCE: 'profile-help.svg',
    BODY: [
      {
        QUERY: 'Why do I always need to authenticate every time I edit my profile?',
        SUGGESTION:
          'Your profile information is a very sensitive section of your account that we use to determine not only your identity but also use it to match your health records. In order to prevent hacking and misuse, we have added additional security. While this may be inconvenient for you, please understand we are doing this to protect you and other consumers like you.',
      },
      {
        QUERY: 'How can I edit my profile ?',
        SUGGESTION:
          'The edit icon needs to be clicked in the profile section. Upon clicking the edit icon, the user is prompted for a OTP validation. Once the OTP is entered, the user can add/edit the information within the session.',
      },
      {
        QUERY: 'Can I change my primary phone number?',
        SUGGESTION:
          'At this time, we do not allow edits to primary phone number as your account is attached to that number and used for authentication and login. We are sorry for the inconvenience. We intend to add this capability in the future. ',
      },
    ],
  },
  {
    TITLE: 'Payments',
    ICON_SOURCE: 'payment-help.svg',
    BODY: [
      {
        QUERY: 'How can I store my payments in Mpowered?',
        SUGGESTION:
          'Click on Utilities menu at the bottom of the app. In the Utilities dashboard, click on Wallet. Follow instructions to add, edit or delete payment options. Our payment solutions are secure and PCI compliant.',
      },
    ],
  },
  {
    TITLE: 'Add coverage',
    ICON_SOURCE: 'coverage.svg',
    BODY: [
      {
        QUERY: 'What are the different relationships to the subscriber?',
        SUGGESTION: [
          'These values represent your relationship to the policy holder of a particular coverage/plan. Child - You are a child of the Policy Holder, Spouse - You are a spouse of the policy holder, Parent - You are a parent of the policy holder, Common - You are a common law spouse of th policy holder, Self - You are the policy holder, Injured - You are an injured party covered by the plan of the policy holder, Other -  You have some other relationship to the policy holder',
        ],
      },
    ],
  },
  {
    TITLE: 'My Medicare',
    ICON_SOURCE: 'my-medicare.jpg',
    BODY: [
      {
        QUERY: 'Who can download health records from My Medicare?',
        SUGGESTION: [
          'If you are a Medicare beneficiary, then you can download your Medicare claims using your Medicare Beneficiary ID and password. If you are a Medicare fee-for-service beneficiary i.e you are not enrolled in a Medicare Advantage plan, you will see all your claims. If you have a Medicare Advantage plan, you may see only Part D (drug ) claims. You will need to add your Medicare Advantage Plan as a partner in your circles to see all other claims.  We plan to add support for Medicare Advantage Plan partners shortly.',
        ],
      },
      ,
      {
        QUERY: 'How do I make sense of the data?',
        SUGGESTION: [
          'My Medicare makes claims data available to you. The application organizes the data based on what we receive on your behalf and present it in a way that is understandable for you.',
        ],
      },
      {
        QUERY: 'What are related records?',
        SUGGESTION: [
          'We may infer some information based on the claims, such as the diagnosis in a claim or a procedure that was performed for which the claim was raised by your provider.  Where we infer such information, we will note it as such on the screen. Such information is presented for your helpful viewing only and will not become part of your record.',
        ],
      },
      {
        QUERY: 'How can I download my data from My Medicare?',
        SUGGESTION: [
          'Please click on "Add to circle" in the Circles dashboard, and select My Medicare from the drop down. Enter your Medicare credentials and authorize Mpowered Health to receive information on your behalf. Upon successfully submitting, the application will try to authenticate with My Medicare and download your health information. You can view this information when you click on "My Medicare" icon under the section "Circles".',
        ],
      },
    ],
  },
  {
    TITLE: 'Apple Health',
    ICON_SOURCE: 'Icon-Apple-Health-Small.png',
    BODY: [
      {
        QUERY: 'How can I store my payments in Mpowered?',
        SUGGESTION: [
          'Please click on "Add to circle" in the Circles dashboard, and select Apple Health from the drop down. Enter your Apple credentials and authorize Mpowered Health to receive information on your behalf. Upon successfully submitting, the application will try to authenticate with Apple Health and download your health information. You can view this information when you click on "Apple Health" icon under the section "Circles".',
        ],
      },
      {
        QUERY: 'Can I download Apple Health data if I do NOT have an Apple device?',
        SUGGESTION: [
          'Apple does not support this integration on non Apple devices. So if you are on an Android, you will not be able to fetch any data from Apple Health. However, if you have already downloaded data from Apple Health onto your Mpowered health record, that information is available to your for viewing at anytime in the future irrespective of the device you have.',
        ],
      },
    ],
  },
  ///////////////////SCAN FAQ's//////////////////////////////
  // {
  //   TITLE: 'Benefit allowance',
  //   ICON_SOURCE: faqIcon,
  //   BODY: [
  //     {
  //       QUERY: 'How can I find out what my benefit allowance is?',
  //       SUGGESTION:
  //         'Benefit allowances vary depending on the plan. Check your Evidence of Coverage.',
  //     },
  //   ],
  // },
  // {
  //   TITLE: 'PCP change',
  //   ICON_SOURCE: faqIcon,
  //   BODY: [
  //     {
  //       QUERY: 'How do I change my PCP?',
  //       SUGGESTION:
  //         'You can initiate a PCP change from the Care & Prescriptions tab on your account dashboard.',
  //     },
  //   ],
  // },
  // {
  //   TITLE: 'Changing primary care doctor',
  //   ICON_SOURCE: faqIcon,
  //   BODY: [
  //     {
  //       QUERY: 'When can I change my doctor?',
  //       SUGGESTION: `You can choose a new primary care doctor at any time and for any reason.\n\nOnce you choose a new doctor, the change will go into effect either on the first day of the next month or the first day of the following month. It depends on when you request the change and whether you are also changing to a new medical group.`,
  //     },
  //     {
  //       QUERY:
  //         'I’d like to stay with my primary care doctor, can I follow them to their new medical group?',
  //       SUGGESTION: `We know that your relationship with your doctor is important, and sometimes it’s possible to follow them to their new office. Call Member Services for help finding out if your doctor’s new office is in your plan and accepting new patients.`,
  //     },
  //   ],
  // },
  // {
  //   TITLE: 'Annual physical exams',
  //   ICON_SOURCE: faqIcon,
  //   BODY: [
  //     {
  //       QUERY: 'Does SCAN cover annual physical exams?',
  //       SUGGESTION: `Yes! It’s important to see your primary care doctor at least once every year. These exams are when your doctor will recommend any preventive tests and screenings you may need, and see how you’re managing any chronic conditions. So, if you haven’t seen your doctor in the past 12 months, please call to schedule an appointment soon.
  //         `,
  //     },
  //   ],
  // },
  // {
  //   TITLE: 'Dental coverage',
  //   ICON_SOURCE: faqIcon,
  //   BODY: [
  //     {
  //       QUERY: 'Does SCAN Health Plan offer dental coverage?',
  //       SUGGESTION: `All SCAN plans provide the preventive dental services that are covered by Original Medicare. Most SCAN plans also offer the option to sign up for additional dental coverage at an additional monthly premium. Dental coverage is supplemental and offered through Delta Dental®.\n\nSome SCAN plans, like SCAN Connections (HMO SNP) and some employer group plans, offer dental coverage as part of your regular benefits. If you’d like to see if your coverage includes dental as part of your regular benefits, check out Chapter 4 of your Evidence of Coverage.`,
  //     },
  //   ],
  // },
  // {
  //   TITLE: 'Track orders',
  //   ICON_SOURCE: faqIcon,
  //   BODY: [
  //     {
  //       QUERY: 'Can I track my orders?',
  //       SUGGESTION: `Yes, go to the My Account page on the SCAN OTC website and click on Order History. That will take you to a list of all of your orders, including those placed by mail or over the phone. Or you can call 1-877-494-2892 (TTY: 711) to find out the status of your order.`,
  //     },
  //   ],
  // },
  // {
  //   TITLE: 'Fetch records from Scan health',
  //   ICON_SOURCE: faqIcon,
  //   BODY: [
  //     {
  //       QUERY: 'How do I fetch my records from Scan Health?',
  //       SUGGESTION: `Steps to fetch your records from Scan Health Plan\n\n1. Go to Circles\n2. Click on Add to circle\n3. Click on Organization\n4. Search and select Scan Health Plan\n5. Add your credentials and Proceed.You should be able to see data in the application.
  //       `,
  //     },
  //   ],
  // },
];

export const COVERAGE_SUMMARY_TEXTS = Object.freeze({
  ADD_BUTTON_TEXT: 'Shop for coverage',
  LOADING_COVERAGES_TEXT: 'Please wait while we load coverages',
  LOADING_ADD_COVERAGE: 'Please wait',
  CHANGE_PLAN: 'Change plan',
  PENDING_COVERAGE_TEXT: `*If you are seeing the status as "Pending" for eHealth plans, it is because you recently visited the eHealth website. If you enrolled in a plan, the status will be updated once we receive the information from eHealth. Otherwise, this status will be cleared after 24-48 hours.`,
});
export const CIRCLES_SUMMARY_TEXTS = Object.freeze({
  ADD_BUTTON_TEXT: 'Add a partner',
  LOADING_COVERAGES_TEXT: 'Please wait while we load circles',
  LOADING_ADD_COVERAGE: 'Please wait',
  PENDING_COVERAGE_TEXT: `*If you are seeing the status as "Pending" for eHealth plans, it is because you recently visited the eHealth website. If you enrolled in a plan, the status will be updated once we receive the information from eHealth. Otherwise, this status will be cleared after 24-48 hours.`,
});

export const ADD_COVERAGE_PAGE_DISCLAIMER = Object.freeze({
  CONTENT: `We do not offer every plan available in your area. Currently we represent 193 organizations which offer 4,624 products in your area. Please contact Medicare.gov, 1-800-MEDICARE, or your local State Health Insurance Program (SHIP) to get information on all of your options. Data represented is for the plan year 2023.`,
});

export const ADD_COVERAGE_PAGE_MA = Object.freeze({
  CONTENT: `You are about to go to eHealth's website. eHealth makes it easier for you to find
  affordable, quality health insurance. Please have your Medicare card and primary care
  physician's name handy.`,
});
export const ADD_COVERAGE_PAGE_IFP = Object.freeze({
  CONTENT: `You are about to go to eHealth's website. eHealth makes it easier for you to find
  affordable, quality health insurance.`,
});

export const ADD_COVERAGE_PAGE_CHECKBOXS = Object.freeze({
  I_AGREE_TEXT: 'I agree to the eHealth ',
  TERMS_CONDITION_LINK: ' https://www.ehealthinsurance.com/about-ehealth/terms-of-use/',
  TERMS_CONDITION_LINK_TEXT: ' Terms and Conditions',
  AND: ' and ',
  PRIVACY_POLICY_LINK: 'https://www.ehealthinsurance.com/about-ehealth/privacy-policy/',
  PRIVACY_POLICY_LINK_TEXT: 'Privacy policy',
  CONTENT_TEXT: `  and authorize Mpowered Health to share my zip code details with eHealth to customize the shopping and enrollment experience.
  If I enroll in a plan, I authorize eHealth to share my enrollment and plan information with Mpowered Health`,
  DRUG_LABEL: `I agree that Mpowered Health may share my Provider, Drug, Pharmacy & Personal information with eHealth for an enhanced shopping and enrollment experience.`,
});

export const ADD_COVERAGE_PAGE_BUTTON = Object.freeze({
  ADD_COVERAGE_PAGE_BUTTON_TEXT: 'Proceed',
});

export const NO_PENDING_COVERAGE = Object.freeze({
  TITLE: ' No coverage found',
  NO_PENDING_COVERAGE_TITLE: 'No pending coverage',
  NO_PENDING_CONTENT: `We couldn’t find any coverage in your records`,
});
export const NO_PENDING_CIRCLES = Object.freeze({
  TITLE: 'No partners in my circle!',
  NO_PENDING_COVERAGE_TITLE: 'No pending circles',
  NO_PENDING_CONTENT: `Please click on the below button “ Add a partner “ to add a partner to your circle`,
});

export const COVERAGE_PLANS_FULL_NAMES = Object.freeze({
  MA: 'Medicare Advantage Plan',
  IFP: 'Individual Family Plan',
  MD: 'Medicare prescription drug plan',
  MS: 'Medicare supplement insurance plan',
  DT: 'Dental plan',
  VSP: 'Vision plan',
  DVH: 'Dental, vision, hearing plan',
  ST: 'Short term plan',
});

export const STAY_TUNED_POPUP = {
  HEADING: 'Stay tuned!',
  SUBHEADING: 'More features coming soon.',
};

export const DATE_FORMAT = '<MMM> <DD>, <YYYY>';

export const FIREBASE_PARAMS_NAMES = Object.freeze({
  PROFILE_MODIFIED: 'modified',
  ADDRESS_ADDED: 'added',
  ADDRESS_UPDATED: 'updated',
  SUCCESS: 'success',
  FAIL: 'fail',
  ADD: 'add',
});

export const FIREBASE_EVENT_NAMES = Object.freeze({
  PROFILE_UPDATE: 'user_profile',
  ONE_TIME_LOGIN_PASSED: 'one_time_login',
  LOGIN_PASSED: 'login',
  LOGIN_FAILED: 'login_failed',
  USER_ADDRESS: 'user_address',
  CONN: 'circle_connections_orgs',
  CONN_ADD_APPLE_HEALTH_KIT: 'conn_apple_health_kit',
  INITITATE_SECOND_OPINION: 'second_opinion_initiated',
  VALIDATING_EMAIL: 'email_verified',
  VALIDATING_PHONE_NUM: 'phone_num_verified',
  REGISTRATION_ATTEMPT: 'registration_attempted',
  REGISTRATION: 'registration_completed',
  REGISTRATION_WITH_REFERRAL: 'registration_with_referral',
  REGISTRATION_WITH_PROMO: 'registration_with_promo_code',
  PHONE_VERIFIED: 'phone_verified',
  RATING: 'rating_completed',
  REFER_A_FRIEND: 'referred_a_friend',
  PAYING_A_BILL: 'paying_a_bill',
  VIEW_COMPLETED_SECOND_OP: 'second_opinion_viewed',
  SECOND_OPINION_CREATED: 'second_opinion_created',
  PROFILE_UPDATED: 'profile_updated',
});

export const DEFAULT_NULL_REPLACEMENT = '-';
export const DEFAULT_NA = 'NA';

export const ERROR_MESSAGES = {
  NOT_FOUND: 'NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  CODE_500: 'Something went wrong',
};

export const ACCEPT_CONSENT = Object.freeze({
  TERMS_CONDITION: {
    HEADER: 'Mpowered Health',
    SUB_HEADER_PREFIX: 'Our terms & conditions were updated. Please read and accept the updated ',
    SUBHEADER_POSTFIX: ' in order to continue.',
  },
  PRIVACY_POLICY: {
    HEADER: 'Mpowered Health',
    SUB_HEADER_PREFIX: 'Our privacy policy was updated. Please read and accept the updated ',
    SUBHEADER_POSTFIX: ' in order to continue.',
  },
  BOTH: {
    HEADER: 'Mpowered Health',
    SUB_HEADER_PREFIX:
      'Our terms & conditions and privacy policy were updated. Please read and accept the updated ',
    SUBHEADER_POSTFIX: ' in order to continue using the platform',
  },
  ACCEPT: 'Accept',
  CANCEL: 'Cancel',
  TERMS_CONDITION_TEXT: 'Terms & conditions',
  PRIVACY_POLICY_TEXT: 'Privacy policy',
  AND: ' and ',
});

export const NON_ACTIVE_SERVICE_POP_UP = {
  HEADING: 'Stay tuned !',
  SUBHEADING: 'More features coming soon.',
};

export const MAX_SERVICES_ON_HOME_COUNT = 4;

export const BANK_ACCOUNT_FORM_TYPE = {
  ADD: 'add_bank_account',
  EDIT: 'edit_bank_account',
};

export const PAYMENT_TYPE = {
  bank: 'bank account',
  card: 'card',
};
export const PAYMENT_STATUS = {
  PENDING: 'pending',
};
export const BILL_SUMMARY_TAB_PENDING = 'Pending';
export const IMAGE_EDITOR_BTN = {
  CANCEL: 'Cancel',
  SAVE: 'Save',
};

export const RATING_CATEGORY = Object.freeze({
  cost: {
    name: 'Cost',
  },
  quality: {
    name: 'Quality',
  },
  convenience: {
    name: 'Convenience',
  },
  interaction_experience: {
    name: 'Interaction experience',
  },
});

export const AWARD_POINT_PAYMENT_FAILURE_MODAL = {
  HEADING: 'Failed to process payment!',
  SUB_HEADING: 'Please enter valid award points to proceed to payment.',
};

export const TRANSACTION_FAILURE_MESSAGE = 'Unfortunately, your last transaction failed.';
export const REASON_FOR_CANCELLATION = 'Reason for cancellation';
export const CLAIMS_DASHBOARD = {
  HEADING: 'Claims',
};

export const DEACTIVATE = {
  LOADING: 'Deactivate is loading...',
  HEADING: 'Deactivate account',
  SUB_HEADING: "We're sorry to see you go!",
  DEACTIVATE_CONFIRM_HEADING: 'Account deactivated',

  DEACTIVATE_CONFIRM_SUB_HEADING: [
    'If you did NOT initiate the deactivation, contact support@mpoweredhealth.com immediately with title “URGENT: ACCOUNT DEACTIVATION”',
  ],

  CONFIRMATION_HEADING: 'Deactivate account',

  CONFIRMATION_SUBHEADING: ['Are you sure you want to deactivate your account?'],

  REASON_HEADING: 'Reason for leaving?',

  REASON_BEFORE_GO: 'Before you go...',

  INFO_AWARDS:
    '• You have 700 award points. You will not be able to redeem them after the account deactivation.',
  INFO_AWARD_FIRST: 'You have',
  INFO_AWARD_MIDDLE: ' award points. ',
  INFO_AWARD_LAST: 'You will not be able to redeem them after the account deactivation. ',

  INFO_CONNECTIONS: '• You have 10 connections in your circle.',
  INFO_CONNECTION_FIRST: 'You have',
  INFO_CONNECTION_MIDDLE: ' connection(s)',
  INFO_CONNECTION_LAST: ' in your circle.',

  CANCEL_DEACTIVATE: 'Cancel deactivation',

  GO_DEACTIVATE: 'Continue',

  REASON_1: 'Not satisfied with services',

  REASON_2: 'Did not find what I was looking for',

  REASON_3: 'Not user friendly',

  REASON_4: 'App too slow',

  REASON_5: 'Others',

  DOWNLOAD: 'Download health records',

  DOWNLOAD_HEADING: 'Download my health records',

  FEATURE_NOT_AVAILABLE: 'This feature is not available, coming soon!',

  CONTACT_EMAIL_MESSAGE: 'Please contact support@mpoweredhealth.com to get a copy of your records.',

  DOWNLOAD_SUB_HEADING:
    'Data will not be available for download after account deactivation. We recommend  you to download all the data before deactivating the account.',

  DOWNLOAD_SELECTION_1: 'I will no longer be able to earn or redeem award points.',

  DOWNLOAD_SELECTION_2: 'I have read and understood the implications of deactivating the account.',

  DOWNLOAD_2: 'I will have to go through additional verification steps to re-activate my account.',

  DOWNLOAD_SELECTION_3:
    'I will no longer receive alerts or notifications to my email or phone about my account.',

  TERMS_CHECKBOX: 'I have read and understood the implications of deactivating the account.',

  DOWNLOAD_CANCEL: 'Cancel',

  DOWNLOAD_CONFIRM: 'Confirm',

  DOWNLOAD_CONTINUE: 'Continue',

  TERMS_HEADING: 'Terms and conditions',

  TERMS_1:
    'Mpowered Health will not request or share any information about myself even if I have consents set up in my account.',

  TERMS_2:
    'My partners will immediately stop seeing all my information, except information for which I have given them consent to download.',

  TERMS_3: 'I will no longer be able to earn or redeem award points.',

  TERMS_4: 'I will no longer receive alerts or notifications on my email or phone.',

  TERMS_5:
    'My personal data will be archived for 10 years and during this period it will be used only for legal, audit & compliance purpose. (For example, we may need to respond to audits by regulatory authorities).',

  TERMS_6:
    'My data will not be available for download after I deactivate. We recommend that you download all your data before deactivating your account.',

  TERMS_7:
    'I may re-activate my account, but I will have to go through additional verification steps & incur a fee to re-activate my account.',

  TERM_CONSENT: 'I have read and understood the implications of deactivating the account.',
};

export const CIRCLES_STAY_TUNED = Object.freeze({
  TITLE: 'Circles',
  HEADING: 'Stay tuned!',
  SUBHEADING:
    'This experience is currently available only on Mobile. We are working to get this experience to the web too!',
});

export const SNACKBAR_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const dateFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Past 1 month', value: '1 month' },
  { label: 'Past 3 months', value: '3 months' },
  { label: 'Past 6 months', value: '6 months' },
];
export const carePlanDateFilterMenuItems = [
  { label: 'All', value: 'all' },
  // { label: 'Past 1 month', value: '1 month' },
  { label: 'Past 3 months', value: '3 months' },
  { label: 'Past 6 months', value: '6 months' },
];
export const vitalsDateFilterMenuItems = [
  { label: 'Last 7 days', value: '7 days' },
  { label: 'Last 15 days', value: '15 days' },
  { label: 'Last 30 days', value: '30 days' },
];

export const statusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active', icon: ActiveIcon },
  { label: 'Pending', value: 'pending', icon: PendingIcon },
  { label: 'Inactive', value: 'inactive', icon: RejectIcon },
];

export const claimsStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active', icon: ActiveIcon },
  { label: 'Cancelled', value: 'cancelled', icon: RejectIcon },
  { label: 'Draft', value: 'draft', icon: PendingIcon },
  { label: 'Others', value: 'others', icon: GrayColorStatusIcon },
];

export const statusFilterMenuItemsForCoverage = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active', icon: ActiveIcon },
  {
    label: 'Pending',
    value: 'pending',
    mappingList: ['pending', 'draft'],
    icon: PendingIcon,
  },
  {
    label: 'Inactive',
    value: 'inactive',
    icon: RejectIcon,
    mappingList: ['cancelled,entered-in-error'],
  },
];
export const medicationsStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active', icon: ActiveIcon },
  {
    label: 'Inactive',
    value: 'inactive',
    icon: RejectIcon,
    mappingList: ['stopped', 'completed', 'cancelled'],
  },
  {
    label: 'Others',
    value: 'others',
    icon: GrayColorStatusIcon,
    mappingList: ['entered-in-error', 'on-hold', 'draft', 'unknown'],
  },
];

export const conditionsStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active', icon: ActiveIcon },
  { label: 'Inactive', value: 'inactive', icon: RejectIcon },
  { label: 'Resolved', value: 'resolved', icon: CompletedIcon },
  { label: 'Others', value: 'others', icon: GrayColorStatusIcon },
];

export const allergiesStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active', icon: ActiveIcon },
  { label: 'Inactive', value: 'inactive', icon: RejectIcon },
  { label: 'Resolved', value: 'resolved', icon: CompletedIcon },
  { label: 'Confirmed', value: 'confirmed', icon: ActiveIcon },
  { label: 'Unconfirmed', value: 'unconfirmed', icon: GrayColorStatusIcon },
  { label: 'Refuted', value: 'refuted', icon: GrayColorStatusIcon },
  { label: 'Unknown', value: 'others', icon: GrayColorStatusIcon },
];
export const billsStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: 'draft', icon: PendingIcon },
  { label: 'Issued', value: 'issued', icon: ActiveIcon },
  { label: 'Balanced', value: 'balanced', icon: CompletedIcon },
  { label: 'Cancelled', value: 'cancelled', icon: RejectIcon },
  { label: 'Entered in error', value: 'entered-in-error', icon: GrayColorStatusIcon },
];

export const labResultsStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Registered', value: 'registered', icon: ActiveIcon },
  { label: 'Partial', value: 'partial', icon: PendingIcon },
  { label: 'Preliminary', value: 'preliminary', icon: PendingIcon },
  { label: 'Final', value: 'final', icon: CompletedIcon },
  { label: 'Others', value: 'others', icon: GrayColorStatusIcon },
];

export const proceduresStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Preparation', value: 'preparation', icon: ActiveIcon },
  { label: 'In progress', value: 'in-progress', icon: ActiveIcon },
  { label: 'Not done', value: 'not-done', icon: RejectIcon },
  { label: 'Completed', value: 'completed', icon: CompletedIcon },
  { label: 'Others', value: 'others', icon: GrayColorStatusIcon },
];

export const immunizationsStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Completed', value: 'completed', icon: CompletedIcon },
  { label: 'Not done', value: 'not-done', icon: RejectIcon },
  { label: 'Others', value: 'others', icon: GrayColorStatusIcon },
];

export const claimTypeFilterMenuItems = [
  {
    value: 'all',
    label: CMS_CLAIMS_TYPE_TEXTS['All'],
  },
  {
    value: 'CARRIER',
    label: CMS_CLAIMS_TYPE_TEXTS['CARRIER'],
  },
  {
    value: 'DME',
    label: CMS_CLAIMS_TYPE_TEXTS['DME'],
  },
  {
    value: 'HHA',
    label: CMS_CLAIMS_TYPE_TEXTS['HHA'],
  },
  {
    value: 'HOSPICE',
    label: CMS_CLAIMS_TYPE_TEXTS['HOSPICE'],
  },
  {
    value: 'INPATIENT',
    label: CMS_CLAIMS_TYPE_TEXTS['INPATIENT'],
  },
  {
    value: 'OUTPATIENT',
    label: CMS_CLAIMS_TYPE_TEXTS['OUTPATIENT'],
  },
  {
    value: 'PDE',
    label: CMS_CLAIMS_TYPE_TEXTS['PDE'],
  },
  {
    value: 'SNF',
    label: CMS_CLAIMS_TYPE_TEXTS['SNF'],
  },
  {
    value: 'institutional',
    label: CMS_CLAIMS_TYPE_TEXTS['institutional'],
  },
  {
    value: 'pharmacy',
    label: CMS_CLAIMS_TYPE_TEXTS['pharmacy'],
  },
  {
    value: 'oral',
    label: CMS_CLAIMS_TYPE_TEXTS.oral,
  },
  {
    value: 'vision',
    label: CMS_CLAIMS_TYPE_TEXTS.vision,
  },
];

export const visitsStatusFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Planned', value: 'planned', icon: YellowIcon },
  { label: 'In progress', value: 'in-progress', icon: ActiveIcon },
  { label: 'Arrived', value: 'arrived', icon: CompletedIcon },
  { label: 'Finished', value: 'finished', icon: CompletedIcon },
  { label: 'Others', value: 'others', icon: GrayColorStatusIcon },
];

export const socialHistoryFilterMenuItem = [
  { label: 'All', value: 'all' },
  { label: 'Final', value: 'final', icon: CompletedIcon },
  { label: 'Others', value: 'others', icon: GrayColorStatusIcon },
];

export const secondOpinionTypeFilterMenuItems = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: 'draft' },
  { label: 'Current smoker', value: 'current-smoker' },
  { label: 'Former smoker', value: 'former-smoker' },
];

export const NO_CONSENT_FOUND_CONSENT_RECEIVED = Object.freeze({
  HEADING_TEXT: 'No consent received ',
  IMG_URL: consentNotFound,
  PARA_TEXT: `You have not given the consent to anyone yet.
              We request you to go to the received tab and accept 
              consent request.`,
});
export const NO_CONSENT_FOUND_CONSENT_GIVEN = Object.freeze({
  HEADING_TEXT: 'No consent found ',
  IMG_URL: consentNotFound,
  PARA_TEXT: `Currently, you don’t have any consents. We will notify you once you get any consent request.`,
});
export const NO_CONSENT_RECEIVED = Object.freeze({
  HEADING_TEXT: 'No consent found ',
  IMG_URL: consentNotFound,
  PARA_TEXT: `Initiate the consent request by adding the individual to your circle.`,
  BTN_TEXT: 'Add to circle',
});

export const NO_RELATED_PERSON_FOUND = (userEmail) =>
  Object.freeze({
    HEADING_TEXT: 'We’re sorry...',
    IMG_URL: consentNotFound,
    PARA_TEXT: `${userEmail} is not in your circle. 
  Send an invite to ${userEmail} and get referral bonus`,
    BTN_TEXT: 'Refer a friend',
  });

export const VITAL_TYPES = {
  BLOOD_PRESSURE: 'BLOOD_PRESSURE',
  BODY_TEMPERATURE: 'BODY_TEMPERATURE',
  HEART_RATE: 'HEART_RATE',
  BODY_HEIGHT: 'BODY_HEIGHT',
  BODY_WEIGHT: 'BODY_WEIGHT',
  RESPIRATORY_RATE: 'RESPIRATORY_RATE',
  BLOOD_PRESSURE_CHILDREN: 'BLOOD_PRESSURE',
  // BLOOD_OXYGEN_LEVEL: 'BLOOD_OXYGEN_LEVEL',
  OXYGEN_SATURATION: 'OXYGEN_SATURATION',
  VITAL_SIGNS_PANEL: 'VITAL_SIGNS_PANEL',
  HEAD_CIRCUMFERENCE: 'HEAD_CIRCUMFERENCE',
  BODY_MASS_INDEX: 'BODY_MASS_INDEX',
  SYSTOLIC_BLOOD_PRESSURE: 'SYSTOLIC_BLOOD_PRESSURE',
  DIASTOLIC_BLOOD_PRESSURE: 'DIASTOLIC_BLOOD_PRESSURE',
};

export const VITAL_TYPE_DROPDOWN_ITEMS = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: VITAL_TYPES.BODY_TEMPERATURE,
    label: 'Body temperature',
  },
  {
    value: VITAL_TYPES.BLOOD_PRESSURE,
    label: 'Blood pressure',
  },
  // {
  //   value: VITAL_TYPES.BLOOD_PRESSURE_CHILDREN,
  //   label: 'Blood pressure children',
  // },
  {
    value: VITAL_TYPES.HEART_RATE,
    label: 'Heart rate',
  },
  {
    value: VITAL_TYPES.BODY_HEIGHT,
    label: 'Height',
  },
  {
    value: VITAL_TYPES.BODY_WEIGHT,
    label: 'Weight',
  },
  {
    value: VITAL_TYPES.RESPIRATORY_RATE,
    label: 'Respiratory rate',
  },
  {
    value: VITAL_TYPES.OXYGEN_SATURATION,
    label: 'Oxygen saturation',
  },
  {
    value: VITAL_TYPES.VITAL_SIGNS_PANEL,
    label: 'Vital signs panel',
  },
  {
    value: VITAL_TYPES.HEAD_CIRCUMFERENCE,
    label: 'Head circumference',
  },
  {
    value: VITAL_TYPES.BODY_MASS_INDEX,
    label: 'Body mass index',
  },
  {
    value: VITAL_TYPES.SYSTOLIC_BLOOD_PRESSURE,
    label: 'Systolic blood pressure',
  },
  {
    value: VITAL_TYPES.DIASTOLIC_BLOOD_PRESSURE,
    label: 'Diastolic blood pressure',
  },
  {
    value: 'others',
    label: 'Others',
  },
];

export const VITAL_FHIR_CODES = {
  BODY_HEIGHT: '8302-2',
  BLOOD_PRESSURE: '55284-4',
  BODY_TEMPERATURE: '8310-5',
  BODY_WEIGHT: '29463-7',
  HEART_RATE: '8867-4',
  RESPIRATORY_RATE: '9279-1',
  BLOOD_PRESSURE_CHILDREN: '85354-9',
  // BLOOD_OXYGEN_LEVEL: '59408-5.',
  OXYGEN_SATURATION: '2708-6',
  VITAL_SIGNS_PANEL: '85353-1',
  HEAD_CIRCUMFERENCE: '9843-4',
  BODY_MASS_INDEX: '39156-5',
  SYSTOLIC_BLOOD_PRESSURE: '8480-6',
  DIASTOLIC_BLOOD_PRESSURE: '8462-4',
};

export const VITAL_REVERSE_LOOKUP_CODES = {
  [VITAL_FHIR_CODES.BODY_HEIGHT]: VITAL_TYPES.BODY_HEIGHT,
  [VITAL_FHIR_CODES.BLOOD_PRESSURE]: VITAL_TYPES.BLOOD_PRESSURE,
  [VITAL_FHIR_CODES.BODY_TEMPERATURE]: VITAL_TYPES.BODY_TEMPERATURE,
  [VITAL_FHIR_CODES.BODY_WEIGHT]: VITAL_TYPES.BODY_WEIGHT,
  [VITAL_FHIR_CODES.HEART_RATE]: VITAL_TYPES.HEART_RATE,
  [VITAL_FHIR_CODES.RESPIRATORY_RATE]: VITAL_TYPES.RESPIRATORY_RATE,
  [VITAL_FHIR_CODES.BLOOD_PRESSURE_CHILDREN]: VITAL_TYPES.BLOOD_PRESSURE_CHILDREN,
  // [VITAL_FHIR_CODES.BLOOD_OXYGEN_LEVEL]: VITAL_TYPES.BLOOD_OXYGEN_LEVEL,
  [VITAL_FHIR_CODES.OXYGEN_SATURATION]: VITAL_TYPES.OXYGEN_SATURATION,
  [VITAL_FHIR_CODES.VITAL_SIGNS_PANEL]: VITAL_TYPES.VITAL_SIGNS_PANEL,
  [VITAL_FHIR_CODES.HEAD_CIRCUMFERENCE]: VITAL_TYPES.HEAD_CIRCUMFERENCE,
  [VITAL_FHIR_CODES.BODY_MASS_INDEX]: VITAL_TYPES.BODY_MASS_INDEX,
  [VITAL_FHIR_CODES.SYSTOLIC_BLOOD_PRESSURE]: VITAL_TYPES.SYSTOLIC_BLOOD_PRESSURE,
  [VITAL_FHIR_CODES.DIASTOLIC_BLOOD_PRESSURE]: VITAL_TYPES.DIASTOLIC_BLOOD_PRESSURE,
};

export const VITALS_LABELS = {
  [VITAL_TYPES.BODY_HEIGHT]: 'Body height',
  [VITAL_TYPES.BLOOD_PRESSURE]: 'Blood pressure',
  [VITAL_TYPES.BODY_TEMPERATURE]: 'Body temperature',
  [VITAL_TYPES.BODY_WEIGHT]: 'Body weight',
  [VITAL_TYPES.HEART_RATE]: 'Heart rate',
  [VITAL_TYPES.RESPIRATORY_RATE]: 'Respiratory rate',
  [VITAL_TYPES.BLOOD_PRESSURE_CHILDREN]: 'Blood pressure',
  [VITAL_TYPES.OXYGEN_SATURATION]: 'Oxygen saturation',
  [VITAL_TYPES.VITAL_SIGNS_PANEL]: 'Vitals signs panel',
  [VITAL_TYPES.HEAD_CIRCUMFERENCE]: 'Head circumference',
  [VITAL_TYPES.BODY_MASS_INDEX]: 'Body mass index',
  [VITAL_TYPES.SYSTOLIC_BLOOD_PRESSURE]: 'Systolic blood pressure',
  [VITAL_TYPES.DIASTOLIC_BLOOD_PRESSURE]: 'Diastolic blood pressure',
  ALL_DATA: 'All data',
};

export const STATUS_LIST = {
  ALL: 'All',
  ACTIVE: 'Active',
  RECURRENCE: 'Recurrence',
  RELAPSED: 'Relapse',
  PROPOSED: 'Proposed',
  SUSPENDED: 'Suspended',
  INACTIVE: 'Inactive',
  REMISSION: 'Remission',
  RESOLVED: 'Resolved',
  CANCELLED: 'Cancelled',
  DRAFT: 'Draft',
  PENDING: 'Pending',
  ENTERED_ERROR: 'Entered in error',
  PREPRATION: 'Preparation',
  IN_PROGRESS: 'In progress',
  NOT_DONE: 'Not done',
  ON_HOLD: 'On hold',
  STOPPED: 'Stopped',
  COMPLETED: 'Completed',
  REGISTERED: 'Registered',
  PARTIAL: 'Partial',
  PRELIMINARY: 'Preliminary',
  FINAL: 'Final',
  AMMENDED: 'Amended',
  PLANNED: 'Planned',
  ARRIVED: 'Arrived',
  TRIAGED: 'Triaged',
  ON_LEAVE: 'On Leave',
  FINSISHED: 'Finished',
  UNKNOWN: 'Unknown',
  OTHERS: 'Others',
  DUE: 'Due',
  PAST_DUE: 'Past Due',
  PAID: 'Paid',
  ISSUED: 'Issued',
  BALANCED: 'Balanced',
  CORRECTED: 'Corrected',
  SUCCESSFUL: 'Successful',
  UNSUCCESSFUL: 'Unsuccessful',
  PARTIALLY_SUCCESSFUL: 'Partially Successful',
  APPENDED: 'Appended',
  PREPARATION: 'Preparation',
  NORMAL: 'Normal',
  LOW: 'Low',
  HIGH: 'High',
  CONFIRMED: 'Confirmed',
  DECLINED: 'Declined',
  REVOKED: 'Revoked',
  TERMINATED: 'Terminated',
  CONSENT_ACTIVE: 'Active',
  CONSENT_PENDING: 'Pending',
  CONSENT_REQUESTED: 'Requested',
  NOT_INITIATED: 'Not initiated',
  UNCONFIRMED: 'Unconfirmed',
  REFUTED: 'Refuted',
  EXPIRED: 'Expired',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export const STATUS_COLOR_LIST = [
  {
    key: STATUS_LIST.APPROVED,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.REJECTED,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.ACTIVE,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.PROPOSED,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.SUSPENDED,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.RECURRENCE,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.RELAPSED,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.INACTIVE,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.EXPIRED,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.REMISSION,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.RESOLVED,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.NORMAL,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.CANCELLED,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.LOW,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.HIGH,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.DRAFT,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.PENDING,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.ENTERED_ERROR,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.PREPRATION,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.IN_PROGRESS,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.NOT_DONE,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.ON_HOLD,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.STOPPED,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.COMPLETED,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.REGISTERED,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.PARTIAL,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.PRELIMINARY,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.FINAL,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.AMMENDED,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.PLANNED,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.ARRIVED,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.ON_LEAVE,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.FINSISHED,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.TRIAGED,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.OTHERS,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.DUE,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.PAST_DUE,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.PAID,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.ISSUED,
    color: colors.BLUE,
    value: STATUS_LIST.GENERATED,
  },
  {
    key: STATUS_LIST.BALANCED,
    color: colors.GREEN,
    value: STATUS_LIST.PAID,
  },
  {
    key: STATUS_LIST.UNKNOWN,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.UNSUCCESSFUL,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.PARTIALLY_SUCCESSFUL,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.CORRECTED,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.SUCCESSFUL,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.APPENDED,
    color: colors.GREEN,
  },
  {
    key: STATUS_LIST.CONFIRMED,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.DECLINED,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.REVOKED,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.TERMINATED,
    color: colors.RED,
  },
  {
    key: STATUS_LIST.CONSENT_ACTIVE,
    color: colors.BLUE,
  },
  {
    key: STATUS_LIST.CONSENT_PENDING,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.CONSENT_REQUESTED,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.CONSENT_REQUESTED,
    color: colors.ORANGE,
  },
  {
    key: STATUS_LIST.NOT_INITIATED,
    color: colors.GRAY_LIGHT,
  },
  {
    key: STATUS_LIST.REFUTED,
    color: colors.GREY,
  },
  {
    key: STATUS_LIST.UNCONFIRMED,
    color: colors.GREY,
  },
];

export const VISIT_TYPES = [
  { label: 'All', value: 'all' },
  { label: 'AMB', value: 'ambulatory' },
  { label: 'EMER', value: 'emergency' },
  { label: 'FLD', value: 'field' },
  { label: 'HH', value: 'home health' },
  { label: 'IMP', value: 'inpatient encounter' },
  { label: 'ACUTE', value: 'inpatient acute' },
  { label: 'NONAC', value: 'inpatient non-acute' },
  { label: 'OBSENC', value: 'observation encounter' },
  { label: 'PRENC', value: 'pre-admission' },
  { label: 'SS', value: 'short stay' },
  { label: 'VR', value: 'virtual' },
];

export const CIRCLE_NO_DATA_CARD_TEXT = "Sorry! We couldn't find any records from";

export const ERROR_DATA = Object.freeze({
  TITLE: 'Select organization',
  HEADING_TEXT: 'We’re Sorry...',
  IMG_URL: ErrorAtAddingPartner,
  PARA_TEXT:
    'An error occured while adding your partner. Please reload the page or come back in some time.',
  BTN_TEXT: 'Reload',
});

export const CATEGORIES_FAILED_TO_LOAD = (partnerName) => {
  return {
    HEADING_TEXT: 'Failed to load data!',
    IMG_URL: ErrorAtAddingPartner,
    PARA_TEXT: `Sorry, we are not able to load data in ${partnerName} at this moment. We request you to reload the page or come back in some time.`,
    BTN_TEXT: 'Reload data',
  };
};

export const NO_PARTNER_LEFT = Object.freeze({
  TITLE: 'Select organization',
  HEADING_TEXT: 'No partner left to add!',
  IMG_URL: NoPartnerLeft,
  PARA_TEXT: 'You have already added all the available partners in your circle.',
  BTN_TEXT: '',
});
export const DID_NOT_FIND_PARTNER = Object.freeze({
  TITLE: 'Select organization',
  HEADING_TEXT: 'Did not find the organization looking for?',
  IMG_URL: partnerNotFound,
  PARA_TEXT: '',
  BTN_TEXT: '',
  LINK_TEXT: 'Report here',
  PARA2_TEXT: 'Your feedback is valuable and helps us to provide better solutions to you.',
  LINK: 'https://mpoweredhealth.ideas.aha.io/ideas/new',
});
export const PARTNER_CARD_NO_PARTNAR_FOUND = Object.freeze({
  HEADING_TEXT: 'No organizations found! ',
  PARA_TEXT: 'No matching organizations found!',
  IMG_URL: AddPartnerImage,
  BTN_TEXT: 'Add to circle ',
});
export const MARKET_CATEGORY_CARD_NO_PARTNAR_FOUND = Object.freeze({
  HEADING_TEXT: 'Partner not found',
  IMG_URL: AddPartnerImage,
  PARA_TEXT: 'We did not find a partner matching your search, try searching different name',
});
export const NO_INDIVIDUAL_IN_CIRCLE = Object.freeze({
  HEADING_TEXT: 'No individual added ',
  IMG_URL: NoIndividual,
  PARA_TEXT: 'Please click on the below button to add an individual to my circle',
  BTN_TEXT: 'Add individual ',
});
export const NO_INDIVIDUAL_IN_GIFT = Object.freeze({
  HEADING_TEXT: 'No individual added ',
  IMG_URL: NoIndividual,
  PARA_TEXT: 'Please add an individual to gift the award points',
  BTN_TEXT: 'Add individual ',
});
export const PARTNER_CARD_NO_PARTNAR_ADDED = Object.freeze({
  HEADING_TEXT: 'No organizations added',
  IMG_URL: AddPartnerImage,
  PARA_TEXT: 'Please click on the below button to add an organization to my circle',
  BTN_TEXT: 'Add organization',
});
export const HISTORICAL_RECORDS_NOT_FOUND = Object.freeze({
  HEADING_TEXT: 'No historical records found',
  IMG_URL: AddPartnerImage,
  PARA_TEXT: 'No matching historical records found!',
});
export const PARTNER_CARD_NO_INTERNET_CONNECTION = Object.freeze({
  HEADING_TEXT: 'No internet connection.',
  IMG_URL: NoInternetConnectionImage,
  PARA_TEXT:
    'Sorry, we are not able to load organizations at this moment. Please check your network connection.',
  BTN_TEXT: 'Reload',
});
export const PARTNER_CARD_FAILED_TO_LOAD = Object.freeze({
  HEADING_TEXT: 'Failed to load data!',
  IMG_URL: FailedToLoadImage,
  PARA_TEXT:
    'Sorry, we are not able to load data in My Medicare at this moment. We request you to reload the page or come back in some time.',
  BTN_TEXT: 'Reload data',
});

export const POPUP_MODAL_FAILED_TO_LOAD = Object.freeze({
  HEADING_TEXT: 'Failed to load partners!',
  IMG_URL: FailedToLoadIcon,
  PARA_TEXT:
    'Sorry, we are not able to load partners at this moment. Please reload the page or come back in some time.',
  BTN_TEXT: 'Reload ',
});

export const ACTIVE_CIRCLE_LOADER_KEY = 'ACTIVE_CIRCLE_LOADER_KEY';

export const PARTNER_LOADER_CARD = {
  IMG_URL: FileGif,
  TITLE: 'Data loading...',
  TEXT_CONTENT: 'It might take 3-5 minutes for us to load your records.',
};

export const TOAST_TEXT_MESSAGE = {
  OTP_SENT: 'Verification code sent',
  OTP_RESENT: 'Verification code resent',
  OTP_ERROR: 'Failed to generate OTP! Please try again',
  SOMETHING_ERROR: 'Something went wrong, please try again!',
  SELECT_FILE: 'Please select a file less than 10MB',
};

export const TOAST_MESSAGES = {
  CHANGE_SUCCESS: 'Your changes have been successfully applied',
  UPDATING_WAIT: (type = 'Changes') => `Updating ${type}, please wait...`,
  ADDING_WAIT: (type = 'Changes') => `Adding ${type}, please wait...`,
};

export const POPUP_MODAL_SUCCESS_ADD_DATA = Object.freeze({
  HEADING_TEXT: 'Congratulations! ',
  IMG_URL: SuccessIcon,
  PARA_TEXT: (resourceName = 'resource', category = '') => {
    return `Your ${resourceName} has been successfully added. You can see
   the details by clicking on the ${resourceName} in the ${
      category !== '' ? category : resourceName === 'coverage' ? 'financial' : 'medical'
    } services.`;
  },
  BTN_TEXT: 'View details',
});

export const SELF_REPORTING_CONFIRMATION_POPUP_TEXT = Object.freeze({
  HEADING_TEXT: 'Are you sure you want to save the changes?',
  CANCEL_BUTTON_TEXT: 'No',
  CONFIRMATION_BUTTON_TEXT: 'Yes',
});

export const SELF_REPORTED_MAX_FILE_SIZE_IN_BYTES = 10000000;

export const FILE_UPLOAD_COMPONENT_TEXTS = Object.freeze({
  HEADING_TEXT: 'Document',
  FILE_FORMATS_ALLOWED: ['JPG', 'PNG', 'PDF', 'JPEG'],
  ACCEPTING_FILES_FOR_UPLOAD: '.Jpg,.png,.pdf,.JPEG,.JPG,.PNG,.PDF',
  CHOOSE_FILE_CONTAINER_TEXT: {
    HEADING: `JPG, PNG, or PDF smaller than ${parseInt(
      SELF_REPORTED_MAX_FILE_SIZE_IN_BYTES / 1000000,
    )}MB`,
    SUB_HEADING: 'Drag and drop your files here or',
    CHOOSE_FILE_BUTTON_TEXT: 'Choose file',
  },
  FILE_DROPER_TEXTS: {
    HEADING: 'Drag file here',
  },
  OTHER_FILE_SELECTION_TEXTS: {
    DELETE_BUTTON_TEXT: 'Delete',
    CHOOSE_ANOTHER_FILE_TEXT: 'Choose another file',
  },
  FAILED_FILE_UPLOADCARD_TEXTS: {
    HEADING: 'Upload failed',
    SUB_HEADING: 'File size is more than 10 MB',
    CHOOSE_ANOTHER_FILE_BUTTON_TEXT: 'Choose another file',
  },
  MAX_FILE_SIZE_ERROR: `File size cannot more than ${parseInt(
    SELF_REPORTED_MAX_FILE_SIZE_IN_BYTES / 1000000,
  )}MB`,
  UNSUPPORTED_FILE_TYPE: 'Unsupported file type',
});

export const SELF_REPORTED_S3_BUCKET_URL = process.env.SELF_REPORTED_DOCUMENT_BUCKET_URL;

export const FAVOURITES_KEY_NAMES = {
  condition: 'Conditions',
  procedure: 'Procedures',
  'lab result': 'Lab results',
  medication: 'Medications',
  allergy: 'Allergies',
  immunization: 'Immunizations',
  //vitals: 'Vitals',
  visits: 'Visits',
  'social history': 'Social history',
  'family history': 'Family history',
  'care team': 'Care team',
  'care plan': 'Care plan',
  // claim: 'Claim',
  assessments: 'Assessments',
  appointments: 'Appointments',
  // documents: 'Documents',
  // coverage: 'Coverage',
};

export const SELF_REPORTING_TEXTS = {
  CONDITION: 'Conditions',
  MEDICATION: 'Medication',
  PROCEDURE: 'Procedure',
  IMMUNIZATION: 'Immunization',
  CANCEL: 'Cancel',
  ALLERGY: 'Allergy',
  LAB_RESULT: 'Lab result',
  COVERAGE: 'Coverage',
  ADD_DATA: 'Add data',
  EDIT: 'Edit',
  CHANGES_APPLIED: 'Your changes have been saved',
  SAVE_CHANGES: 'Save changes',
  CONFIRM_SAVE: 'Are you sure you want to save these changes?',
  CONGRATS: 'Congratulations! ',
  LOADING_HISTORICAL_RECORDS: 'Loading historical records, please wait',
  PLEASE_WAIT: 'Please wait',
  SAVING_DOCUMENTS_LOADER_TEXT: 'Saving documents...',
  REQUIRED_FIELDS_VALUES_MISSING: 'Please fill in all the required fields',
  ADD_SUCCESS: (type) =>
    `Your ${
      type || 'record'
    } has been successfully added. You can see the details on main page of ${type || 'record'}s.`,
  ADD_DATA_TYPES: {
    MANUAL: 'MANUAL',
    SCAN: 'SCAN',
  },
  SCAN: 'Scan the document',
  MANUAL: 'Manually fill the form',
  SCAN_DESC: 'Scan the document to auto-populate info.',
  MANUAL_DESC: 'Enter the information manually in the fields.',
  VIEW_DETAILS: 'View details',
  SUBMIT: 'Submit',
  SAVE: 'Save',
  SAVE_CONTINUE: 'Save & continue',
  YES: 'Yes',
  CONTINUE: 'Continue',
  SAVE_CHANGES: 'Save changes',
  NO: 'No',
  BACK: 'Back',
  CHOOSE_FILE: 'Choose file',
  CHOOSE_ANOTHER_FILE: 'Choose another file',
  DELETE: 'Delete',
  UPLOADING: 'Uploading...',
  UPLOAD_FAILED: 'Upload failed!',
  HISTORICAL_RECORDS: 'Historical records',
  UPLOAD_ERROR: (value) => `File size greater than ${value}`,
  INPUT_TYPE: {
    TEXT: 'text',
    DROPDOWN: 'dropdown',
    FILE: 'file',
    MULTILINE: 'multiline',
    DATE: 'date',
    TIME: 'time',
    TEXT_AUTOCOMPLETE: 'text-autocomplete',
    HEADER: 'HEADER',
    MULTIPLE_INPUT: 'multiple',
  },
  MARK_RESTRICTED: 'Mark restricted',
  MARK_RESTRICTED_DESC: (type) =>
    `Mark the ${
      type || 'record'
    } as restricted if you want to hide it from sharing with your circle of family, providers etc.`,
  NAME_PLACEHOLDER: (type) => `Enter ${type ? `${type} ` : ''}name`,
  NAME_OF_PLACEHOLDER: (type) => `Name of ${type || 'record'}`,
  ENTER_PLACEHOLDER: (type) => `Enter ${type || ''}`,
  ENTER_NAME_PLACEHOLDER: (type) => `Enter name of ${type || 'record'}`,
  CHOOSE_PLACEHOLDER: (type) => `Select ${type || ''}`,
  DATE_PLACEHOLDER: 'MM / DD / YYYY',
  TIME_PLACEHOLDER: 'HH : MM',
  RECORDED_BY_PLACEHOLDER: 'Recorded by e.g. John Robertson',
  ADMINISTERED_BY_PLACEHOLDER: 'Administered by e.g. John Robertson',
  RECORDED_BY_PLACEHOLDER_TYPE2: 'Enter name e.g., Kerry, John',
  FACILITY_PLACEHOLDER: 'Facility name, e.g. Mayo Clinic',
  ADD_PLACEHOLDER: (type) => `Add ${type || ''}`,
  COMMENT_PLACEHOLDER: 'eg., Take 30mins before eating',
  DOCUMENT_PLACEHOLDER: (fileTypes, size) =>
    `${fileTypes ? `${fileTypes} ` : 'JPG, PNG, or PDF '}smaller than ${size || '10MB'}`,
  REQUIRED_ERROR_MESSAGE: 'This field is a required field!',
  START_DATE_AFTER_END_DATE_ERROR:
    'This is a required field. Start date cannot be after the end date',
  END_DATE_BEFORE_START_DATE_ERROR:
    'This is a required field. End date cannot be before the start date',
  INVALID_PHONE_NUMBER_ERROR: 'Please enter a valid 10 digit phone number',
  INVALID_AMOUNT_ERROR: 'Please enter a valid amount',
  ADD_TO_FAVORITES: 'Add to favorites',
  FAVORITE_DESC: 'Favorite appear in summary',
  HISTORICAL_RECORDS: 'Historical records',
  NUM_OF_PILLS_ERROR: 'Please provide a valid number between 1-5!',
  REMIND_ME: 'Remind me',
  REACTIONS_PLACEHOLDER: 'Add reactions of the allergy',
  RECORD_SAVED_DESCRIPTION: (type = 'record') => `Saved ${type} successfully`,
  ERROR_DESCRIPTION: (type) =>
    `An error occured while saving your ${
      type ? `${type} ` : ''
    } record. Please retry or come back in some time.`,
  LOADING_DESCRIPTION: (type) => `Saving ${type ? `${type} ` : ''} record, please wait.`,
  RELATED_TO: (name, type) => `${name} related to ${type}`,
};

export const SELF_REPORTING_LABELS = {
  NAME: (type) => `${type ? `${type} ` : ''}name`,
  NAME_OF: (type) => `Name of ${type || 'record'}`,
  FACILITY: 'Facility',
  CATEGORY: 'Category',
  STATUS: 'Status',
  CLINICAL_STATUS: 'Clinical status',
  SUMMARY: 'Summary',
  SEVERITY: 'Severity',
  CODE: 'Code',
  RECORDED_ON: 'Recorded on',
  RECORDED_BY: 'Recorded by',
  NOTE: 'Note',
  LAB_RESULT_LABEL: 'Lab result',
  LAB_RESULT_PLACEHOLDER: 'Enter lab result',
  DOCUMENT: 'Documents',
  TYPE_OF: (type) => `Type of ${type || 'record'}`,
  FREQUENCY: 'Frequency',
  START_DATE: 'Start date',
  CONTACT_DETAILS: 'Contact details',
  CONTACT_NUMBER: 'Contact number',
  END_DATE: 'End date',
  DOSAGE: 'Dosage',
  ADMINISTERED_ON: 'Administered on',
  ADMINISTERED_BY: 'Administered by',
  PRESCRIBER: 'Prescriber',
  DOSE: (count) => `Dose ${count || ''}`,
  NUM_OF_PILLS: 'Number of pills',
  TIME: 'time',
  ADD: (type) => `Add ${type || ''}`,
  COMMENT: 'Comment',
  OUTCOME: 'Outcome',
  DRAINS: 'Drains',
  COMPLICATIONS: 'Complications',
  SPECIMEN: 'Specimen',
  DISPOSITION: 'Disposition',
  FINDINGS: 'Findings',
  ALLERGIC_TO: 'Allergic to',
  CRITICALITY: 'Criticality',
  ROUTE: 'Route',
  REACTIONS: 'Reactions',
  REASON_CODE: 'Reason code',
  COMPLICATIONS: 'Complications',
  FOLLOW_UP: 'Follow up',
  BODY_SITE: 'Body site',
  DOSES: 'Doses',
  VACCINE_DOSE_NUMBER: 'Vaccine dose number',
  VACCINE_MANUFACTURER: 'Vaccine manufacturer',
  VACCINE_LOT_NUMBER: 'Vaccine lot number',
  VACCINE_EXPIRATION_DATE: 'Vaccine expiration date',
  NDC_CODE: 'NDC code',
  REASONS: 'Reasons',
  REASON: 'Reason',
  INGREDIENTS: 'Ingredients',
  MANUFACTURER: 'Manufacturer',
  TEST_NAME: 'Test name',
  VALUE: 'Value',
  UNITS: 'Units',
  REFERENCE_RANGE: 'Reference range',
  CATEGORY_CODE: 'Category code',
  LOINC_CODE: 'LOINC code',
  ISSUED_DATE: 'Issued date',
  ISSUED_TIME: 'Issued time',
  ISSUER_OF_REPORT: 'Issuer of the report',
  IMMUNIZATION_NAME: 'Immunization name',
  POLICY_NUMBER: 'Policy number',
  SUBSCRIBER_ID: 'Subscriber id',
  MEMBER_ID: 'Member id',
  PlAN_TYPE: 'Plan type',
  PLAN_NAME: 'Plan name',
  COVERAGE_TYPE: 'Coverage type',
  TOTAL_AMOUNT: 'Total amount',
  CONTACT_NAME: 'Contact name',
  CONTACT_PHONE_NUMBER: 'Contact phone number',
  ISSUER_ORGANIZATION: 'Issuer organization',
  CONTACT_ADDRESS: 'Contact address',
  COMMENT: 'Comment',
  RELATIONSHIP: 'Relationship',
  SUBSCRIBER_NAME: 'Subscriber name',
  PLAN_ID: 'Plan id',
  START_TIME: 'Start time',
  INTENT: 'Intent',
  DESCRIPTION: 'Description',
  GENDER: 'Gender',
};

export const SELF_REPORTING_STEPPER_TEXTS = {
  CONDITIONS: ['Add data', 'Documents'],
  PROCEDURES: ['Add data', 'Documents'],
  IMMUNIZATIONS: ['Add data', 'Documents'],
  ALLERGIES: ['Add data', 'Documents'],
  MEDICATIONS: ['Add data', 'Documents'],
  LAB_RESULTS: ['Add data', 'Documents'],
  COVERAGE: ['Add data', 'Documents'],
};
export const ADD_INDIVIDUAL_RELATIONSHIP = [
  {
    value: 'Son',
    label: 'Son',
  },
  {
    value: 'Daughter',
    label: 'Daughter',
  },
  {
    value: 'Wife',
    label: 'Wife',
  },
  {
    value: 'Father',
    label: 'Father',
  },
  {
    value: 'Mother',
    label: 'Mother',
  },
  {
    value: 'Brother',
    label: 'Brother',
  },
];

export const CDN_URL_FOR_TEMPLATE = `https://d2fd7p9d9ashnj.cloudfront.net/services/dynamic_records.json`;
export const ERROR_MSG_FOR_TEMPLATE_LOADING_FAILURE = `Error from CDN during fetching dynamic templates :`;

export const SELF_REPORTING_DATA_SOURCE_LABELS = ['self-reporting', 'self reported'];

export const SEARCH_DEBOUNCE_TIME = 2000;
export const NUMBER_OF_RECORDS_PER_PAGE = 10; // for all categories by default

export const CONDITION_CLINICAL_STATUS_LIST = [
  'active',
  'recurrence',
  'relapse',
  'inactive',
  'remission',
  'resolved',
];

export const CLAIMS_STATUS_LIST = ['entered-in-error'];

export const PROCEDURE_STATUS_LIST = [
  'preparation',
  'in-progress',
  'not-done',
  'on-hold',
  'stopped',
  'completed',
  'entered-in-error',
  'unknown',
];

export const ALLERGY_STATUS_LIST = [
  'active',
  'inactive',
  'resolved',
  'unconfirmed',
  'confirmed',
  'refuted',
  'entered-in-error',
  'unknown',
];
export const BILLS_STATUS_LIST = ['draft', 'issued', 'balanced', 'cancelled', 'entered-in-error'];

export const IMMUNIZATION_STATUS_LIST = ['completed', 'entered-in-error', 'not-done'];
export const SOCILA_HISTORY_STATUS_LIST = [
  'final',
  'entered-in-error,registered,preliminary,amended,corrected,cancelled',
];

export const LAB_RESULT_STATUS_LIST = [
  'registered',
  'partial',
  'preliminary',
  'final',
  'amended',
  'appended',
  'corrected',
  'cancelled',
  'entered-in-error',
  'unknown',
];

export const VISITS_STATUS_LIST = [
  'triaged',
  'in-progress',
  'onleave',
  'cancelled',
  'entered-in-error',
  'unknown',
];

export const MEDICATION_STATUS_LIST = ['on-hold', 'entered-in-error', 'draft', 'unknown'];

export const NO_CONSENT_FOUND = Object.freeze({
  HEADING_TEXT: 'No consent given ',
  IMG_URL: consentNotFound,
  PARA_TEXT: `You have not given the consent to anyone yet.
              We request you to go to the received tab and accept 
              consent request.`,
});

export const FLYOUT_CONTENT_FOR_DECLINED_INACTIVE_REVOKED_STATUS = (
  { status, name: { Name } },
  from,
) =>
  `The consent ${from !== 'received' ? 'given to' : 'received from'} ${Name} has been ${
    status && status.toLowerCase()
  }. We request you to ask ${Name} to send the consent request again in case you want to give the consent to ${Name} to view your records`;

export const CONTENT_FOR_EXPIRED_STATUS = ({ status, name: { Name } }, from) =>
  `The consent ${from !== 'received' ? 'given to' : 'received from'} ${Name} has been ${
    (status && status.toLocaleLowerCase()) || '-'
  }. We request you to ${
    from !== 'received' ? `ask ${Name} to ` : ''
  }send the consent request again in order to view ${
    from !== 'received' ? 'your' : `${Name.trim() || '-'}'s`
  } records.`;

export const CONTENT_FOR_ONE_TIME_EXPIRED_STATUS = ({ status, name: { Name } }, from) =>
  `The consent ${from !== 'received' ? 'given to' : 'received from'} ${Name} has been ${
    (status && status.toLocaleLowerCase()) || '-'
  }.`;

export const CONTENT_FOR_CANCELLED_STATUS = ({ status, name: { Name } }, from) =>
  `The consent request you ${
    from !== 'received' ? 'received from' : 'have sent to'
  } ${Name} has been ${(status && status.toLocaleLowerCase()) || '-'}. We request you to ${
    from !== 'received' ? `ask ${Name} to ` : ''
  }send the consent request again in order to view ${
    from !== 'received' ? 'your' : `${Name.trim() || '-'}'s`
  } records.`;

export const CONTENT_FOR_ONE_TIME_CANCELLED_STATUS = ({ status, name: { Name } }, from) =>
  `The consent request you ${
    from !== 'received' ? 'received from' : 'have sent to'
  } ${Name} has been ${(status && status.toLocaleLowerCase()) || '-'}.`;

export const USER_NOT_EXIST_POPUP_CONTENT = `This user is not part of the Mpowered Health platform. Please send App invite to add the
individual in your circle.`;

export const USER_NOT_EXIST_POPUP_HEADING = `User does not exist`;
export const USER_NOT_EXIST_POPUP_BUTTON_TEXT = `Send invite`;
export const SUB_HEADING_IN_ADD_INDIVIDUAL_MODAL = `What do you want to add to your circle?`;
export const HEADING_IN_ADD_INDIVIDUAL_MODAL = `Add to circle`;
export const LINK_EXPIRES = 'Link expires on';
export const VITALS_DATA = {
  NO_DATA_FOUND: 'No data found',
  NO_DATA: 'No data...',
  ALL_DATA: 'All data',
};

export const INVITATION_CONTENT = {
  HEADING: 'Consent pending',
  CONTENT: `Your request has been sent. You will be able to access the records once your request has been accepted.`,
};

export const NO_ACCESS_CARD = {
  HEADING: `You don’t have access to the records`,
  CONTENT: `Initiate consent request and get the access`,
  BTN_TEXT: `Initiate consent request`,
};

export const ERROR_STATE_FOR_MOBILE_NUMBER = {
  INVALID_MOBILE_NUMBER: {
    lookup: 'Invalid phone number',
    Label: 'Please enter a valid 10 digit mobile number!',
  },
  MOBILE_NUMBER_ALREADY_EXISTS: {
    lookup: 'User exists with same username or email',
    Label: 'Mobile number already exists',
  },
  RANDOM_ERROR_EXISTS: {
    lookup: '',
    Label: 'Failed to add phone number, please try again...',
  },
};

export const ADD_DOCUMENT = 'Add document';

export const ADD_DATA = 'Add data';
export const EDIT_DATA = 'Edit data';
export const D_SOURCE = 'Data source';
export const SEARCH_HERE = 'Search here';
export const IN_MY_CARE = {
  REQUEST_CONSENT: 'Request consent',
  DOB: 'DOB',
  CONSENT_STATUS: 'Consent status',
};

export const OPTIONS_VIEW = {
  HISTORICAL_RECORDS: 'Historical records',
  OPTIONS: 'Options',
};

export const NOT_FOUND_PAGE = {
  TITLE: (title) => `No ${title.toLowerCase()} found`,
  PARA: (title) => `We couldn't find any ${title.toLowerCase()} in your records.`,
};
export const NO_CATEGORY_CARD = {
  NO_RECORDS_FOUND: 'No records found',
  NOT_FOUND: (title) => `Sorry! We couldn't find any records from ${title.toLowerCase()}`,
};

export const LOG_IN = {
  ONE_APP:
    'One app to help you consolidate all your health records, track your medications, manage your healthcare expenses, shop for plans and more..',
  WELCOME_BACK: 'Welcome back!',
  LOGIN: 'Log in',
  EMAIL_PHONE: 'Email/mobile number',
  EMAIL_PHONE_REQUIRED_ERROR: 'Email/mobile number is required',
  PASSWORD_REQUIRED_ERROR: 'Password is required',
  EMAIL_PHONE_PLACEHOLDER: 'Please enter your email or mobile number',
  PASSWORD: 'Password',
  PASSWORD_PLACEHOLDER: 'Password (8+ characters)',
  FORGOT_PASS: 'Forgot password?',
  NOT_HAVE_ACCOUNT: 'Don’t have an account?',
  SIGN_UP: 'Sign up',
  OR: 'or',
  DOWNLOAD: 'Download Mpowered Health for free on ',
  IOS: 'iOS',
  AND: ' and ',
  ANDROID: 'Android',
  LOADING_TEXT: 'Logging in...',
  INVALID_CREDS_ERROR_MESSAGE: 'Invalid user credentials',
  ACCOUNT_DOES_NOT_EXISTS: 'This account does not exists. Create a new account.',
  ACCOUNT_DISABLED:
    'Account is disabled. Please contact support@mpoweredhealth.com to enable the account.',
  ALL_YOUR: 'All your',
  HEALTHCARE: 'healthcare',
  IN_ONE_APP: 'in one app',
  ACCOUNT_CREATED_SUCCESSFULLY: 'Account created successfully! Please login to continue.',
};

export const SIGNUP = {
  ONE_APP:
    ' One app to help you consolidate all your health records, track your medications, manage your healthcare expenses, shop for plans and more..',
  WELCOME: 'Welcome!',
  CREATE_ACCOUNT: 'Create my account',
  EMAIL: 'Email',
  EMAIL_PLACEHOLDER: 'abc@gmail.com',
  PASSWORD: 'Password',
  PASSWORD_PLACEHOLDER: 'Password (8+ characters)',
  CONFIRM_PASSWORD: 'Confirm password',
  CHANGE_PASSWORD: 'Change password',
  REFERRAL: 'Have a referral/promo code? (Optional)',
  HAVE_ACCOUNT: 'Have an account?',
  LOGIN: 'Log in',
  OR: ' or ',
  DOWNLOAD: 'Download Mpowered Health for free on ',
  IOS: 'iOS',
  AND: ' and ',
  ANDROID: 'Android',
};

export const SIGN_UP_DETAILS = {
  WELCOME: 'Welcome! Please add a few details about yourself',
  FIRST_NAME: FORM_LABEL.FIRST_NAME,
  FIRST_NAME_PLACEHOLDER: 'John',
  LAST_NAME: FORM_LABEL.LAST_NAME,
  LAST_NAME_PLACEHOLDER: 'Dohe',
  PHONE_NUMBER: 'Mobile number',
  PHONE_NUMBER_PLACEHOLDER: 'Enter a valid mobile number',
  CONFIRM_OTP: `We'll send an OTP to confirm your number`,
  REFERRAL: 'Have a referral/promo code? (Optional)',
  READ_AGREE: 'By creating an account you are accepting the ',
  TERMS: 'terms of service',
  AND: ' and ',
  POLICY: 'privacy policy',
  CREATE_ACCOUNT: 'Create account',
  PROCEED: 'Proceed',
};

export const OTP_VERIFICATION_MODAL = {
  ENTER_OTP: 'Please enter OTP',
  FOUR_DIGIT_CODE: 'Enter a 4 digit code sent to ',
  BTN_TEXT: 'Change contact number',
  DID_NOT: `Didn't receive the code?`,
  NEW_CODE: 'Request new code in 00:',
  RESEND_OTP: 'Resend OTP',
  EDIT_PHONE_NUMBER: 'Edit phone number',
  PHONE_NUMBER: 'Phone number',
  NEW_PASSWORD: 'Create new password',
  PASSWORD: 'Password',
};

export const FORGOT_PSWD = {
  ONE_APP:
    'One app to help you consolidate all your health records, track your medications, manage your healthcare expenses, shop for plans and more..',
  FORGOT_PSWD: 'Forgot password',
  REGISTERED_EMAIL_PHONE: 'Please enter your registered email or mobile number',
  INVALID: 'Enter a valid mobile number or email',
  MOBILE_OR_EMAIL_REQUIRED: 'Mobile number or email required',
  CONFIRM_OTP: `We'll send an OTP to confirm your email or mobile number`,
  BUTTON_TEXT: 'Send OTP',
  CHANGE_PASSWORD: 'Change password',
  SOMETHING_ERROR: 'Something went wrong while updating password.',
  OTP_NOT_MATCH: 'OTP does not match',
  OTP_SENT: 'Verification code sent',
  OTP_RESENT: 'Verification code resent',
};

export const CONSENT_GIVEN_TEXT = {
  CONFIRMATION_MODAL_HEADING: 'Revoke Access',
  CONFIRMATION_MODAL_BTN_TEXT: 'Revoke Access',
  CONFIRMATION_MODAL_SUBHEADING: (name) =>
    `Are you sure you want to Revoke access for viewing record to ${name}`,
};

export const STATUS_LIST_FOR_CONSENT = {
  CANCEL_CASE: ['Expired', 'EXPIRED', 'Cancelled', , 'CANCELLED', 'Terminated', 'TERMINATED'],
  ACTIVE_CASE: ['ACTIVE', 'active'],
  PENDING_CASE: ['pending', 'Pending'],
};

export const CARE_PLAN = {
  WORDINGS: {
    TITLE: 'Care plans',
    NO_RECORDS: 'care plans',
    LOADING_CARE_PLANS: 'Loading care plans',
    LOADING_DETAILS: 'Loading care plan details',
    DETAILS_MODAL: {
      SECTIONS: [
        { TITLE: 'Problem', PROP_KEY: 'problems' },
        { TITLE: 'Goals', PROP_KEY: 'goals' },
        { TITLE: 'Activities', PROP_KEY: 'activities' },
      ],
    },
  },
  FILTER_ITEMS: {
    STATUS: [
      { label: 'All', value: 'all' },
      { label: 'Active', value: 'active', icon: ActiveIcon },
      { label: 'Completed', value: 'completed', icon: CompletedIcon },
      { label: 'Revoked', value: 'revoked', icon: RejectIcon },
      { label: 'On hold', value: 'on-hold', icon: YellowIcon },
      { label: 'Entered in error', value: 'entered-in-error', icon: GrayColorStatusIcon },
      { label: 'Draft', value: 'draft', icon: YellowIcon },
    ],
  },
};

export const CARE_TEAM = {
  WORDINGS: {
    CARE_TEAM: 'Care Team',
    TITLE: 'Care teams',
    PLACEHOLDER: 'Search by category',
    LOADING_CARE_TEAMS: 'Loading care teams',
    LOADING_DETAILS: 'Loading care team details',
    FILTER_TYPE: 'Status:',
    NO_RECORDS: 'care teams',
    VIEW_DETAILS: 'View details',
    READ_LESS: '...Read less',
    READ_MORE: '...Read more',
    DETAILS_MODAL: {
      SECTIONS: [
        { TITLE: 'Problem', PROP_KEY: 'problems' },
        { TITLE: 'Goals', PROP_KEY: 'goals' },
        { TITLE: 'Activities', PROP_KEY: 'activities' },
      ],
    },
  },
  FILTER_ITEMS: {
    STATUS: [
      { label: 'All', value: 'all' },
      { label: 'Active', value: 'active', icon: ActiveIcon },
      { label: 'Entered in error', value: 'entered-in-error', icon: GrayColorStatusIcon },
      { label: 'Inactive', value: 'inactive', icon: RejectIcon },
      { label: 'Proposed', value: 'proposed', icon: YellowIcon },
      { label: 'Suspended', value: 'suspended', icon: RejectIcon },
    ],
  },
};

export const FAMILY_HISTORY = {
  WORDINGS: {
    TITLE: 'Family history',
    PLACEHOLDER: 'Search by relationship',
    NO_RECORDS: 'family history',
    FILTER_TYPE: 'Date:',
    VIEW_DETAILS: 'View details',
    CONDITION_LABEL: 'Condition',
    START_DATE_LABEL: 'Start date',
    REASON_FOR_HISTORY_LABEL: 'Reason for history',
    NOTE: 'Note',
    LOADING_DETAILS: 'Loading family history details',
    LOADING_FAMILY_HISTORY: 'Loading family history',
  },
  FILTER_ITEMS: {
    FILTER_TYPE: 'Status:',
    STATUS: [
      { label: 'All', value: 'all' },
      { label: 'Partial', value: 'partial', icon: YellowIcon },
      { label: 'Completed', value: 'completed', icon: CompletedIcon },
      {
        label: 'Unknown',
        value: 'unknown',
        mappingList: ['health-unknown', 'entered-in-error'],
        icon: GrayColorStatusIcon,
      },
    ],
    MAPPING_LIST: ['partial', 'completed', 'health-unknown', 'entered-in-error'],
  },
};

export const EAM_FETCH_ERR_MSGS = [
  {
    message: 'Something went wrong, please try again later.',
    code: 'EAM001',
  },
  {
    message: 'Services are down, please try again later.',
    code: 'EAM002',
  },
  {
    message: 'Something went wrong from the partner, please try again later.',
    code: 'EAM003',
  },
  {
    message: 'Unable to establish the connection, please try again.',
    code: 'EAM004',
  },
];

export const EAM_CALLBACK_ERR_MSGS = [
  {
    message: 'Something went wrong, please try again later.',
    code: 'EAM101',
  },
  {
    message: 'Something went wrong, please try again later.',
    code: 'EAM102',
  },
  {
    message: 'Unable to process the request, please try again later.',
    code: 'EAM103',
  },
  {
    message: 'Services are down, please try again later.',
    code: 'EAM104',
  },
  {
    message: 'Something went wrong from the partner, please try again later.',
    code: 'EAM105',
  },
  {
    message: 'Session expired, please login again.',
    code: 'EAM106',
  },
  {
    message: 'Unable to establish the connection, please try again.',
    code: 'EAM107',
  },
];

export const BONUS_TYPE = {
  organizationAddedBonus: 'Organization bonus',
  coverageBonus: 'Coverage bonus',
  giftedTo: (name) => `Gifted to ${name || ''}`,
  giftedBy: (name) => `Gifted by ${name || ' '}`,
  profileUpdateBonus: 'Profile update bonus',
  referralBonus: 'Referral bonus',
  redeemAwardPoints: 'Award points redeemed',
  promoCodeBonus: 'Promo code bonus',
};

export const AWARDPOINTS = {
  HEADING: 'Award points',
  TOTAL_AWARD_POINTS: 'Total award points',
  TRANSACTION_HISTORY: 'Transaction history',
  EARN_AWARD_POINTS_HEADING: 'Do you want to earn more award points?',
  EARN_AWARD_POINTS_SUB_HEADING: 'Complete the tasks and earn points',
  EARN_AWARD_POINTS_BUTTON_TEXT: 'Earn award points',
  CARD_DETAILS1: {
    HEADING: 'Redeem a gift card',
    TEXT: ' Redeem points for a gift card',
    COLOR: '#F0E4FC',
  },
  CARD_DETAILS2: {
    HEADING: 'Gift to someone',
    TEXT: 'Gift points to someone you know',
    COLOR: '#E6F5E9',
  },
  CARD_DETAILS3: {
    HEADING: 'Pay bills',
    TEXT: 'Pay bills with points',
    TEXT1: (numberOfDueBills) => `No of bills due : ${numberOfDueBills}`,
    COLOR: '#E2F1FC',
  },
  AWARD_POINTS_SHARED_SUCCESSFULLY: 'Award points successfully shared',
  AWARD_POINTS_SHARED_FAILED: 'Failed to share award points',
  INVALID_POINTS: 'Invalid points',
  MIN_POINTS_TO_SHARE: 'Minimum points to share is 100',
  POINTS_NOT_AVAILABLE: 'Selected points not available',
  SEND_AWARD_POINTS: 'Send award points',
};
export const EARNPOINTS = {
  HEADING: 'Earn more points',
  TOTAL_AWARD_POINTS: 'Total award points',
  cards: [
    // The user first time log in then we are forcing to update the profile. So this card currently hiding.
    // {
    //   heading: 'Complete your profile',
    //   subHeading: 'Complete your profile and earn points',
    //   points: 100,
    //   buttonText: 'Complete',
    //   icon: EarnPointCard1,
    //   link: '/utilities/accountDetails',
    // },
    {
      heading: 'Refer a friend',
      subHeading: 'Invite your friend and earn points',
      points: 100,
      buttonText: 'Refer',
      icon: EarnPointCard2,
      link: '/utilities/refer',
    },
    // {
    //   heading: 'Add an organization',
    //   subHeading: 'Add an organization to your circle and get rewarded',
    //   points: 100,
    //   buttonText: 'Add',
    //   link: '/circles',
    //   icon: EarnPointCard3,
    // },
    {
      heading: 'Shop for coverage',
      subHeading: 'Compare and shop for health, vision and dental plans',
      points: 2000,
      buttonText: 'Shop now',
      link: '/records/coveragesummary/shopNow',
      icon: EarnPointCard4,
    },
  ],
};
export const REFER_AND_EARN = {
  HEADING: 'Refer & earn',
  AWARDPOINT_BUTTON_HEADING: 'View award points',
  INSTRUCTION_HEADING: 'How it works',
  INVITE_HEADING: ' Invite friends and earn award points',
  REFERRAL_CODE_TEXT: 'Referral code',
  SHARE_INVITE_CODE: 'Share referral code',
};
export const REFERRAL_STEPS = [
  'Share your referral code with your friends',
  'When your friend uses the app for the first time , they need to enter your referral code during the signup',
  "Once your friend's account is created, you will receive 100 award points",
];
export const SHARE_POINTS_FIELDS = {
  POINTS: {
    LABEL: 'Enter award points',
    NAME: 'awardPoints',
    PLACEHOLDER: '',
  },
  DESCRIPTION: {
    LABEL: '',
    NAME: 'description',
    PLACEHOLDER: 'Additional message',
  },
};
export const BREADCURMB_PROPS_TRANSACTION_HISTORY = [
  { label: 'Utilities', route: '/utilities' },
  { label: 'Award points', route: '/utilities/awardpoints' },
  { label: 'Transaction history', route: 'utilities/awardpoints/transactionDetails' },
];
export const BREADCRUMB_PROPS_AWARD_POINTS = [
  { label: 'Utilities', route: '/utilities' },
  { label: 'Award points', route: '/utilities/awardpoints' },
];
export const ADD_COVERAGE_PAGE_BREADCRUMB_PROPS = [
  { label: 'Records', route: '/records' },
  { label: 'Coverage', route: '/records/coverage' },
  { label: 'Add Coverage' },
];
export const BREADCRUMB_PROPS_REFERRAL = [
  { label: 'Utilities', route: '/utilities' },
  { label: 'Refer & earn', route: '/utilities/refer' },
];
export const BREADCRUMB_PROPS_EARN_POINTS = [
  { label: 'Utilities', route: '/utilities' },
  { label: 'Award points', route: '/utilities/awardpoints' },
  { label: 'Transaction history', route: '/utilities/awardpoints/transactionDetails' },
  { label: 'Earn more points', route: '/utilities/awardpoints/transactionDetails/earnPoints' },
];
export const BREADCRUMB_PROPS_MARKET_CATEGORY = [
  { label: 'Shop', route: '/shop' },
  { label: 'Market category', route: '/shop/marketCategory' },
];

export const AWARDPOINTS_OBJECT = {
  LOADER_TEXT_FOR_GIFT_AWARDPOINTS: 'Loading users...',
  SPINNER_TEXT_FOR_GIFT_AWARDPOINTS: 'Fetching user...',
  TITLE_FOR_GIFT_AWARDPOINTS: 'Gift award points',
  PLACEHOLDER_FOR_GIFT_AWARDPOINTS_SEARCH: 'Enter name or email address',
};

export const REDEEM_GIFT_CARD_OBJ = {
  SERVER_ERROR: 'Internal server error, please try after sometime',
  CHANGE_BUTTON_TEXT: 'Change',
  REDEEM_AMOUNT_IN_DOLLAR_PREFIX_TEXT: 'Number of award points: ',
  EMAIL_ADDRESS_PREFIX_TEXT: '*We will send the gift card to your email ID: ',
  GIFT_CARD_SELECTION_SUB_HEADING: 'Select a gift card you would like to redeem',
  INFO_CARD: (minAwardPoint) =>
    `You would need minimum ${minAwardPoint} points to redeem a gift card`,
  REDEEM_BUTTON_TEXT: 'Redeem award points',
  PLACEHOLDER_FOR_INPUT_REWARD_POINT_BOX: 'Enter a gift card amount',
  PLACEHOLDER_FOR_FIXED_TYPE_SELECTION_BOX: 'Select a gift card',
  PAGE_HEADING: 'Redeem a gift card',
  SUCCESS_MODAL_VIEW_TRANSACTION_BUTTON_TEXT: 'View transaction history',
  AWARD_POINT_NOT_ENOUGH_BUTTON_TEXT: 'Earn award points',
  AWARD_POINT_NOT_ENOUGH_HEADING: 'Gift card can not be redeemed',
  SUCCESS_MODAL_SUB_HEADING: (point) => `You have redeemed ${point} award points!`,
  SUCCESS_MODAL_DESCRIPTION: (email) => `Your gift card had been sent to your email id : ${email}`,
  LOADING_GIFT_CARD: 'Loading gift cards',
  PLEASE_WAIT: 'Please wait',
  BREADCRUMB_PROPS_FOR_REDEEM_GIFT_CARD: (breadCrumbTitle) => [
    { label: 'Utilities', route: '/utilities' },
    { label: 'Award points', route: '/utilities/awardpoints' },
    { label: breadCrumbTitle },
  ],
};

export const TRANSACTION_TYPE = {
  ADD_TO_CIRCLE: 'ADD_TO_CIRCLE',
  ADD_PARTNER: 'ADD_PARTNER',
  EHEALTH: 'EHEALTH',
  SHARED_TO_USER: 'SHARED_TO_USER',
  SHARED_BY_USER: 'SHARED_BY_USER',
  PROFILE: 'PROFILE',
  REFERRAL: 'REFERRAL',
  GIFT_CARD_REDEMPTION: 'GIFT_CARD_REDEMPTION',
  PROMO_CODE: 'PROMO_CODE',
  PROMO: 'MEDI001',
};

export const MEDICATION_PARTNER = {
  HEADING: 'All medication partners',
  SEARCH_BAR_PLACEHOLDER: ' Search by partner name',
  CARDS: [
    {
      HEADING: 'Capsule Pharmacy ',
      URL: '',
      PARA_TEXT: 'US based pharmacy delivery app.',
    },
    {
      HEADING: 'NowRx',
      URL: '',
      PARA_TEXT: 'Prescription based pharmacy delivery app.',
    },
    {
      HEADING: 'Pill Pack',
      URL: '',
      PARA_TEXT: 'US based pharmacy delivery app.',
    },
  ],
};

export const PARTNER_RELOGIN_MESSAGES = {
  HEADING: 'Re-login with the organization',
  MESSAGE: 'We are unable to update your latest data since your last session has expired.',
  LOGIN_BTNT_EXT: 'Login',
};

export const ONE_TIME_CONSTANTS = {
  SORRY_EXPIRED_HEADER: 'Sorry, the link has expired.',
  EXPIRED_MESSAGE: (grantorName) =>
    `Please request ${grantorName} to share the records using Mpowered Health App if you want to continue accessing the records.`,
};

export const THANK_YOU_DEFAULT_OBJ = {
  HEADING: 'Thank you',
  SUB_HEADING: 'Your feedback was successfully submitted.',
  LINK_TEXT: 'Add another feedback',
};
export const THANK_YOU_SUPPORT_OBJ = {
  HEADING: 'Thank you',
  SUB_HEADING: 'Your issue has been successfully registered.',
  LINK_TEXT: 'Report another issue',
};
export const SUPPORT_OBJ = {
  HEADING: 'Support',
  SUB_HEADING: 'Need help?',
  FILE_SELECTOR_SUB_HEADING: 'Please attach a file that illustrates the issue you are facing',
  CONTENT: "We'll do our best to assist you and resolve any problems as quickly as possible.",
  PLACEHOLDER_FOR_SUPPORT: 'How can we help you?',
  PLACEHOLDER_FOR_CIRCLE: 'Can’t find the partner I am looking for.',
  ERROR_MSG: 'Something went wrong please try again.',
};
export const MAX_CHAR_ALLOWED = 200;

export const FEED_BACK_OBJECT = Object.freeze({
  OPTIONS_LIST_FOR_FEEDBACK: [
    'Speed and efficiency ',
    'Costumer support',
    'User friendly',
    'Trust and security',
    'Other',
  ],
  HEADING: 'We would love to hear from you',
  SUB_HEADING: 'Are you satisfied with the service?',
  TEXT_FIELD_PLACEHOLDER: 'Leave your comment here...',
  HINT_TEXT_FOR_LESSTHAN_FOUR_STAR: 'Tell us what can be improved',
  HINT_TEXT_FOR_FIVE_STAR: 'What did you like the most?',
  PLACEHOLDER_FOR_LESSTHAN_FOUR_STAR: 'Tell us how can we improve',
  PLACEHOLDER_FOR_FIVE_STAR: 'What did you like the most',
  ERROR_MSG: 'Something went wrong please try again.',
  FEEDBACK_HEADING: 'Feedback',
  SUBMIT_BUTTON_TEXT: 'Submit feedback',
  APP: 'What did you like about the app?',
});

export const DATE_TO_BE_REPLACED_FOR_RECORDED_ON = '1111-01-01T00:00:00+00:00';
export const DUMMY_DATE_STUB = '1111-01-01';
export const SWITCH_AND_REVOKE_CONFIRMATION_MODEL_OBJ = {
  REVOKE_HEADING: 'Revoke profile access',
  REVOKE_SUB_HEADING: 'Are you sure that you want to revoke profile access?',
  SWITCH_HEADING: 'Switch profile',
  SWITCH_SUB_HEADING: (name) => `Do you wish to switch to ${name}’s profile?`,
};

export const INITIALS_BG_COLOR = ['#00A0DF', '#08ebb3', '#eb8508', '#8c42e8', '#FBFFB1', '#BFACE2'];
export const LABEL_LIST = ['Date of birth', 'Gender', 'Marital status', 'Address'];

export const PROFILE_CONFIRMATION_OBJECT = {
  YES_BUTTON_TEXT: "Yes it's me",
  NO_BUTTON_TEXT: 'No, it’s not me',
  HEADING: 'We found your profile',
  SUB_HEADING: 'Please confirm that the displayed profile belongs to you.',
  CHECKBOX_CONTENT: 'Update the profile details shown above in your Mpowered Health account.',
};
export const ADD_MINOR_CONFIRMATION_OBJECT = {
  HEADING: 'Linked profiles found',
  SUB_HEADING: `We found few profiles linked to your organization. Would you like to add them to
  your circle?`,
  IS_LEGAL_GUARDIAN_TEXT: 'I confirm that  I am the legal guardian.',
};
export const MINOR_PROFILE_ALREADY_EXISTS_CONFIRMATION_OBJECT = {
  HEADING: 'Linked profiles found',
  SUB_HEADING: `We found few profiles linked to your organization. Would you like to add them to your circle?`,
  YES_BTN_TEXT: 'Send consent',
  NO_BTN_TEXT: 'Skip',
  IS_LEGAL_GUARDIAN_TEXT: 'You need consent to access their records.',
};

export const INDIVIDUAL_FLYOUT_OBJ = {
  HEADING: 'Add an individual',
  SUB_HEADING: 'Choose an individual you wish to add to your circle ',
  listItems: [
    {
      heading: 'Minor',
      content: `A person who is under the age of 18 years`,
      img: AddMinor,
      isDependent: true,
    },
    {
      heading: 'Major',
      content: `A person who is 18 years or older`,
      img: AddAdult,
      isDependent: false,
    },
  ],
};

export const LEGAL_GUARDIAN_MODAL = {
  HEADING: 'Are you a legal guardian?',
  SUB_HEADING: 'Legal authority to make medical decisions for a minor.',
  BUTTON_TEXT: 'Proceed',
  YES: 'Yes',
  YES_TEXT: 'Will link minor’s profile to your account',
  NO: 'No',
  NO_TEXT: 'Need consent from the legal guardian.',
  TYPE: 'Minor',
  CHANGE: 'Change',
};
export const HEALTHCARE_ATTORNEY_MODAL = {
  HEADING: 'Do you have a healthcare power of attorney?',
  SUB_HEADING: 'Legal document that allows you to make medical decisions on their behalf.',
  BUTTON_TEXT: 'Proceed',
  YES: 'Yes',
  YES_TEXT: "Will link adult's profile to your account",
  NO: 'No',
  NO_TEXT: 'Need consent to access their records.',
  TYPE: 'Adult',
  CHANGE: 'Change',
};

export const ACCOUNT_TYPE = {
  MINOR: 'minor',
  ADULT: 'adult',
  PRIMARY: 'primary',
};

export const MY_MEDICARE_IDENTIFIER_SYSTEM_URL =
  'https://bluebutton.cms.gov/resources/variables/carr_clm_cntl_num';
