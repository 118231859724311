import React, { useContext, useMemo } from 'react';
import { UserInfoContext } from '../components/Routes';
import { UTILITIES_TITLE, UTILITY_TAB_CONTENT } from './constants';

// Import icons
import AwardPoints from '../assets/images/awardPoints.svg';
import PaymentsMethods from '../assets/images/payments.svg';
import Refer from '../assets/images/referral-help.svg';
import AccountDetails from '../assets/images/accountDetails.svg';
import Logout from '../assets/images/logoutUtilities.svg';
import Help from '../assets/images/utilitiesHelp.svg';
import FeedBack from '../assets/images/feedbackUtilities.svg';
import ContactUs from '../assets/images/contactUs.svg';
import PrivacyPolicy from '../assets/images/privacyPolicy.svg';
import TermsAndConditions from '../assets/images/termsAndConditions.svg';
import Deactivate from '../assets/images/deactivateIcon.svg';
import CommunicationPreference from '../assets/images/communicationPreference.png';

const UTILITIES_COPY = {};
let iconIndex = 0;
const get = () => {
  const { userInfo } = useContext(UserInfoContext);
  const betaUser = userInfo && userInfo.betaUser;

  const ICONS_IMAGES = [
    { pwaIcon: AwardPoints, icon: AwardPoints },
    // { pwaIcon: Refunds, icon: Refunds },
    { pwaIcon: PaymentsMethods, icon: PaymentsMethods },
    // { pwaIcon: PaymentsHistory, icon: PaymentsHistory },
    // { pwaIcon: Refer, icon: Refer },
    // { pwaIcon: OffersAndDiscounts, icon: OffersAndDiscounts },
    // { pwaIcon: TaxForms, icon: TaxForms },
    { pwaIcon: AccountDetails, icon: AccountDetails },
    // { pwaIcon: IDCard, icon: IDCard },
    // { pwaIcon: Coverage, icon: CoverageSmallIcon },
    { pwaIcon: Logout, icon: Logout },
    { pwaIcon: Deactivate, icon: Deactivate },
    { pwaIcon: CommunicationPreference, icon: CommunicationPreference },
    { pwaIcon: ContactUs, icon: ContactUs },
    { pwaIcon: FeedBack, icon: FeedBack },
    { pwaIcon: Help, icon: Help },
    // { pwaIcon: ExportRecords, icon: ExportRecords },
    
    
    // { pwaIcon: PrivacyPolicy, icon: PrivacyPolicy },
    // { pwaIcon: TermsAndConditions, icon: TermsAndConditions },
    
   
  ];

  const INACTIVE_ICONS = [
    AwardPoints,
    // Refunds,
    PaymentsMethods,
    // PaymentsHistory,
    // Refer,
    // OffersAndDiscounts,
    // TaxForms,
    AccountDetails,
    // IDCard,
    // Coverage,
    Logout,
    Help,
    // ExportRecords,
    FeedBack,
    ContactUs,
    PrivacyPolicy,
    TermsAndConditions,
    Deactivate,
    CommunicationPreference,
  ];

  for (const key in UTILITY_TAB_CONTENT) {
    const utilitiesCopy = [];

    for (let i = 0; i < UTILITY_TAB_CONTENT[key].length; i++) {
      const utility = UTILITY_TAB_CONTENT[key][i];

      // if (utility.title === UTILITIES_TITLE.COMMUNICATION_PREFERENCE ) {
      //   continue;
      // }

      const utilityCopy = { ...utility };

      if (utilityCopy.active) {
        utilityCopy.icon = ICONS_IMAGES[iconIndex].icon;
        utilityCopy.pwaIcon = ICONS_IMAGES[iconIndex].pwaIcon;
      } else {
        utilityCopy.inactiveIcon = INACTIVE_ICONS[iconIndex];
        utilityCopy.pwaIcon = null;
      }

      utilitiesCopy.push(utilityCopy);
      iconIndex++;
    }

    UTILITIES_COPY[key] = utilitiesCopy;
  }

  iconIndex = 0;
  return UTILITIES_COPY;
};

const utilitiesIconsGetter = { get };

export default utilitiesIconsGetter;
