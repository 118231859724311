import gql from 'graphql-tag';

export const PROFILE_QUERY = gql`
  query {
    fetchProfileDetails {
      basicProfileDetails {
        firstName
        middleName
        lastName
        suffix
        preferredName
        previousName
        userId
        gender
        maritalStatus
        dob
        bloodGroup
        avatarUrl
        ethnicity
        race
        preferredLanguage
        planName
        issuer
        id
        name
        dr
        phoneNumber
        medicalGroup
        rxBin
        rxPCN
        memberEnabled
        readOnly
        careManagers {
          careManagerKeycloakId
          careManagerFirstName
          careManagerLastName
          memberKeycloakId
          careManagerFullName
          userRole
        }      
        primaryCareProvider
      }
    }
  }
`;

export const FETCHING_CONTACT_DETAILS = gql`
  query {
    fetchProfileDetails {
      contactProfileDetails {
        phoneNumberList {
          phoneNumber
          primary
          verified
          active
        }
        emailIdList {
          emailAddress
          primary
          emailAddress
          primary
          verified
          active
        }
        addressList {
          id
          nickName
          addressLine1
          addressLine2
          city
          state
          zipCode
          primary
          active
        }
      }
    }
  }
`;

export const ADD_EMAIL_ID = gql`
  mutation addEmailId($emailId: String) {
    addEmailId(emailId: $emailId) {
      status
      message
    }
  }
`;

export const ADD_PHONE = gql`
  mutation addPhoneNumber($phone: String) {
    addPhoneNumber(phone: $phone) {
      status
      message
    }
  }
`;

export const NEW_ADDRESS = gql`
  mutation addAddress($address: AddressInput) {
    addAddress(address: $address) {
      status
      message
    }
  }
`;

export const EDIT_EMAIL_ID = gql`
  mutation editEmailId($emailList: [EmailIdInput]) {
    editEmailId(emailList: $emailList) {
      status
      message
    }
  }
`;

export const PROFILE_AVATAR = gql`
  mutation addProfileAvatar($profilePicture: String!) {
    addProfileAvatar(profilePicture: $profilePicture)
  }
`;

export const EDIT_PHONE_NO = gql`
  mutation editPhoneNumber($phoneList: [PhoneNumberInput]) {
    editPhoneNumber(phoneList: $phoneList) {
      status
      message
    }
  }
`;

export const EDIT_ADDRESS_QUERY = gql`
  mutation editAddressDetails($addressList: [AddressInput]) {
    editAddressDetails(addressList: $addressList) {
      status
      message
    }
  }
`;
