import client from '../components/ApolloClient';
import gql from 'graphql-tag';
import AxiosClient from '.././components/AxiosClient';

export const GET_ID_CARDS = gql`
  query GetIdCards {
    getIdCards {
      idCardName
      status
      layout
      createdAt
      updatedAt
      contents {
        fieldName
        fieldValue
      }
    }
  }
`;

const START_CONTACT_VERIFICATION_MUTATION = gql`
  mutation startContactVerification($verificationRequest: ContactVerificationInput) {
    startContactVerification(verificationRequest: $verificationRequest) {
      verificationSid
    }
  }
`;

const CONFIRM_CONTACT_VERIFICATION_MUTATION = gql`
  mutation confirmContactVerification($verificationRequest: ContactVerificationInput) {
    confirmContactVerification(verificationRequest: $verificationRequest) {
      verified
    }
  }
`;

const DEFAULT_UPDATE_BASIC_PROFILE_MUTATION = gql`
  mutation editBasicProfileDetails($basicProfileDetails: BasicProfileDetailsInput) {
    editBasicProfileDetails(
      editBasicProfile: { basicProfileDetails: $basicProfileDetails, profileEditItem: basic_info }
    ) {
      status
      message
    }
  }
`;

const FETCH_PROFILE_DETAILS_BY_ID = gql`
  query fetchProfileDetails($id: String) {
    fetchProfileDetails(id: $id) {
      basicProfileDetails {
        firstName
        middleName
        lastName
        gender
        maritalStatus
        dob
        bloodGroup
        avatarUrl
        ethnicity
        race
        __typename
      }
      contactProfileDetails {
        emailIdList {
          emailAddress
        }
        phoneNumberList {
          phoneNumber
        }
        addressList {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
      }
      __typename
    }
  }
`;

const FETCH_DEPENDENT_PROFILES = gql`
  query {
    getDependentProfiles {
      dependentFhirId
      relationship
      basicProfileDetails {
        firstName
        lastName
        userId
        preferredName
        dob
        gender
        bloodGroup
        avatarUrl
        betaUser
      }
      contactProfileDetails {
        addressList {
          city
          state
          zipCode
          addressLine1
          addressLine2
        }
      }
    }
  }
`;

const PROFILE_QUERY = gql`
  query {
    fetchProfileDetails {
      basicProfileDetails {
        firstName
        lastName
        preferredName
        userId
        gender
        dob
        bloodGroup
        avatarUrl
        suffix
        middleName
        previousName
        race
        ethnicity
        maritalStatus
        preferredLanguage
        orgName
        betaUser
        careManagers {
          careManagerKeycloakId
          careManagerFirstName
          careManagerLastName
          memberKeycloakId
          careManagerFullName
          userRole
        }      
        primaryCareProvider
      }
      contactProfileDetails {
        phoneNumberList {
          phoneNumber
          primary
          verified
          active
        }
        emailIdList {
          emailAddress
          primary
          verified
          active
        }
        addressList {
          city
          state
          zipCode
          addressLine1
          addressLine2
        }
      }
    }
  }
`;

const UPDATE_PATIENT_DEMOGRAPHIC_DETAILS = gql`
  mutation updatePatientDemographics(
    $checkBoxInput: Boolean!
    $buttonInput: Boolean!
    $partnerPatientDetails: PartnerPatientUpdateInput
  ) {
    updatePatientDemographics(
      checkBoxInput: $checkBoxInput
      buttonInput: $buttonInput
      partnerPatientDetails: $partnerPatientDetails
    ) {
      requestId
      userId
      circleId
      patientId
      partnerPatientId
      demographicUpdateStatus
      relatedPersonResource {
        total
        status
        entry {
          name
          relationship
          id
          email
          phone
          birthDate
          identifier {
            system
            value
          }
        }
      }
    }
  }
`;

const GENERATE_REFERRAL_CODE_QUERY = gql`
  mutation createReferralCode($createReferralReq: CreateReferralRequest) {
    createReferralCode(createReferralReq: $createReferralReq) {
      code
    }
  }
`;

export const PROFILE_AVATAR_END_POINT = {
  UPLOAD_PROFILE_AVATAR: '/documentservice/upload',
  DOWNLOAD_PROFILE_AVATAR: '/documentservice/document/download',
  BUCKET_NAME: 'mpowered-profile-photo-prod',
};

const ProfileService = {
  uploadAvatar: (formData) => {
    return new Promise((resolve, reject) => {
      AxiosClient.put(PROFILE_AVATAR_END_POINT.UPLOAD_PROFILE_AVATAR, {}, formData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  downloadAvatar(docArn, folder = PROFILE_AVATAR_END_POINT.BUCKET_NAME) {
    return new Promise((resolve, reject) => {
      AxiosClient.get(
        PROFILE_AVATAR_END_POINT.DOWNLOAD_PROFILE_AVATAR,
        { docArn: docArn, folder: folder },
        true,
        {
          responseType: 'blob',
        },
      )
        .then((res) => {
          // console.log(res);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  fetchCoverageDetails(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchCoverageDetails.planCoverageDetails.planCoverageList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchProfileDetails(query = PROFILE_QUERY, primary = false) {
    const context = primary
      ? {
          headers: {
            fetch_id: '',
            domain: window.location.hostname.startsWith('demo.mpowered') ? 'DEMO' : 'DEFAULT',
          },
        }
      : {};
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
          context,
        })
        .then((response) => resolve(response.data.fetchProfileDetails))
        .catch((error) => reject(error));
    });
  },
  updatePatientDemographics(args, mutation = UPDATE_PATIENT_DEMOGRAPHIC_DETAILS) {
    const {
      checkBoxInput,
      buttonInput,
      partnerPatientDetails: { __typename, ...rest },
    } = args;
    if (rest?.address?.length > 0) {
      rest.address.map((item) => {
        delete item.__typename;
        return (item.lines = item.lines !== null && item.lines?.length ? item.lines : []);
      });
    } else {
      rest.address = [];
    }
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            checkBoxInput: checkBoxInput,
            buttonInput: buttonInput,
            partnerPatientDetails: rest,
          },
        })
        .then((response) => resolve(response.data.updatePatientDemographics))
        .catch((error) => reject(error));
    });
  },
  fetchDependentProfiles() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_DEPENDENT_PROFILES,
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data.getDependentProfiles))
        .catch((error) => reject(error));
    });
  },

  fetchProfileDetailsById(id, query = FETCH_PROFILE_DETAILS_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data.fetchProfileDetails))
        .catch((error) => reject(error));
    });
  },
  fetchCoverageById(query, coverageId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            coverageId: coverageId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  editCoverage(args) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: gql`
                    mutation {
                        editCoverageDetails(
                          editCoverage: {
                            profileEditItem: coverage
                            planCoverage: {
                                firstName: "${args.firstName}"
                                lastName: "${args.lastName}"
                                organisationName: "${args.organisationName}",
                                primary: ${args.primary}
                                groupId:"${args.groupId}"
                                planType:"${args.planType}"
                                memberId: "${args.memberId}"
                                coverageId: "${args.coverageId}"
                                patientRelationShip:${args.patientRelationShip}
                                activate:${args.activate}
                            }
                          }
                        ) {
                          status
                          message
                        }
                }`,
        })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  editProfile(args, mutation = DEFAULT_UPDATE_BASIC_PROFILE_MUTATION) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: { ...args },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  addCoverage(query, addCoverage) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            addCoverage,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  makeCovergePrimary(args, query) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: gql`
                        mutation {
                            markCoveragePrimary(
                                makeCoveragePrimary: {
                                profileEditItem: coverage
                                planCoverage: {
                                    memberId: "${args.memberId}"
                                    groupId: "${args.groupId}"
                                    planType: "${args.planType}"
                                    organisationName: "${args.organisationName}",
                                    primary:true,
                                    coverageId:"${args.coverageId}"
                                    patientRelationShip:${args.patientRelationShip}
                                    activate:${args.activate}
                                }
                                }
                            ) {
                                status
                                message
                            }
                    }
                `,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  activateOrDeactivateCoverage(args, query) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: gql`
                        mutation {
                            activateOrDeactivateCoverage(
                                activateCoverage: {
                                planCoverage: {
                                    memberId: "${args.memberId}"
                                    groupId: "${args.groupId}"
                                    planType: "${args.planType}"
                                    primary:${args.primary}
                                    organisationName: "${args.organisationName}",
                                    coverageId:"${args.coverageId}"
                                    patientRelationShip:${args.patientRelationShip}
                                    activate:${args.activate}
                                }
                                }
                            ) {
                                status
                                message
                            }
                    }
                `,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  addEmail(query, email) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            emailId: email,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  editEmail(query, emailList) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            emailList,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  editPhone(query, phoneList) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            phoneList,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  addPone(query, phone) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            phone: phone,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  addAddress(query, args) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            address: {
              nickName: args.nickName,
              addressLine1: args.addressLine1,
              addressLine2: args.addressLine2,
              city: args.city,
              state: args.state,
              zipCode: args.zipCode,
              primary: args.primary,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  changePassword(query, args) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            currentPassword: args.currentPassword,
            newPassword: args.newPassword,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  editAddress(query, addressList) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            addressList,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  verifyItem(query, args) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            verifyEditItem: args.verifyEditItem,
            verifyValue: args.verifyValue,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  uploadProfileAvatar(query, profilePicture) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            profilePicture,
          },
        })
        .then((response) => resolve(response.data.addProfileAvatar))
        .catch((error) => reject(error));
    });
  },
  async generateOTPForPhone(phone, mutation = START_CONTACT_VERIFICATION_MUTATION) {
    const { data } = await client.mutate({
      mutation,
      variables: {
        verificationRequest: {
          contact: phone,
          contactType: 'phone',
          phoneVerificationChannel: 'SMS',
        },
      },
    });
    return data.startContactVerification;
  },

  async verifyPhoneOTP(phone, sid, otp, mutation = CONFIRM_CONTACT_VERIFICATION_MUTATION) {
    const { data } = await client.mutate({
      mutation,
      variables: {
        verificationRequest: {
          contact: phone,
          verificationSid: sid,
          otp,
          contactType: 'phone',
        },
      },
    });
    return data.confirmContactVerification.verified;
  },
  async generateReferralCode(args, mutation = GENERATE_REFERRAL_CODE_QUERY) {
    const {
      data: { createReferralCode },
    } = await client.mutate({
      mutation,
      variables: {
        createReferralReq: { ...args },
      },
    });

    return createReferralCode;
  },
};

export default ProfileService;
