export const TABLE_HEADINGS = {
  RECORDED_ON: 'Recorded on',
  RECORDED_BY: 'Recorded by',
  STATUS: 'Status',
  DATA_SOURCE: 'Data source',
  MORE: 'More',
  CODE_WITH_NAME: (name) => name,
  CODE: 'Code',
  MEDICATION: 'Medication',
  CONDITION: 'Condition',
  ALLERGIC_TO: 'Allergic to',
  IMMUNIZATION: 'Immunization',
  LAB_RESULTS: 'Lab results',
  NAME: 'Name',
  PROCEDURE: 'Procedure',
  COVERAGE: 'Coverage',
  FREQUENCY: 'Frequency',
  DOSAGE: 'Dosage',
  SEVERITY: 'Severity',
  CRITICALITY: 'Criticality',
  ADMINISTERED_ON: 'Administered on',
  ADMINISTERED_BY: 'Administered by',
  CATEGORY: 'Category',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  FACILITY: 'Facility',
  TYPE_OF_VISITS: 'Type of visit',
  PROVIDER: 'Provider',
  OUTCOME: 'Outcome',
  POLICY_NUMBER: 'Policy number',
  EXPIRY_DATE: 'Expiry date',
  CLAIM_NUMBER: 'Claim number',
  CLAIM_TYPE: 'Claim type',
  CLAIM_AMOUNT: 'Total claim amount',
  PAY: 'Pay',
  PLAN_NAME: 'Plan Name',
  TEAM_NAME: 'Team name',
  RELATIONSHIP: 'Relationship',
  BENEFITS: 'Plan Benefits'
};
export const TABLE_KEYS = {
  RECORDED_ON: 'recordedOn',
  RECORDED_BY: 'recordedBy',
  STATUS: 'status',
  DATA_SOURCE: 'dataSource',
  MORE: 'more',
  CODE_WITH_NAME: (name) => `codeWith${name}`,
  CODE: 'code',
  MEDICATION: 'medication',
  CONDITION: 'condition',
  ALLERGIC_TO: 'allergicTo',
  IMMUNIZATION: 'immunization',
  LAB_RESULTS: 'labResults',
  NAME: 'name',
  PROCEDURE: 'procedure',
  COVERAGE: 'coverage',
  FREQUENCY: 'frequency',
  DOSAGE: 'dosage',
  SEVERITY: 'severity',
  CRITICALITY: 'criticality',
  ADMINISTERED_ON: 'administeredOn',
  ADMINISTERED_BY: 'administeredBy',
  CATEGORY: 'category',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  FACILITY: 'facility',
  TYPE_OF_VISITS: 'typeOfVisits',
  PROVIDER: 'provider',
  OUTCOME: 'outcome',
  POLICY_NUMBER: 'policyNumber',
  EXPIRY_DATE: 'expiryDate',
  CLAIM_NUMBER: 'claimNumber',
  CLAIM_TYPE: 'claimTypeLabel',
  CLAIM_AMOUNT: 'claimAmount',
  PAY: 'pay',
  PLAN_NAME: 'title',
  TEAM_NAME: 'teamName',
  RELATIONSHIP: 'relationShip',
};

// View for onetime access in device
export const medicationDeviceTableHeading = [
  { label: TABLE_HEADINGS.MEDICATION, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.MEDICATION) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE },
];
export const conditionsDeviceTableHeading = [
  { label: TABLE_HEADINGS.CONDITION, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.CONDITION) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];
export const allergyDeviceTableHeading = [
  { label: TABLE_HEADINGS.ALLERGIC_TO, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.ALLERGIC_TO) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];
export const immunizationDeviceTableHeading = [
  {
    label: TABLE_HEADINGS.IMMUNIZATION,
    key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.IMMUNIZATION),
  },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];
export const labResultsDeviceTableHeading = [
  { label: TABLE_HEADINGS.NAME, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.LAB_RESULTS) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const visitsDeviceTableHeading = [
  { label: TABLE_HEADINGS.FACILITY, key: TABLE_KEYS.FACILITY },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const proceduresDeviceTableHeading = [
  { label: TABLE_HEADINGS.PROCEDURE, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.PROCEDURE) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const socialHistoryDeviceTableHeading = [
  { label: TABLE_HEADINGS.NAME, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.NAME) },
  // { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.FREQUENCY, key: TABLE_KEYS.FREQUENCY },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const coverageDeviceTableHeading = [
  { label: TABLE_HEADINGS.POLICY_NUMBER, key: TABLE_KEYS.POLICY_NUMBER },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const billsDeviceTableHeading = [,
  { label: TABLE_HEADINGS.FACILITY, key: TABLE_KEYS.FACILITY },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const claimsDeviceTableHeading = [
  { label: TABLE_HEADINGS.CLAIM_NUMBER, key: TABLE_KEYS.CLAIM_NUMBER },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];
export const familyHistoryDeviceHeading = [
  { label: TABLE_HEADINGS.NAME, key: TABLE_KEYS.NAME, routeFrom: 'FamilyMember' },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE }
];
export const carePlanDeviceHeading = [
  { label: TABLE_HEADINGS.PLAN_NAME, key: TABLE_KEYS.PLAN_NAME },
    { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE }
];
export const careTeamDeviceHeading = [
  { label: TABLE_HEADINGS.TEAM_NAME, key: TABLE_KEYS.TEAM_NAME },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.MORE }
];

// End of one time access views
export const medicationTableHeading = [
  { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
  { label: TABLE_HEADINGS.MEDICATION, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.MEDICATION) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.FREQUENCY, key: TABLE_KEYS.FREQUENCY },
  { label: TABLE_HEADINGS.DOSAGE, key: TABLE_KEYS.DOSAGE },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE },
];

export const conditionsTableHeading = [
  { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
  { label: TABLE_HEADINGS.CONDITION, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.CONDITION) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.SEVERITY, key: TABLE_KEYS.SEVERITY },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const allergyTableHeading = [
  { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
  { label: TABLE_HEADINGS.ALLERGIC_TO, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.ALLERGIC_TO) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.CRITICALITY, key: TABLE_KEYS.CRITICALITY },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const immunizationTableHeading = [
  { label: TABLE_HEADINGS.ADMINISTERED_ON, key: TABLE_KEYS.RECORDED_ON },
  {
    label: TABLE_HEADINGS.IMMUNIZATION,
    key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.IMMUNIZATION),
  },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.DOSAGE, key: TABLE_KEYS.DOSAGE },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const labResultsTableHeading = [
  { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
  { label: TABLE_HEADINGS.NAME, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.LAB_RESULTS) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.CATEGORY, key: TABLE_KEYS.CATEGORY },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const visitsTableHeading = [
  { label: TABLE_HEADINGS.START_DATE, key: TABLE_KEYS.START_DATE },
  { label: TABLE_HEADINGS.FACILITY, key: TABLE_KEYS.FACILITY },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.TYPE_OF_VISITS, key: TABLE_KEYS.TYPE_OF_VISITS },
  { label: TABLE_HEADINGS.PROVIDER, key: TABLE_KEYS.PROVIDER },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const proceduresTableHeading = [
  { label: TABLE_HEADINGS.START_DATE, key: TABLE_KEYS.START_DATE },
  { label: TABLE_HEADINGS.PROCEDURE, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.PROCEDURE) },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.OUTCOME, key: TABLE_KEYS.OUTCOME, showStatusIcon: true },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const socialHistoryTableHeading = [
  { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
  { label: TABLE_HEADINGS.NAME, key: TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.NAME) },
  { label: TABLE_HEADINGS.RECORDED_BY, key: TABLE_KEYS.RECORDED_BY },
  // { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.FREQUENCY, key: TABLE_KEYS.FREQUENCY },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const coverageTableHeading = [
  { label: TABLE_HEADINGS.COVERAGE, key: TABLE_KEYS.COVERAGE, type: 'hotLink' },
  { label: TABLE_HEADINGS.POLICY_NUMBER, key: TABLE_KEYS.POLICY_NUMBER },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.START_DATE, key: TABLE_KEYS.START_DATE },
  { label: TABLE_HEADINGS.EXPIRY_DATE, key: TABLE_KEYS.EXPIRY_DATE },
  // { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  // { label: TABLE_HEADINGS.BENEFITS, key: TABLE_KEYS.MORE },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const billsTableHeading = [
  { label: TABLE_HEADINGS.DATE, key: TABLE_KEYS.DATE },
  { label: TABLE_HEADINGS.FACILITY, key: TABLE_KEYS.FACILITY },
  { label: TABLE_HEADINGS.AMOUNT, key: TABLE_KEYS.AMOUNT },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.PAY, key: TABLE_KEYS.PAY },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export const claimsTableHeading = [
  { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
  { label: TABLE_HEADINGS.CLAIM_NUMBER, key: TABLE_KEYS.CLAIM_NUMBER },
  { label: TABLE_HEADINGS.CLAIM_TYPE, key: TABLE_KEYS.CLAIM_TYPE },
  { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  { label: TABLE_HEADINGS.PROVIDER, key: TABLE_KEYS.PROVIDER },
  { label: TABLE_HEADINGS.CLAIM_AMOUNT, key: TABLE_KEYS.CLAIM_AMOUNT },
  { label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE, hide: true },
  { label: TABLE_HEADINGS.MORE, key: TABLE_KEYS.MORE },
];

export function getCarePlanTableHeadings(screenMode) {
  const cols = [
    { label: TABLE_HEADINGS.PLAN_NAME, key: TABLE_KEYS.PLAN_NAME },
    // { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
    { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
    { label: TABLE_HEADINGS.START_DATE, key: TABLE_KEYS.START_DATE },
    { label: TABLE_HEADINGS.END_DATE, key: TABLE_KEYS.END_DATE },
    // { label: TABLE_HEADINGS.RECORDED_BY, key: TABLE_KEYS.RECORDED_BY },
  ];
  if (!screenMode.isCircle || screenMode.involvesConsent) {
    cols.push({ label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE });
  }
  cols.push({ label: TABLE_HEADINGS.MORE });
  return cols;
}

export function getCareTeamTableHeadings(isForCircle) {
  const cols = [
    { label: TABLE_HEADINGS.TEAM_NAME, key: TABLE_KEYS.TEAM_NAME },
    { label: TABLE_HEADINGS.CATEGORY, key: TABLE_KEYS.CATEGORY },
    { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
  ];
  if (!isForCircle) {
    cols.push({ label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE });
  }
  cols.push({ label: TABLE_HEADINGS.MORE });
  return cols;
}
export function getFamilyHistoryTableHeadings(screenMode) {
  const cols = [
    { label: TABLE_HEADINGS.RECORDED_ON, key: TABLE_KEYS.RECORDED_ON },
    { label: TABLE_HEADINGS.NAME, key: TABLE_KEYS.NAME, routeFrom: 'FamilyMember' },
    { label: TABLE_HEADINGS.STATUS, key: TABLE_KEYS.STATUS },
    { label: TABLE_HEADINGS.RELATIONSHIP, key: TABLE_KEYS.RELATIONSHIP },
  ];
  if (!screenMode?.isCircle || screenMode?.involvesConsent) {
    cols.push({ label: TABLE_HEADINGS.DATA_SOURCE, key: TABLE_KEYS.DATA_SOURCE });
  }
  cols.push({ label: TABLE_HEADINGS.MORE });
  return cols;
}
