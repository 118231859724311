
import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import UserCircle from '../../../assets/images/defaultAvatarBlack.svg';
import NoPartnerCard from '../../../components/organisms/NoPartnerCard';
import { NO_INDIVIDUAL_IN_GIFT } from '../../../utils/constants';

export const getBgColorForInitials = () => {
  const colorHexCode = (Math.random() * 0x00ff00 * 1000000).toString(16);
  const bgColor = '#' + colorHexCode.slice(0, 6);
  return bgColor;
};

export const getUserName = (item, handleRedirect, classes) => {
  const username =
    item?.userName?.length > 12
      ? item?.userName?.slice(0, 13)?.concat('...')
      : item?.userName || null;
  if (username) {
    return (
      <Tooltip
        placement="top"
        title={item?.userName}
        classes={{ tooltip: classes.hotLinkTooltip }}
        key={item.id}
      >
        <span className={classes.userCardWrapper} onClick={() => handleRedirect(item)}>
          {item?.imgUrl ? (
            <img
              className={classes.userImage}
              src={item.imgUrl}
              alt={'userEmail'}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = UserCircle;
              }}
            />
          ) : (
            <div
              style={{ backgroundColor: item?.bgColorForInitial }}
              className={classes.initialWrapper}
            >
              <Typography className={classes.initialName}>
                {username && username[0].toUpperCase()}
              </Typography>
            </div>
          )}
          <Typography className={classes.userName}>{username}</Typography>
        </span>
      </Tooltip>
    );
  } else {
    return '';
  }
};

export const UserCards = ({ userData, type, classes, handleRedirect, location }) => {
  const handleAddToCircle = () => {
    location('/circles', {
      state: {
        tabIndex: 1,
      },
    });
  };
  return (
    <div className={classes.recentContainer}>
      <div className={classes.recentHeadingSection}>
        {/* <Typography className={classes.recentCardTitle}>
          {type === 'circles' ? 'Circles' : userData[type].title}
        </Typography> */}
        {/* {userData[type].title === 'Recents' && userData[type].list.length > 6 && (
          <Typography className={classes.viewAllBtn}>View All</Typography>
        )} */}
      </div>
      <div className={classes.userCardContainer}>
        {type === 'recent' &&
        userData[type].title === 'Recents' &&
        userData[type].list.length > 6 ? (
          userData[type].list.slice(0, 6).map((item) => getUserName(item, handleRedirect, classes))
        ) : userData[type] && userData[type].list.length ? (
          userData[type].list.map((item) => getUserName(item, handleRedirect, classes))
        ) : (
          <NoPartnerCard
            rootContainer={classes.rootContainer}
            btnWrapper={classes.btnWrapper}
            btnStyle={classes.btnStyleForNoIndividualInCirlce}
            handleAddToCircle={handleAddToCircle}
            data={NO_INDIVIDUAL_IN_GIFT}
            imageStyle={classes.noPartnerCardImageStyle}
            headingStyle={classes.noPartnerCardHeading}
            contentStyle={classes.noPartnerCardContent}
          />
        )}
      </div>
    </div>
  );
};

export const UserSearchedCard = ({ userDetails, handleRedirect, classes }) => {
  return (
    <div className={classes.userFoundCardContainer}>
      {userDetails?.heading && (
        <Typography className={classes.userFoundCardHeading}>{userDetails.heading}</Typography>
      )}
      {userDetails?.list?.map((item, i) => {
        return (
          <div
            className={classes.userFoundCardWrapper}
            onClick={(e) => handleRedirect(item)}
            key={i}
          >
            <div>
              {!!item?.initialText ? (
                <div
                  style={{ backgroundColor: item.bgColorForInitial }}
                  className={classes.initialWrapper}
                >
                  <Typography className={classes.initialName}>{item.initialText}</Typography>
                </div>
              ) : (
                <img
                  className={classes.userFoundImage}
                  src={item.imgUrl}
                  alt="user"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = UserCircle;
                  }}
                />
              )}
            </div>
            <div className={classes.userFoundCardTextWrapper}>
              <Typography className={classes.userFoundName}>{item.userName}</Typography>
              <Typography className={classes.userFoundEmail}>{item.email}</Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const getArrangedData = (data = []) => {
  const res = {
    title: '',
    list: [],
  };
  if (data.length > 0) {
    for (const recent of data) {
      res.title = recent.__typename === 'RecentlyVisitedUser' ? 'Recents' : 'Circles';
      res.list.push({
        userName: recent.name,
        email: recent.email,
        id: Math.floor(Math.random() * 10000),
        imgUrl: recent.avatarUrl || recent.profileUrl || '',
        bgColorForInitial: getBgColorForInitials(),
        heading: recent.__typename === 'RecentlyVisitedUser' ? 'In recents' : 'In circles',
      });
    }
  }
  return res;
};

export const formatFilteredData = (data) => {
  const res = {
    recent: {
      heading: '',
      list: [],
    },
    circle: {
      heading: '',
      list: [],
    },
  };

  for (const item of data) {
    if (item?.heading.includes('In recents')) {
      if (!item.imgUrl) {
        item.imgUrl = null;
        item.initialText = item?.userName?.[0]?.toUpperCase() || item?.email?.[0]?.toUpperCase();
      } else {
        item.initialText = '';
      }
      res['recent'].heading = 'In recents';
      res['recent'].list.push(item);
    }
    if (item?.heading.includes('In circles')) {
      if (!item.imgUrl) {
        item.imgUrl = null;
        item.initialText = item?.userName?.[0]?.toUpperCase() || item?.email?.[0]?.toUpperCase();
      } else {
        item.initialText = '';
      }
      res['circle'].heading = 'In circles';
      res['circle'].list.push(item);
    }
  }
  return res;
};

export const getStatus = {
  getFilteredDataStatus: (filteredData, type) => {
    const filterStatus =
      filteredData &&
      filteredData[type] &&
      filteredData[type].list &&
      filteredData[type].list.length > 0;
    if (filterStatus) {
      return true;
    } else {
      return false;
    }
  },
  getAllFilterListStatus: (filteredData) => {
    if (filteredData === {}) {
      return true;
    } else {
      return false;
    }
  },
  getDataStatus: (data, type) => {
    const dataStatus = data && data[type] && data[type].list && data[type].list.length > 0;
    if (dataStatus) {
      return true;
    } else {
      return false;
    }
  },
};
