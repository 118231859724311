import React, { useContext, useMemo } from 'react';
import { UserInfoContext } from '../components/Routes';
import { replaceImageProperty } from './helper';
import { HEADER_MENU_LIST, PWA_HEADER_ICON } from './constants';

// Import icons
import Profile from '../assets/images/Profile.svg';
import ContactIcon from '../assets/images/contact.svg';
import Contact from '../assets/images/call.svg';
import PrivacyPolicyIcon from '../assets/images/notepad-blue.svg';
import TermsConditionIcon from '../assets/images/signing-the-contract.svg';
import BellIcon from '../assets/images/notifications.svg';
import LogoutIcon from '../assets/images/sign-out.svg';
import AwardPointsInHeader from '../assets/images/awardPointsInHeader.svg';
import HelpIcon from '../assets/images/helpIcon.svg';

const MENU_ICONS = [
  Profile,
  ContactIcon,
  Contact,
  PrivacyPolicyIcon,
  TermsConditionIcon,
  LogoutIcon,
];

const useHeaderIcons = () => {
  const { userInfo } = useContext(UserInfoContext);
  const betaUser = userInfo && userInfo.betaUser;
  // const HEADER_ICON =betaUser 
  //   ? [AwardPointsInHeader, BellIcon, HelpIcon]
  //   : [AwardPointsInHeader, BellIcon];

  const HEADER_ICON = [AwardPointsInHeader, BellIcon];

  // const memoizedPWA_HEADER_ICON = useMemo(() => {
  //   if (betaUser) {
  //     return [
  //       ...PWA_HEADER_ICON,
  //       {
  //         icon: '/src/assets/images/helpIcon.svg',
  //         name: 'Help',
  //         link: '/help',
  //         alt: 'help',
  //       },
  //     ];
  //   }
  //   return PWA_HEADER_ICON;
  // }, [betaUser]);
  

  const getHeaderMenu = () => replaceImageProperty(HEADER_MENU_LIST, MENU_ICONS, []);
  const getHeader = () => replaceImageProperty(PWA_HEADER_ICON, HEADER_ICON, []);

  return {
    getHeaderMenu,
    getHeader,
  };
};

export default useHeaderIcons;
